import React, { FC, useContext } from 'react';
import { Layout } from 'antd';
import Header from './Header';
import { LayoutContextProvider } from '../context/LayoutContext';

import '../assets/styles/Login.less';

import background from '../assets/images/home-bg.jpg';
import { SiteContext } from '../context/SiteContext';

const LoginLayout: FC = ({ children }) => {
    const { site } = useContext(SiteContext);
    const backgroundImage = site?.loginImage ?? background;

    return (
        <LayoutContextProvider>
            <Layout id="login-layout">
                <Layout>
                    <Header />
                    <div
                        id="login-section"
                        style={{
                            backgroundImage: 'url(' + backgroundImage + ')',
                        }}
                    >
                        <div className="wrapper">
                            <div id="login-section-inner">{children}</div>
                        </div>
                    </div>
                </Layout>
            </Layout>
        </LayoutContextProvider>
    );
};

export default LoginLayout;
