import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'errorpage.title',
        defaultMessage: 'Erreur',
    },
    subtitle: {
        id: 'errorpage.subtitle',
        defaultMessage:
            'Nous sommes désolés, une erreur est survenue, veuillez réessayer plus tard ou contacter le support.',
    },
    subTitle_error_400: {
        id: 'errorpage.subTitle_error_400',
        defaultMessage: 'Nous sommes désolés, les informations envoyées ne sont pas valides.',
    },
    subTitle_error_403: {
        id: 'errorpage.subTitle_error_403',
        defaultMessage: 'Nous sommes désolés, vous n’êtes pas autorisé à accéder à cette ressource.',
    },
    subTitle_error_401: {
        id: 'errorpage.subTitle_error_401',
        defaultMessage: 'Nous sommes désolés, vous devez être authentifié pour accéder à cette ressource.',
    },
    subTitle_error_404: {
        id: 'errorpage.subTitle_error_404',
        defaultMessage: 'Nous sommes désolés, la page que vous cherchez n’existe pas.',
    },
    button_link: {
        id: 'errorpage.button_link',
        defaultMessage: 'Retourner à l&rsquo;accueil',
    },
});
