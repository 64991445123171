import React, { FC } from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import formMessages from '../../locale/formMessages';
import { Address, AddressType, User } from '../../store/api/apiTypes';
import AddressRow from '../../components/AddressRow';
import { isSameAddress } from '../../helpers';
import { PlusOutlined } from '@ant-design/icons';

interface AddressesListProps {
    type: AddressType;
    user?: User;
    selectedAdress?: Address;
    onSelect: (address: Address) => void;
    onEdit: (type: AddressType, index: number) => void;
    onNew: (type: AddressType) => void;
}

const AddressesList: FC<AddressesListProps> = ({ type, user, selectedAdress, onSelect, onEdit, onNew }) => {
    return (
        <>
            <h2 className="title title-1 smaller mb-1">
                <small>
                    <FormattedMessage {...messages.use_existing_address} />
                </small>
            </h2>
            <div className="addresses-list">
                {user?.addresses?.map((address, i) => (
                    <div
                        className={'address-row ' + (isSameAddress(selectedAdress, address) ? 'checked' : '')}
                        key={'address-' + i.toString()}
                        onClick={onSelect.bind(null, address)}
                        role="presentation"
                    >
                        <div className="check-container">
                            <span className="check"></span>
                        </div>
                        <div className="address-container">
                            <AddressRow address={address} />
                            <div className="edit-container">
                                <a href="#edit-address" onClick={onEdit.bind(null, type, i)}>
                                    <small>
                                        <FormattedMessage {...formMessages.edit_address} />
                                    </small>
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <Button type="link" onClick={onNew.bind(null, type)} className="add-address">
                <PlusOutlined /> <FormattedMessage {...formMessages.add_new_address} />
            </Button>
        </>
    );
};

export default AddressesList;
