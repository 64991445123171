import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import ErrorPage from './pages/error';
import { getRawRoute, RoutePathName } from './routes';
import RouterScrollTop from './components/RouterScrollTop';
import Site from './pages/site/Site';

import './assets/styles/App.less';
import Home from './pages/home/Home';
import Axeptio from './components/Axeptio';

const App: FC = () => (
    <>
        <RouterScrollTop />
        <Axeptio />
        <Switch>
            <Route exact path={getRawRoute(RoutePathName.home)} component={Home} />
            <Route path={getRawRoute(RoutePathName.site)} component={Site} />
            <Route path="*">
                <ErrorPage />
            </Route>
        </Switch>
    </>
);

export default App;
