import { defineMessages } from 'react-intl';

const messages = defineMessages({
    yes: {
        id: 'yes',
        defaultMessage: 'Oui',
    },
    no: {
        id: 'no',
        defaultMessage: 'Non',
    },
    or: {
        id: 'or',
        defaultMessage: 'ou',
    },
    validate: {
        id: 'validate',
        defaultMessage: 'Valider',
    },
    edit: {
        id: 'edit',
        defaultMessage: 'Modifier',
    },
    cancel: {
        id: 'cancel',
        defaultMessage: 'Annuler',
    },
    delete: {
        id: 'delete',
        defaultMessage: 'Supprimer',
    },
    header_baseline: {
        id: 'header_baseline',
        defaultMessage: 'RÉSERVEZ SIMPLEMENT VOTRE PLACE DE PARKING',
    },
    header_contact: {
        id: 'header_contact',
        defaultMessage: 'Nous contacter',
    },
    header_login: {
        id: 'header_login',
        defaultMessage: 'Connexion',
    },
    header_register: {
        id: 'header_register',
        defaultMessage: 'Inscription',
    },
    header_logout: {
        id: 'header_logout',
        defaultMessage: 'Déconnexion',
    },
    footer_copyright: {
        id: 'footer_copyright',
        defaultMessage: '2021 - Tous droits réservés',
    },
    footer_privacy: {
        id: 'footer_privacy',
        defaultMessage: 'Politique de confidentialité',
    },
    footer_cgv: {
        id: 'footer_cgv',
        defaultMessage: 'CGV',
    },
    footer_cgu: {
        id: 'footer_cgu',
        defaultMessage: 'CGU',
    },
    footer_legals: {
        id: 'footer_legals',
        defaultMessage: 'Mentions légales',
    },
    back_homepage: {
        id: 'back_homepage',
        defaultMessage: "Retour à l'accueil",
    },
    subscription: {
        id: 'subscription',
        defaultMessage: 'Abonnement',
    },
    count_years: {
        id: 'count_years',
        defaultMessage: `
            {count, plural,
                =0 {an}
                one {1 an}
                other {{count, number} ans}
            }
        `,
    },
    price_from: {
        id: 'price_from',
        defaultMessage: 'à partir de',
    },
    to_pay_now: {
        id: 'to_pay_now',
        defaultMessage: 'À payer',
    },
    price_by_month: {
        id: 'price_by_month',
        defaultMessage: '/ mois',
    },
    dates_from_to: {
        id: 'dates_from_to',
        defaultMessage: 'Du {from} au {to}',
    },
    select: {
        id: 'select',
        defaultMessage: 'Sélectionner',
    },
    back: {
        id: 'back',
        defaultMessage: 'Retour',
    },
    ongoing: {
        id: 'ongoing',
        defaultMessage: 'En cours',
    },
    expired: {
        id: 'expired',
        defaultMessage: 'Expirés',
    },
    transaction_pending: {
        id: 'transaction_pending',
        defaultMessage: 'Transaction en attente de validation',
    },
    error_not_available: {
        id: 'error_not_available',
        defaultMessage: 'Le service est actuellement indisponible, veuillez réessayer ultérieurement.',
    },
    error_no_result: {
        id: 'error_no_result',
        defaultMessage: 'Aucun résultat pour cette recherche',
    },
    error_no_subs: {
        id: 'error_no_subs',
        defaultMessage: "Vous n'avez actuellement souscris à aucun abonnement",
    },
    error_payment: {
        id: 'error_payment',
        defaultMessage: 'Une erreur est survenue lors du paiement, veuillez réessayer.',
    },
    vehicle_type_all: {
        id: 'vehicle_type_all',
        defaultMessage: 'Tous les types',
    },
    vehicle_type_car: {
        id: 'vehicle_type_car',
        defaultMessage: 'Voiture',
    },
    vehicle_type_motorcycle: {
        id: 'vehicle_type_motorcycle',
        defaultMessage: 'Moto',
    },
    vehicle_type_small: {
        id: 'vehicle_type_small',
        defaultMessage: 'Véhicule léger',
    },
    vehicle_type_heavy: {
        id: 'vehicle_type_heavy',
        defaultMessage: 'Véhicule lourd',
    },
    parking_name: {
        id: 'parking_name',
        defaultMessage: '{parking}',
    },
});

export default messages;
