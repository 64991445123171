import React, { FC } from 'react';
import Seo from '../../components/Seo';

import '../../assets/styles/Home.less';

import logo from '../../assets/images/logo.png';

const Home: FC = () => {
    return (
        <>
            <Seo title="Orbility" />
            <div className="home-layout">
                <img src={logo} alt="logo" className="logo" />
            </div>
        </>
    );
};

export default Home;
