import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { Parking, Site } from '../api/apiTypes';

// Controlers
import { contact as contactApiCall, site as siteApiCall, SitePayload, ContactPayload } from '../api/sites';

// States
export interface SitesState {
    site: RequestState<Site>;
    contact: RequestState<any>;
}

const initialState: SitesState = {
    site: {
        data: undefined,
        loading: false,
    },
    contact: {
        data: undefined,
        loading: false,
    },
};

export const site = new EzeeAsyncAction<SitesState['site'], SitePayload, Site>(
    'site/site',
    initialState.site,
    requestReducer<SitesState['site'], Site>(initialState.site)
);

export const contact = new EzeeAsyncAction<SitesState['contact'], ContactPayload, any>(
    'site/contact',
    initialState.contact,
    requestReducer<SitesState['contact'], Parking>(initialState.contact)
);

// Reducer
export const sitesReducer = combineReducers<SitesState>({
    site: site.reducer,
    contact: contact.reducer,
});

// Saga
export function* sitesSaga() {
    yield takeLatest(site.type.trigger, simpleAsyncSaga(siteApiCall, site));
    yield takeLatest(contact.type.trigger, simpleAsyncSaga(contactApiCall, contact));
}

// Store helpers
export const getSiteState = (state: MainReducerState) => state.sites.site.data;
export const getContactState = (state: MainReducerState) => state.sites.contact;
export const getSitesState = (state: MainReducerState) => state.sites;
