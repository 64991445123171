import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import accountMessages from '../../../locale/accountMessages';
import Seo from '../../../components/Seo';
import { Button, Modal, Spin, Typography } from 'antd';
import formMessages from '../../../locale/formMessages';
import AddressCard from './AddressCard';
import ModalAddress from './ModalAddress';
import genericMessages from '../../../locale/genericMessages';
import { useActions, usePrevious } from '../../../hooks';
import { useSelector } from 'react-redux';
import { update as updateAction, getUsersUpdateState } from '../../../store/actions/users';
import { getAuthState, checkLoginStatus as checkLoginStatusAction } from '../../../store/actions/auth';
import { Address } from '../../../store/api/apiTypes';
import { isUserDefaultAddress } from '../../../helpers';

const { confirm } = Modal;

const Addresses: FC = () => {
    const { formatMessage } = useIntl();
    const [updateUser, checkLoginStatus] = useActions([updateAction.trigger, checkLoginStatusAction.trigger]);
    const userUpdateState = useSelector(getUsersUpdateState);
    const authState = useSelector(getAuthState);
    const [userId, setUserId] = useState<string>();
    const addresses = authState.user?.addresses;

    const previous = usePrevious({
        userUpdateState,
    });

    const [addressModalVisible, setAddressModalVisible] = useState<boolean>(false);
    const [editAddress, setEditAddress] = useState<Address>();
    const [editAddressIndex, setEditAddressIndex] = useState<number>();

    useEffect(() => {
        setUserId(authState.user?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const add = () => {
        setAddressModalVisible(true);
        setEditAddress(undefined);
        setEditAddressIndex(undefined);
    };

    const edit = (index: number) => {
        setAddressModalVisible(true);
        setEditAddressIndex(index);
        setEditAddress(authState.user?.addresses[index]);
    };

    const remove = (index: number) => {
        closeAddressModal();

        confirm({
            icon: false,
            title: formatMessage(formMessages.delete_address_title),
            content: formatMessage(formMessages.delete_address_message),
            okText: formatMessage(genericMessages.delete),
            cancelText: formatMessage(genericMessages.cancel),
            okButtonProps: { shape: 'round', size: 'large' },
            cancelButtonProps: { shape: 'round', size: 'large', type: 'primary', className: 'reverse' },
            onOk() {
                removeAddressByIndex(index);
            },
        });
    };

    const check = (index: number) => {
        closeAddressModal();
        if (authState?.user) {
            setDefaultAddress(authState.user.addresses[index]);
        }
    };

    const onModalAddressSuccess = (address: Address) => {
        if (authState.user && userId) {
            let addresses: Address[];

            if (editAddressIndex !== undefined) {
                addresses = authState.user.addresses;
                addresses[editAddressIndex] = address;
            } else {
                addresses = [...authState.user.addresses, address];
            }

            updateAddresses(addresses);

            if (address.default) {
                setDefaultAddress(address);
            }

            setAddressModalVisible(false);
        }
    };

    const onModalRemove = () => {
        removeAddressByIndex(editAddressIndex);
        closeAddressModal();
    };

    const closeAddressModal = () => {
        setAddressModalVisible(false);
        setEditAddressIndex(undefined);
    };

    const removeAddressByIndex = (index?: number) => {
        if (authState.user && index !== undefined) {
            const addresses: Address[] = authState.user.addresses;
            addresses.splice(index, 1);
            updateAddresses(addresses);
        }
    };

    const updateAddresses = (addresses: Address[]) => {
        updateUser({
            id: userId,
            body: {
                addresses: addresses,
            },
        });
    };

    const setDefaultAddress = (address: Address) => {
        updateUser({
            id: userId,
            body: {
                defaultAddress: address,
            },
        });
    };

    useEffect(() => {
        if (previous?.userUpdateState.loading && !userUpdateState.loading) {
            if (userUpdateState.success) {
                checkLoginStatus();
            }
        }
    }, [previous, userUpdateState, checkLoginStatus]);

    return (
        <>
            <Seo title={formatMessage(accountMessages.your_addresses)} />
            <div className="header mb-2">
                <Typography.Title level={1} className="title title-1">
                    <FormattedMessage {...accountMessages.your_addresses} />
                </Typography.Title>
            </div>

            <Spin spinning={authState.loading}>
                <div id="addresses-grid">
                    {addresses?.forEach((address, i) => {
                        if (isUserDefaultAddress(authState.user, address) && i !== 0) {
                            addresses?.splice(i, 1);
                            addresses?.unshift(address);
                        }
                    })}
                    {addresses?.map((address, i) => (
                        <AddressCard
                            isDefault={isUserDefaultAddress(authState.user, address)}
                            address={address}
                            onEdit={edit.bind(null, i)}
                            onRemove={remove.bind(null, i)}
                            onCheck={check.bind(null, i)}
                            key={'address-' + i.toString()}
                        />
                    ))}
                </div>
            </Spin>

            <div className="text-center">
                <Button type="primary" size="large" shape="round" className="reverse btn-xl" onClick={add}>
                    <FormattedMessage {...formMessages.add_address} />
                </Button>
            </div>

            <ModalAddress
                deletable={!isUserDefaultAddress(authState.user, editAddress)}
                isVisible={addressModalVisible}
                address={editAddress}
                onClose={closeAddressModal}
                onSuccess={onModalAddressSuccess}
                onDelete={onModalRemove}
            />
        </>
    );
};

export default Addresses;
