import React, { FC } from 'react';
import IconCar from './icons/IconCar';

import '../assets/styles/CardSkeleton.less';
import IconVehicles from './icons/IconVehicles';
import { VehicleType } from '../store/api/apiTypes';
import IconTruck from './icons/IconTruck';
import IconMoto from './icons/IconMoto';
import IconTukTuk from './icons/IconTukTuk';

interface VehicleIconProps {
    type?: string;
}

const VehicleIcon: FC<VehicleIconProps> = ({ type }) => {
    let Icon = IconVehicles;

    switch (type) {
        case VehicleType.car:
            Icon = IconCar;
            break;
        case VehicleType.heavy:
            Icon = IconTruck;
            break;
        case VehicleType.motorcycle:
            Icon = IconMoto;
            break;
        case VehicleType.small:
            Icon = IconTukTuk;
            break;
    }

    return (
        <div className="circle-icon" data-type={type}>
            <Icon className="text-primary" />
        </div>
    );
};

export default VehicleIcon;
