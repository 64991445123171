import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { Parking, ListResponse } from '../api/apiTypes';

// Controlers
import {
    ParkingIdPayload,
    ParkingListPayload,
    ParkingCreatePayload,
    ParkingUpdatePayload,
    list as listApiCall,
    create as createApiCall,
    update as updateApiCall,
    remove as removeApiCall,
    details as detailsApiCall,
} from '../api/parkings';
import { DataAction } from '../helpers/EzeeAction';

// States
export interface ParkingsState {
    create: RequestState<Parking>;
    update: RequestState<Parking>;
    remove: RequestState<Parking>;
    details: RequestState<Parking>;
    list: RequestState<ListResponse<Parking>>;
}

const initialState: ParkingsState = {
    create: {
        data: undefined,
        loading: false,
    },
    update: {
        data: undefined,
        loading: false,
    },
    remove: {
        loading: false,
    },
    details: {
        data: undefined,
        loading: false,
    },
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
};

export const create = new EzeeAsyncAction<ParkingsState['create'], ParkingCreatePayload, Parking>(
    'parkings/create',
    initialState.create,
    requestReducer<ParkingsState['create'], Parking>(initialState.create)
);

export const update = new EzeeAsyncAction<ParkingsState['update'], ParkingUpdatePayload, Parking>(
    'parkings/update',
    initialState.update,
    requestReducer<ParkingsState['update'], Parking>(initialState.update)
);

export const remove = new EzeeAsyncAction<ParkingsState['remove'], ParkingIdPayload, Parking>(
    'parkings/remove',
    initialState.remove,
    requestReducer<ParkingsState['remove'], Parking>(initialState.remove)
);

export const details = new EzeeAsyncAction<ParkingsState['details'], ParkingIdPayload, Parking>(
    'parkings/details',
    initialState.details,
    requestReducer<ParkingsState['details'], Parking>(initialState.details)
);

export const list = new EzeeAsyncAction<ParkingsState['list'], ParkingListPayload, ListResponse<Parking>>(
    'parkings/list',
    initialState.list,
    requestReducer<ParkingsState['list'], ListResponse<Parking>>(initialState.list)
);

// Reducer
export const parkingsReducer = combineReducers<ParkingsState>({
    list: list.reducer,
    update: update.reducer,
    create: create.reducer,
    remove: remove.reducer,
    details: details.reducer,
});

// Saga
export function* parkingsSaga() {
    yield takeLatest(list.type.trigger, listSaga);
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
}

function* listSaga(action: DataAction<ParkingListPayload>) {
    try {
        const response = yield call(listApiCall, action.payload);
        return yield put(
            list.success({
                items: response.parkings,
            })
        );
    } catch (error) {
        return yield put(list.failure(error));
    }
}

// Store helpers
export const getParkingsState = (state: MainReducerState) => state.parkings;
export const getParkingsListState = (state: MainReducerState) => state.parkings.list;
export const getParkingsCreateState = (state: MainReducerState) => state.parkings.create;
export const getParkingsUpdateState = (state: MainReducerState) => state.parkings.update;
export const getParkingsRemoveState = (state: MainReducerState) => state.parkings.remove;
export const getParkingsDetailsState = (state: MainReducerState) => state.parkings.details;
