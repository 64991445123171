import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { ListResponse, Title, Transaction, User } from '../api/apiTypes';

// Controlers
import {
    UserCreatePayload,
    UserUpdatePayload,
    create as createApiCall,
    verification as verificationApiCall,
    update as updateApiCall,
    transaction as transactionApiCall,
    title as titleApiCall,
    titles as titlesApiCall,
    receipt as receiptApiCall,
    UserVerificationPayload,
    UserTransactionPayload,
    UserTransactionListPayload,
    transactionList as pendingTransactionListApiCall,
} from '../api/users';

// States
export interface UsersState {
    create: RequestState<User>;
    update: RequestState<User>;
    verification: RequestState<User>;
    transaction: RequestState<Transaction>;
    transactionPendingList: RequestState<ListResponse<Transaction>>;
    title: RequestState<Title>;
    titles: RequestState<Title[]>;
    receipt: RequestState<any>;
}

const initialState: UsersState = {
    create: {
        data: undefined,
        loading: false,
    },
    update: {
        data: undefined,
        loading: false,
    },
    verification: {
        data: undefined,
        loading: false,
    },
    transaction: {
        data: undefined,
        loading: false,
    },
    transactionPendingList: {
        data: undefined,
        loading: false,
    },
    title: {
        data: undefined,
        loading: false,
    },
    titles: {
        data: undefined,
        loading: false,
    },
    receipt: {
        data: undefined,
        loading: false,
    },
};

export const create = new EzeeAsyncAction<UsersState['create'], UserCreatePayload, User>(
    'users/create',
    initialState.create,
    requestReducer<UsersState['create'], User>(initialState.create)
);

export const update = new EzeeAsyncAction<UsersState['update'], UserUpdatePayload, User>(
    'users/update',
    initialState.update,
    requestReducer<UsersState['update'], User>(initialState.update)
);

export const verification = new EzeeAsyncAction<UsersState['verification'], UserVerificationPayload, User>(
    'users/verification',
    initialState.verification,
    requestReducer<UsersState['verification'], User>(initialState.verification)
);

export const transaction = new EzeeAsyncAction<UsersState['transaction'], UserTransactionPayload, User>(
    'users/transaction',
    initialState.transaction,
    requestReducer<UsersState['transaction'], User>(initialState.transaction)
);

export const transactionPendingList = new EzeeAsyncAction<
    UsersState['transactionPendingList'],
    UserTransactionListPayload,
    User
>(
    'users/transactionPendingList',
    initialState.transactionPendingList,
    requestReducer<UsersState['transactionPendingList'], User>(initialState.transactionPendingList)
);

export const title = new EzeeAsyncAction<UsersState['title'], UserTransactionPayload, User>(
    'users/title',
    initialState.title,
    requestReducer<UsersState['title'], User>(initialState.title)
);

export const titles = new EzeeAsyncAction<UsersState['titles'], UserTransactionPayload, User>(
    'users/titles',
    initialState.titles,
    requestReducer<UsersState['titles'], User>(initialState.titles)
);

export const receipt = new EzeeAsyncAction<UsersState['receipt'], UserTransactionPayload, User>(
    'users/receipt',
    initialState.receipt,
    requestReducer<UsersState['receipt'], User>(initialState.receipt)
);

// Reducer
export const usersReducer = combineReducers<UsersState>({
    update: update.reducer,
    create: create.reducer,
    verification: verification.reducer,
    transaction: transaction.reducer,
    transactionPendingList: transactionPendingList.reducer,
    title: title.reducer,
    titles: titles.reducer,
    receipt: receipt.reducer,
});

// Saga
export function* usersSaga() {
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(verification.type.trigger, simpleAsyncSaga(verificationApiCall, verification));
    yield takeLatest(transaction.type.trigger, simpleAsyncSaga(transactionApiCall, transaction));
    yield takeLatest(
        transactionPendingList.type.trigger,
        simpleAsyncSaga(pendingTransactionListApiCall, transactionPendingList)
    );
    yield takeLatest(title.type.trigger, simpleAsyncSaga(titleApiCall, title));
    yield takeLatest(titles.type.trigger, simpleAsyncSaga(titlesApiCall, titles));
    yield takeLatest(receipt.type.trigger, simpleAsyncSaga(receiptApiCall, receipt));
}

// Store helpers
export const getUsersState = (state: MainReducerState) => state.users;
export const getUsersCreateState = (state: MainReducerState) => state.users.create;
export const getUsersUpdateState = (state: MainReducerState) => state.users.update;
export const getUsersVerificationState = (state: MainReducerState) => state.users.verification;
export const getTransactionState = (state: MainReducerState) => state.users.transaction;
export const getTransactionPendingListState = (state: MainReducerState) => state.users.transactionPendingList;
export const getTitleState = (state: MainReducerState) => state.users.title;
export const getTitlesState = (state: MainReducerState) => state.users.titles;
export const getReceiptState = (state: MainReducerState) => state.users.receipt;
