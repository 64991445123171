import React, { FC, useContext, useState } from 'react';
import { Alert, Button, Form, Skeleton, Spin } from 'antd';

import Seo from '../../components/Seo';
import ParkingCard, { ParkingCardProps } from './ParkingCard';
import ParkingPicker from './ParkingPicker';
import SubscriptionDatePicker from './SubscriptionDatePicker';
import VehicleTypePicker from './VehicleTypePicker';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import Modal from '../../components/Modal';
import { useHistory } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';
import { getParkingsListState } from '../../store/actions/parkings';
import { useSelector } from 'react-redux';
import genericMessages from '../../locale/genericMessages';
import useSessionStorage from '../../hooks/sessionStorage';
import { Parking } from '../../store/api/apiTypes';
import { SiteContext } from '../../context/SiteContext';
import formMessages from '../../locale/formMessages';

import '../../assets/styles/Parkings.less';

import background from '../../assets/images/home-bg.jpg';

const Home: FC = () => {
    const { formatMessage } = useIntl();
    const { site } = useContext(SiteContext);
    const listParkingsState = useSelector(getParkingsListState);

    const history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [modalForm] = Form.useForm();
    const [searchValues, setSearchValues] = useSessionStorage('searchValues', undefined);
    const [selectedParking, setSelectedParking] = useState<Parking>();

    const backgroundImage = site?.bannerImage ?? background;

    const onParkingSelect: ParkingCardProps['onSelect'] = (parking) => {
        setSelectedParking(parking);
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
    };

    const onSearchFormFinish = () => {
        form.validateFields().then((values) => {
            setSearchValues(values);
            history.push(getRoute(RoutePathName.offers));
        });
    };

    const onModalFormFinish = () => {
        modalForm.validateFields().then((values) => {
            setSearchValues({
                ...values,
                parking: selectedParking,
            });
            history.push(getRoute(RoutePathName.offers));
        });
    };
    return (
        <>
            <Seo title="Parkings" />
            <div
                id="search-masterhead"
                style={{
                    backgroundImage: 'url(' + backgroundImage + ')',
                }}
            >
                <div className="wrapper">
                    <div className="title font-title">
                        <FormattedMessage {...messages.parkings_search_headline} />
                    </div>
                    <Form form={form} onFinish={onSearchFormFinish} initialValues={searchValues} id="searchbar">
                        <Form.Item
                            name="parking"
                            rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                        >
                            <ParkingPicker />
                        </Form.Item>
                        <Form.Item
                            name="subscriptionDate"
                            rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                        >
                            <SubscriptionDatePicker />
                        </Form.Item>
                        <Form.Item
                            name="vehicleType"
                            rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                        >
                            <VehicleTypePicker />
                        </Form.Item>
                        <Button
                            type="primary"
                            shape="round"
                            size="large"
                            className="btn-submit"
                            htmlType="submit"
                            disabled={!site?.enabled}
                        >
                            <FormattedMessage {...messages.parking_search_submit_cta} />
                        </Button>
                    </Form>
                </div>
            </div>

            <div id="parkings-list" className="wrapper">
                <h1 className="title title-2 text-center mt-3">
                    <FormattedMessage {...messages.our_parkings} />
                </h1>
                <h2 className="title title-1 text-center mb-3">
                    <FormattedMessage {...messages.our_parkings_baseline} />
                </h2>

                <Spin spinning={listParkingsState.loading}>
                    {listParkingsState.data && listParkingsState.data?.items.length > 0 ? (
                        <div id="parkings-grid">
                            {listParkingsState.data.items.map((parking) => (
                                <ParkingCard
                                    parking={parking}
                                    onSelect={onParkingSelect}
                                    key={'parking-' + parking.id.toString()}
                                />
                            ))}
                        </div>
                    ) : (
                        <>
                            {listParkingsState.error ? (
                                <Alert
                                    type="error"
                                    message={<FormattedMessage {...genericMessages.error_not_available} />}
                                    showIcon
                                />
                            ) : (
                                <div id="parkings-grid">
                                    <Skeleton loading={true} />
                                    <Skeleton loading={true} />
                                    <Skeleton loading={true} />
                                </div>
                            )}
                        </>
                    )}
                </Spin>
            </div>

            <Modal visible={isModalVisible} onCancel={closeModal}>
                <div className="modal-title">
                    <FormattedMessage {...messages.modal_offers_title} />
                </div>

                <Form form={modalForm} onFinish={onModalFormFinish} initialValues={searchValues}>
                    <div className="modal-body">
                        <Form.Item
                            name="subscriptionDate"
                            rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                        >
                            <SubscriptionDatePicker />
                        </Form.Item>
                        <Form.Item
                            name="vehicleType"
                            rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                        >
                            <VehicleTypePicker />
                        </Form.Item>
                    </div>

                    <Button type="primary" shape="round" size="large" block htmlType="submit">
                        <FormattedMessage {...messages.parking_card_offers_cta} />
                    </Button>
                </Form>
            </Modal>
        </>
    );
};

export default Home;
