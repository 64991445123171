import React, { FC, ReactNode } from 'react';
import { Select as AntSelect, SelectProps } from 'antd';

import '../../assets/styles/form/Select.less';

const Select: FC<SelectProps<any>> = ({ ...props }) => {
    const placeholder: ReactNode = props.placeholder;
    delete props.placeholder;

    const onInputChange: SelectProps<any>['onChange'] = (e, option) => {
        if (props.onChange) {
            props.onChange(e, option);
        }
    };

    return (
        <div className={'input-float-label ' + (!props.value && !props.defaultValue ? 'empty' : 'not-empty')}>
            <label className="float-label">{placeholder}</label>
            <AntSelect {...props} onChange={onInputChange} defaultActiveFirstOption={false} />
        </div>
    );
};

export default Select;
