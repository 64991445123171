import { defineMessages } from 'react-intl';

const messages = defineMessages({
    instructionPaymentNMI: {
        id: 'paymentNMI.instruction',
        defaultMessage: 'Entrez les informations de la carte',
    },
    buttonPaymentNMI: {
        id: 'paymentNMI.button',
        defaultMessage: 'Soumettre le paiement',
    },
    failure_paymentNMI_N: {
        id: 'paymentNMI.failure_paymentNMI_N',
        defaultMessage: 'Transaction refusée',
    },
    failure_paymentNMI_R: {
        id: 'paymentNMI.failure_paymentNMI_R',
        defaultMessage: 'L’émetteur refuse l’authentification/vérification',
    },
    failure_paymentNMI_U: {
        id: 'paymentNMI.failure_paymentNMI_U',
        defaultMessage: 'Problème technique ou autre',
    },
    failure_paymentNMI: {
        id: 'paymentNMI.failure_paymentNMI',
        defaultMessage: 'Echec non prévu',
    },
    missingNMIToken: {
        id: 'paymentNMI.missing_token',
        defaultMessage:
            'Nous sommes désolés, une erreur est survenue. Veuillez réessayer plus tard ou contacter le support. (code: NMI token)',
    },
});

export default messages;
