import { defineMessages } from 'react-intl';

export default defineMessages({
    parkings_search_headline: {
        id: 'parkings.parkings_search_headline',
        defaultMessage: 'VOUS RECHERCHEZ UN PARKING PROCHE DE VOUS ?',
    },
    parking_picker_label: {
        id: 'parkings.parking_picker_label',
        defaultMessage: 'Parking',
    },
    parking_picker_placeholder: {
        id: 'parkings.parking_picker_placeholder',
        defaultMessage: 'Sélectionnez un parking',
    },
    parking_picker_search: {
        id: 'parkings.parking_picker_search',
        defaultMessage: 'Recherchez un parking',
    },
    subscription_picker_label: {
        id: 'parkings.subscription_picker_label',
        defaultMessage: "Début de l'abonnement",
    },
    subscription_picker_placeholder: {
        id: 'parkings.subscription_picker_placeholder',
        defaultMessage: 'Sélectionnez une date',
    },
    vehicletype_picker_label: {
        id: 'parkings.vehicletype_picker_label',
        defaultMessage: 'Type de véhicule',
    },
    vehicletype_picker_placeholder: {
        id: 'parkings.vehicletype_picker_placeholder',
        defaultMessage: 'Sélectionnez un type',
    },
    parking_search_submit_cta: {
        id: 'parkings.parking_search_submit_cta',
        defaultMessage: 'VOIR LES OFFRES',
    },
    our_parkings: {
        id: 'parkings.our_parkings',
        defaultMessage: 'NOS PARKINGS',
    },
    our_parkings_baseline: {
        id: 'parkings.our_parkings_baseline',
        defaultMessage: 'Quand stationner devient un jeu d’enfant !',
    },
    parking_card_offers_cta: {
        id: 'parkings.parking_card_offers_cta',
        defaultMessage: 'Voir les offres disponibles',
    },
    parking_card_map_cta: {
        id: 'parkings.parking_card_map_cta',
        defaultMessage: 'S’y rendre',
    },
    parking_card_phone_cta: {
        id: 'parkings.parking_card_phone_cta',
        defaultMessage: 'Téléphone',
    },
    parking_card_phone_copied: {
        id: 'parkings.parking_card_phone_copied',
        defaultMessage: 'Copié',
    },
    parking_card_total_spots: {
        id: 'parking_card_total_spots',
        defaultMessage: `
            {count, plural,
                =0 {aucune place}
                one {1 place}
                other {{count, number} places}
            }
        `,
    },
    modal_offers_title: {
        id: 'parkings.modal_offers_title',
        defaultMessage: 'Remplissez les critères suivants pour afficher les offres :',
    },
});
