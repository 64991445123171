import { Button, Skeleton, Spin } from 'antd';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Breakpoint } from 'react-socks';
import IconArrowDown from '../../components/icons/IconArrowDown';
import genericMessages from '../../locale/genericMessages';
import messages from './messages';
import { useSelector } from 'react-redux';
import { list as listAction, getParkingsListState } from '../../store/actions/parkings';
import { Parking } from '../../store/api/apiTypes';
import { useActions } from '../../hooks';
import { SiteContext } from '../../context/SiteContext';

import '../../assets/styles/CustomPicker.less';

interface ParkingPickerProps {
    onChange?: (value?: Parking) => void;
    value?: Parking;
}

const ParkingPicker: FC<ParkingPickerProps> = (props) => {
    const listParkingsState = useSelector(getParkingsListState);
    const { formatMessage } = useIntl();
    const container: any = useRef();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [value, setValue] = useState<Parking>();
    const { siteId } = useContext(SiteContext);
    const [loadParkings, reset] = useActions([listAction.trigger, listAction.reset]);

    useEffect(() => {
        if (props.onChange) {
            setValue(props.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    useEffect(() => {
        if (props.onChange) {
            setValue(props.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    // ---------------------------------
    // Close search on click outside

    const handleClick = (e: any) => {
        if (!container.current.contains(e.target)) {
            setIsOpen(false);
            setSearchValue('');
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        loadParkings({ siteId });

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSearch = (e: React.FormEvent<HTMLInputElement>) => {
        setSearchValue(e.currentTarget.value);
    };

    const onSelect = (parking: Parking) => {
        setIsOpen(false);

        if (props.onChange) {
            props.onChange(parking);
        }
    };

    // ---------------------------------
    // Render

    const getFilteredList = () => {
        return (
            listParkingsState.data?.items.filter(
                (parking) => !searchValue || parking.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
            ) ?? []
        );
    };

    useEffect(
        () => () => {
            console.log('reset');
            reset();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <div className={'parking-picker custom-picker ' + (value ? 'has-value' : '')} ref={container}>
            <div className="btn-open" onClick={setIsOpen.bind(null, true)} aria-hidden="true">
                <div className="content">
                    <Breakpoint lg down>
                        <div className="value">
                            {value?.name ?? (
                                <span className="placeholder">
                                    <FormattedMessage {...messages.parking_picker_label} />
                                </span>
                            )}
                        </div>
                    </Breakpoint>
                    <Breakpoint xl up>
                        <div className="label">
                            <FormattedMessage {...messages.parking_picker_label} />
                        </div>
                        <div className="value">
                            {value?.name ?? (
                                <span className="placeholder">
                                    <FormattedMessage {...messages.parking_picker_placeholder} />
                                </span>
                            )}
                        </div>
                    </Breakpoint>
                </div>
                <IconArrowDown />
            </div>

            {isOpen && (
                <div className="custom-picker-overlay">
                    <div className="search">
                        <input
                            type="text"
                            placeholder={formatMessage(messages.parking_picker_search)}
                            value={searchValue}
                            onChange={onSearch}
                        />
                        <Button type="text" className="btn-cancel text-primary" onClick={setIsOpen.bind(null, false)}>
                            <FormattedMessage {...genericMessages.cancel} />
                        </Button>
                    </div>
                    <Spin spinning={listParkingsState.loading}>
                        <div className="results-list">
                            {listParkingsState.data && listParkingsState.data?.items.length > 0 ? (
                                <ul>
                                    {getFilteredList().map((parking) => (
                                        <li
                                            key={'parking' + parking.id.toString()}
                                            onClick={onSelect.bind(this, parking)}
                                            role="presentation"
                                        >
                                            {parking.name}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <Skeleton loading={true} />
                            )}
                        </div>
                    </Spin>
                </div>
            )}
        </div>
    );
};

export default ParkingPicker;
