import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 15 14">
        <path
            fill="currentColor"
            d="M13.6946432,0.30535676 C14.0678571,0.678570578 14.0989582,1.26434596 13.7879467,1.6729867 L13.6946432,1.77974962 L8.474,7 L13.6946432,12.2202504 C14.1017856,12.6273927 14.1017856,13.2875009 13.6946432,13.6946432 C13.3214294,14.0678571 12.735654,14.0989582 12.3270133,13.7879467 L12.2202504,13.6946432 L7,8.474 L1.77974962,13.6946432 C1.37260728,14.1017856 0.712499107,14.1017856 0.30535676,13.6946432 C-0.0678570578,13.3214294 -0.0989582093,12.735654 0.212053306,12.3270133 L0.30535676,12.2202504 L5.526,7 L0.30535676,1.77974962 C-0.101785587,1.37260728 -0.101785587,0.712499107 0.30535676,0.30535676 C0.678570578,-0.0678570578 1.26434596,-0.0989582093 1.6729867,0.212053306 L1.77974962,0.30535676 L7,5.526 L12.2202504,0.30535676 C12.6273927,-0.101785587 13.2875009,-0.101785587 13.6946432,0.30535676 Z"
        ></path>
    </svg>
);

const IconClose: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-close${props.className ? ` ${props.className}` : ''}`} />
);

export default IconClose;
