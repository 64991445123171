import { defineMessages } from 'react-intl';

export default defineMessages({
    available_offers: {
        id: 'offers.available_offers',
        defaultMessage: `
            {count, plural,
                =0 {aucune offre disponible actuellement}
                one {1 offre disponible actuellement}
                other {{count, number} offres disponible actuellement}
            }
        `,
    },
    title: {
        id: 'offers.title',
        defaultMessage: 'Trouvez l’offre qui correspond à votre besoin',
    },
    cta_select_offer: {
        id: 'offers.cta_select_offer',
        defaultMessage: 'Choisir cette offre',
    },
    cta_edit_search: {
        id: 'offers.cta_edit_search',
        defaultMessage: 'Modifier recherche',
    },
});
