import { Button } from 'antd';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Price from '../../components/Price';
import VehicleIcon from '../../components/VehicleIcon';
import genericMessages from '../../locale/genericMessages';
import { Offer } from '../../store/api/apiTypes';
import messages from './messages';

export interface OfferCardProps {
    offer: Offer;
    onSelect: (offer: Offer) => void;
}

const OfferCard: FC<OfferCardProps> = ({ offer, onSelect }) => {
    const select = () => {
        onSelect(offer);
    };

    return (
        <div className="offer-card" onClick={select} aria-hidden="true">
            <div className="body text-center">
                <div className="flex-grow">
                    <VehicleIcon type={offer.vehicleClass} />
                    <div className="subscription-name">{offer.offerName}</div>
                    {offer.zones?.map((zone, index) => (
                        <div className="parking-name" key={'offer-zone-' + index.toString()}>
                            {zone.parkingName} - {zone.zoneName}
                        </div>
                    ))}
                </div>
                <div className="price-container mt-2 mb-2">
                    <small>
                        <FormattedMessage {...genericMessages.price_from} />
                    </small>
                    {offer?.aboPriceByMonth && (
                        <div className="price text-primary">
                            <Price
                                value={offer.aboPriceByMonth * (offer.months ? offer.months : 1)}
                                currency={offer.currencyIso}
                            />
                        </div>
                    )}
                </div>
                <Button type="primary" shape="round" size="large" block className="reverse">
                    <FormattedMessage {...messages.cta_select_offer} />
                </Button>
            </div>
        </div>
    );
};

export default OfferCard;
