import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 18 18">
        <path
            fill="currentColor"
            d="M8.9963561,0 C9.60252229,0 10.0939171,0.491394787 10.0939171,1.09756098 C10.0939171,1.66584178 9.66202712,2.13324928 9.10857533,2.18945537 L8.9963561,2.19512195 L2.19512195,2.19512195 L2.19512195,15.804878 L9,15.804878 C9.5682808,15.804878 10.0356883,16.236768 10.0918944,16.7902198 L10.097561,16.902439 C10.097561,17.4707198 9.66567103,17.9381273 9.11221923,17.9943334 L9,18 L1.09756098,18 C0.529280174,18 0.0618726741,17.5681101 0.00566658359,17.0146583 L0,16.902439 L0,1.09756098 C0,0.529280174 0.431889949,0.0618726741 0.985341742,0.00566658359 L1.09756098,0 L8.9963561,0 Z M13.6381945,4.19267782 L13.7273123,4.27268768 L17.6330054,8.17724864 C17.85821,8.3782507 18,8.67074499 18,8.9963561 L17.9939795,9.11514976 C17.9725748,9.31923793 17.8940956,9.51847314 17.7585417,9.68697494 L17.6785318,9.77609281 L13.7273123,13.7273123 C13.2986881,14.1559365 12.6037509,14.1559365 12.1751267,13.7273123 C11.7750774,13.327263 11.7484075,12.6952256 12.0951168,12.2642446 L12.1751267,12.1751267 L14.256,10.093 L5.48780488,10.0939171 C4.88163869,10.0939171 4.3902439,9.60252229 4.3902439,8.9963561 C4.3902439,8.4280753 4.82213385,7.9606678 5.37558564,7.90446171 L5.48780488,7.89879512 L14.249,7.898 L12.1751267,5.8248733 C11.7750774,5.42482402 11.7484075,4.79278658 12.0951168,4.36180555 L12.1751267,4.27268768 C12.575176,3.8726384 13.2072134,3.84596845 13.6381945,4.19267782 Z"
        ></path>
    </svg>
);

const IconLogout: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-logout${props.className ? ` ${props.className}` : ''}`} />
);

export default IconLogout;
