import React, { createContext, useState, FC } from 'react';
import { Site, Transaction } from '../store/api/apiTypes';

interface SiteContextTypes {
    site: Site | undefined;
    setSite: React.Dispatch<React.SetStateAction<Site | undefined>>;
    siteId: string;
    setSiteId: React.Dispatch<React.SetStateAction<string>>;
    transaction: Transaction | undefined;
    setTransaction: React.Dispatch<React.SetStateAction<Transaction | undefined>>;
}

export const SiteContext = createContext<SiteContextTypes>({
    site: undefined,
    setSite: () => undefined,
    siteId: '',
    setSiteId: () => undefined,
    transaction: undefined,
    setTransaction: () => undefined,
});

export const SiteContextProvider: FC = ({ children }) => {
    const [transaction, setTransaction] = useState<Transaction>();
    const [site, setSite] = useState<Site>();
    const [siteId, setSiteId] = useState('');

    const siteContext = {
        site,
        setSite,
        siteId,
        setSiteId,
        transaction,
        setTransaction,
    };

    return <SiteContext.Provider value={siteContext}>{children}</SiteContext.Provider>;
};
