import { defineMessages } from 'react-intl';

const messages = defineMessages({
    error_required: {
        id: 'form.error_required',
        defaultMessage: 'Champ obligatoire',
    },
    error_plate: {
        id: 'form.error_plate',
        defaultMessage: 'Numéro de plaque invalide',
    },
    error_old_password_not_match: {
        id: 'form.error_old_password_not_match',
        defaultMessage: 'Votre ancien mot de passe est invalide',
    },
    all_fields_required: {
        id: 'form.all_fields_required',
        defaultMessage: 'Tous les champs sont obligatoires',
    },
    all_fields_required_except: {
        id: 'form.all_fields_required_except',
        defaultMessage: 'Les champs ci-dessus sont obligatoires, sauf si mentionné. ',
    },
    error_default: {
        id: 'form.error_default',
        defaultMessage: 'Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur',
    },
    update_success: {
        id: 'form.update_success',
        defaultMessage: 'Mise à jour effectuée avec succès',
    },
    contact_success: {
        id: 'form.contact_success',
        defaultMessage: 'Votre message a été envoyé avec succès',
    },
    user_type_label: {
        id: 'form.user_type_label',
        defaultMessage: 'Vous êtes :',
    },
    user_type_individual: {
        id: 'form.user_type_individual',
        defaultMessage: 'Particulier',
    },
    user_type_company: {
        id: 'form.user_type_company',
        defaultMessage: 'Société',
    },
    civility_label: {
        id: 'form.civility_label',
        defaultMessage: 'Civilité :',
    },
    civility_mme: {
        id: 'form.civility_mme',
        defaultMessage: 'Mme',
    },
    civility_mr: {
        id: 'form.civility_mr',
        defaultMessage: 'M.',
    },
    civility_ms: {
        id: 'form.civility_ms',
        defaultMessage: 'Ms',
    },
    civility_mlle: {
        id: 'form.civility_mlle',
        defaultMessage: 'Mlle',
    },
    civility_autre: {
        id: 'form.civility_autre',
        defaultMessage: 'Autre',
    },
    username_label: {
        id: 'form.username_label',
        defaultMessage: "Nom d'utilisateur",
    },
    email_label: {
        id: 'form.email_label',
        defaultMessage: 'Adresse e-mail',
    },
    email_error: {
        id: 'form.email_error',
        defaultMessage: 'Veuillez renseigner votre e-mail',
    },
    password_label: {
        id: 'form.password_label',
        defaultMessage: 'Mot de passe',
    },
    current_password_label: {
        id: 'form.current_password_label',
        defaultMessage: 'Mot de passe actuel',
    },
    new_password_label: {
        id: 'form.new_password_label',
        defaultMessage: 'Nouveau mot de passe',
    },
    confirm_password_label: {
        id: 'form.confirm_password_label',
        defaultMessage: 'Confirmer le mot de passe',
    },
    password_error: {
        id: 'form.password_error',
        defaultMessage: 'Veuillez renseigner votre mot de passe',
    },
    password_invalid: {
        id: 'form.password_invalid',
        defaultMessage: 'Mot de passe non valide',
    },
    password_instructions_title: {
        id: 'form.password_instructions_title',
        defaultMessage: 'Votre mot de passe doit contenir :',
    },
    password_instructions_1: {
        id: 'form.password_instructions_1',
        defaultMessage: '10 caractères minimum',
    },
    password_instructions_2: {
        id: 'form.password_instructions_2',
        defaultMessage: 'Au moins un chiffre',
    },
    password_instructions_3: {
        id: 'form.password_instructions_3',
        defaultMessage: 'Au moins une lettre en majuscule',
    },
    password_instructions_4: {
        id: 'form.password_instructions_4',
        defaultMessage: 'Au moins un caractère spécial',
    },
    facebook_signin: {
        id: 'form.facebook_signin',
        defaultMessage: 'CONTINUER AVEC FACEBOOK',
    },
    google_signin: {
        id: 'form.google_signin',
        defaultMessage: 'CONTINUER AVEC GOOGLE',
    },
    firstname_label: {
        id: 'form.firstname_label',
        defaultMessage: 'Prénom',
    },
    firstname_label_offer: {
        id: 'form.offer.firstname_label',
        defaultMessage: 'Prénom  (max: 15 caractères)',
    },
    lastname_label: {
        id: 'form.lastname_label',
        defaultMessage: 'Nom',
    },
    lastname_label_offer: {
        id: 'form.offer.lastname_label',
        defaultMessage: 'Nom (max: 20 caractères)',
    },
    phone_country_label: {
        id: 'form.phone_country_label',
        defaultMessage: 'Indicatif pays',
    },
    phone_label: {
        id: 'form.phone_label',
        defaultMessage: 'Numéro de téléphone',
    },
    address_label: {
        id: 'form.address_label',
        defaultMessage: 'Adresse (n° et nom de rue)',
    },
    address_2_label: {
        id: 'form.address_2_label',
        defaultMessage: "Complément d'adresse (optionnel)",
    },
    zipcode_label: {
        id: 'form.zipcode_label',
        defaultMessage: 'Code postal',
    },
    city_label: {
        id: 'form.city_label',
        defaultMessage: 'Ville',
    },
    country_label: {
        id: 'form.country_label',
        defaultMessage: 'Pays',
    },
    company_name_label: {
        id: 'form.company_name_label',
        defaultMessage: 'Nom de la société',
    },
    company_siret_label: {
        id: 'form.company_siret_label',
        defaultMessage: 'SIRET',
    },
    company_vat_label: {
        id: 'form.company_vat_label',
        defaultMessage: 'TVA Intra-communautaire',
    },
    company_info_label: {
        id: 'form.company_info_label',
        defaultMessage: 'Notes complémentaires (optionnel)',
    },
    message_label: {
        id: 'form.message_label',
        defaultMessage: 'Message',
    },
    total_subscription_label: {
        id: 'form.total_subscription_label',
        defaultMessage: 'Nombre d’abonnements souscris',
    },
    badge_type_label: {
        id: 'form.badge_type_label',
        defaultMessage: 'Type de badge',
    },
    badge_type_dematerialized_label: {
        id: 'form.badge_type_dematerialized_label',
        defaultMessage: 'Dématérialisé',
    },
    badge_type_physical_label: {
        id: 'form.badge_type_physical_label',
        defaultMessage: 'Physique',
    },
    badge_type_both_label: {
        id: 'form.badge_type_both_label',
        defaultMessage: 'Dématérialisé + Physique',
    },
    end_subscription_notification_label: {
        id: 'form.end_subscription_notification_label',
        defaultMessage: 'Être averti de l’écheance',
    },
    registration_label: {
        id: 'form.registration_label',
        defaultMessage: 'Immatriculation',
    },
    invoice_address_label: {
        id: 'form.invoice_address_label',
        defaultMessage: 'Adresse de facturation',
    },
    badge_delivery_address_label: {
        id: 'form.badge_delivery_address_label',
        defaultMessage: 'Adresse de livraison du badge',
    },
    same_address_for_delivery_label: {
        id: 'form.same_address_for_delivery_label',
        defaultMessage: 'Identique à l’adresse de facturation',
    },
    total_without_vat: {
        id: 'form.total_without_vat',
        defaultMessage: 'Total HT',
    },
    total_vat: {
        id: 'form.total_vat',
        defaultMessage: '{name} ({rate}%)',
    },
    total_to_pay: {
        id: 'form.total_to_pay',
        defaultMessage: 'Total à payer',
    },
    accept_cgv: {
        id: 'form.accept_cgv',
        defaultMessage: 'Vous reconnaissez avoir pris connaissance des {cgv} et vous les acceptez',
    },
    cgv: {
        id: 'form.cgv',
        defaultMessage: 'conditions générales de vente',
    },
    add_address: {
        id: 'form.add_address',
        defaultMessage: 'Ajouter une adresse',
    },
    confirm_billing_address: {
        id: 'form.confirm_billing_address',
        defaultMessage: 'Facturer à cette adresse',
    },
    confirm_delivery_address: {
        id: 'form.confirm_delivery_address',
        defaultMessage: 'Livrer à cette adresse',
    },
    add_new_address: {
        id: 'form.add_new_address',
        defaultMessage: 'Ajouter une nouvelle adresse',
    },
    edit_address: {
        id: 'form.edit_address',
        defaultMessage: "Modifier l'adresse",
    },
    cta_delete_address: {
        id: 'form.cta_delete_address',
        defaultMessage: "Supprimer l'adresse",
    },
    cta_update: {
        id: 'form.cta_update',
        defaultMessage: 'Mettre à jour',
    },
    type_label: {
        id: 'form.type_label',
        defaultMessage: 'Type :',
    },
    type_personal_label: {
        id: 'form.type_personal_label',
        defaultMessage: 'Personnelle',
    },
    type_pro_label: {
        id: 'form.type_pro_label',
        defaultMessage: 'Professionnelle',
    },
    define_as_default_address: {
        id: 'form.define_as_default_address',
        defaultMessage: 'Définir comme adresse par défaut',
    },
    default_address: {
        id: 'form.default_address',
        defaultMessage: 'Votre adresse par défaut',
    },
    cta_save_address: {
        id: 'form.cta_save_address',
        defaultMessage: "Enregistrer l'adresse",
    },
    cta_update_password: {
        id: 'form.cta_update_password',
        defaultMessage: 'Modifier le mot de passe',
    },
    delete_address_title: {
        id: 'form.delete_address_title',
        defaultMessage: 'Supprimer l’adresse de livraison',
    },
    delete_address_message: {
        id: 'form.delete_address_message',
        defaultMessage: 'Souhaitez-vous vraiment supprimer cette adresse ? L’action ne pourra pas être annulée.',
    },
    payment_summary: {
        id: 'form.payment_summary',
        defaultMessage: 'Récapitulatif paiement',
    },
    configuration: {
        id: 'form.configuration',
        defaultMessage: 'Configuration',
    },
    user_and_vehicle: {
        id: 'form.user_and_vehicle',
        defaultMessage: 'Utilisateur et véhicule(s)',
    },
    addresses_billing_delivery: {
        id: 'form.addresses_billing_delivery',
        defaultMessage: 'Adresses de facturation et livraison',
    },
    billing: {
        id: 'form.billing',
        defaultMessage: 'Facturation',
    },
    delivery: {
        id: 'form.delivery',
        defaultMessage: 'Livraison',
    },
    badge_label: {
        id: 'form.badge_label',
        defaultMessage: 'Badge :',
    },
    end_notification_label: {
        id: 'form.end_notification_label',
        defaultMessage: 'Notification fin d’échance :',
    },
    card_number_label: {
        id: 'form.card_number_label',
        defaultMessage: 'N° carte :',
    },
    user_label: {
        id: 'form.user_label',
        defaultMessage: 'Utilisateur :',
    },
    vehicles_label: {
        id: 'form.vehicles_label',
        defaultMessage: 'Véhicule(s) :',
    },
    period_type_startNextMonth: {
        id: 'form.period_type_startNextMonth',
        defaultMessage: 'Démarrage mois suivant',
    },
    period_type_startCurrentMonth: {
        id: 'form.period_type_startCurrentMonth',
        defaultMessage: 'Avec prorata mois en cours',
    },
    period_type_endOfCurrentMonth: {
        id: 'form.period_type_endOfCurrentMonth',
        defaultMessage: 'Démarrage mois courant',
    },
    period_type_endOfCurrentMonthThenNextPeriod: {
        id: 'form.period_type_endOfCurrentMonthThenNextPeriod',
        defaultMessage: 'Avec prorata mois en cours + suivant',
    },
    period_type_fixedDate: {
        id: 'form.period_type_fixedDate',
        defaultMessage: 'À date fixe',
    },
    alert_activated: {
        id: 'form.alert_activated',
        defaultMessage: 'Activée',
    },
    alert_deactivated: {
        id: 'form.alert_deactivated',
        defaultMessage: 'Désactivée',
    },
    reset_password_button_connect: {
        id: 'reset_password_button_connect',
        defaultMessage: 'Connexion',
    },
    reset_password_success: {
        id: 'reset_password_success',
        defaultMessage: 'Votre mot de passe a été modifié avec succès',
    },
    reset_password_new_password: {
        id: 'reset_password_new_password',
        defaultMessage: 'Merci de saisir un nouveau mot de passe ci-dessous',
    },
    reset_password_error: {
        id: 'reset_password_error',
        defaultMessage: 'Nous sommes désolés, une erreur est survenue. Veuillez réessayer plus tard.',
    },
    reset_password_error_400: {
        id: 'reset_password_error_400',
        defaultMessage: 'Mot de passe non valide',
    },
    reset_password_error_404: {
        id: 'reset_password_error_404',
        defaultMessage: 'Adresse e-mail non trouvée.',
    },
    reset_password_new_password_rule: {
        id: 'reset_password_new_password_rule',
        defaultMessage: 'Veuillez renseigner votre nouveau mot de passe',
    },
    reset_password_button: {
        id: 'reset_password_button',
        defaultMessage: 'Réinitialiser votre mot de passe',
    },
    reset_password_resend: {
        id: 'reset_password_resend',
        defaultMessage: 'Renvoyer une invitation',
    },
    reset_password_expiration: {
        id: 'reset_password_expiration',
        defaultMessage: 'L’invitation a expiré.',
    },
    reset_password_resendInvitationSuccess: {
        id: 'reset_password_resendInvitationSuccess',
        defaultMessage: 'Vous allez recevoir une nouvelle invitation par email',
    },
    reset_password_title: {
        id: 'reset_password_title',
        defaultMessage: 'Réinitialisation du mot de passe',
    },
    reset_password_new_password_error: {
        id: 'reset_password_new_password_error',
        defaultMessage: 'Mot de passe non valide',
    },
    reset_password_new_password_label: {
        id: 'reset_password_new_password_label',
        defaultMessage: 'Nouveau mot de passe',
    },
});

export default messages;
