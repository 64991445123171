import api from './_client';

// Types
import { Parking } from './apiTypes';

export interface ContactPayload {
    lastName: string;
    firstName: string;
    email: string;
    message: string;
    send_to: string;
    send_cc: boolean;
}

export interface SitePayload {
    id: Parking['id'];
}

// Routes

export const contact = async (payload: ContactPayload) => {
    return await api.post(`/ppo/contactus`, payload);
};

export const site = async (payload: SitePayload) => {
    return await api.get(`/ppo/sites/${payload.id}`);
};
