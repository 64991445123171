import { useCallback, useEffect, useState } from 'react';

export default function useScrollPosition() {
    const [value, setValue] = useState<number>(0);

    const onScroll = useCallback(
        (e: any) => {
            setValue(e.target.documentElement.scrollTop);
        },
        [setValue]
    );

    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, [onScroll]);

    return value;
}
