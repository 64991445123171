import { defineMessages } from 'react-intl';

const messages = defineMessages({
    your_account: {
        id: 'account.your_account',
        defaultMessage: 'Votre compte',
    },
    edit_your_account: {
        id: 'account.edit_your_account',
        defaultMessage: 'Votre compte',
    },
    your_informations: {
        id: 'account.your_informations',
        defaultMessage: 'Vos informations',
    },
    your_password: {
        id: 'account.your_password',
        defaultMessage: 'Votre mot de passe',
    },
    your_addresses: {
        id: 'account.your_addresses',
        defaultMessage: 'Vos adresses',
    },
    empty_addresses: {
        id: 'account.empty_addresses',
        defaultMessage: "Vos n'avez actuellement aucune adresse enregistrée",
    },
    your_subscriptions: {
        id: 'account.your_subscriptions',
        defaultMessage: 'Vos abonnements',
    },
    subscriptions: {
        id: 'account.subscriptions',
        defaultMessage: 'Abonnements souscris',
    },
    logout: {
        id: 'account.logout',
        defaultMessage: 'Se déconnecter',
    },
    cta_update_infos: {
        id: 'account.cta_update_infos',
        defaultMessage: 'Mettre à jour vos informations',
    },
    password_intro: {
        id: 'account.password_intro',
        defaultMessage: 'Vous pouvez modifier votre mot de passe de connexion ci-dessous.',
    },
    by_default: {
        id: 'account.by_default',
        defaultMessage: 'Par défaut',
    },
    define_by_default: {
        id: 'account.define_by_default',
        defaultMessage: 'Définir par défaut',
    },
    download_invoice: {
        id: 'account.download_invoice',
        defaultMessage: 'Télécharger la facture',
    },
    your_dematerialized_badge: {
        id: 'account.your_dematerialized_badge',
        defaultMessage: 'Votre badge dématérialisé',
    },
    renew: {
        id: 'account.renew',
        defaultMessage: 'Renouveler',
    },
    renew_and_pay: {
        id: 'account.renew_and_pay',
        defaultMessage: 'Renouveler et payer {price}',
    },
    validity: {
        id: 'account.validity',
        defaultMessage: 'Validité',
    },
    validity_dates: {
        id: 'account.validity_dates',
        defaultMessage: 'Du {from} au {to}',
    },
    new_sub_title: {
        id: 'account.new_sub_title',
        defaultMessage: 'Renouvellement de votre offre',
    },
    new_sub_intro: {
        id: 'account.new_sub_intro',
        defaultMessage: 'Relisez et validez ci-dessous les informations concernant votre nouvel abonnement',
    },
    badge_modal_title: {
        id: 'account.badge_modal_title',
        defaultMessage: 'Votre badge dématérialisé',
    },
    download_badge: {
        id: 'account.download_badge',
        defaultMessage: 'Télécharger le badge',
    },
    download_badge_alternatives: {
        id: 'account.download_badge_alternatives',
        defaultMessage: 'Vous pouvez aussi l’ajouter à votre téléphone',
    },
    card_number_label: {
        id: 'account.card_number_label',
        defaultMessage: 'N° carte :',
    },
    licenses_plates_label: {
        id: 'account.licenses_plates',
        defaultMessage: 'Véhicule :',
    },
    amount_label: {
        id: 'account.amount_label',
        defaultMessage: 'Montant',
    },
});

export default messages;
