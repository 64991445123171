import jwt_decode from 'jwt-decode';
import { Address, BadgeType, OfferPrice, Site, User } from '../store/api/apiTypes';

export { default as constants } from '../config/constants';

interface LoggerType {
    isAllowed: boolean;
    log: (messages?: any, ...optionalParams: any[]) => void;
    warn: (messages?: any, ...optionalParams: any[]) => void;
}

class Logger implements LoggerType {
    public isAllowed: boolean;

    constructor() {
        this.isAllowed = process.env.NODE_ENV !== 'production';
    }

    public log(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: dodgerblue; font-weight: bold', messages, ...optionalParams);
        }
    }

    public info(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: cornflowerblue; font-weight: bold', messages, ...optionalParams);
        }
    }

    public warn(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: darkorange; font-weight: bold', messages, ...optionalParams);
        }
    }

    public error(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: tomato; font-weight: bold', messages, ...optionalParams);
        }
    }
}

export const debug = new Logger();

export const getFullName = (firstname?: string, lastname?: string) => {
    if (!firstname && !lastname) {
        return '';
    }

    return `${firstname ?? ''}${lastname ? ` ${lastname}` : ''}`.trim();
};

export const capitalize = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    const lowerCased = str.toLowerCase();

    return `${lowerCased.charAt(0).toUpperCase()}${lowerCased.slice(1)}`;
};

export const capitalizeWords = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    return str.split(' ').map(capitalize).join(' ');
};

export const addYear = (date: Date) => {
    date.setFullYear(date.getFullYear() + 1);

    return date;
};

export function classNames(...args: Array<string | undefined | boolean>) {
    return [...args].filter(Boolean).join(' ');
}

export const stripUndefinedKeysFromObject = (object: { [key: string]: any }) =>
    Object.keys(object).forEach((key) => {
        if (object[key] === undefined) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete object[key];
        }
    });

export const checkIfTokenExpired = (token: string) => {
    try {
        const decoded: { exp: number } = jwt_decode(token);
        return decoded.exp * 1000 - Date.now() < 0;
    } catch (error) {
        return true;
    }
};

export const hasOwnProp = (obj: any, value: string | number | [], strict = true) => {
    let result = false;

    if (typeof obj === 'object') {
        if (Array.isArray(value)) {
            result = strict
                ? !value.some((property) => !Object.prototype.hasOwnProperty.call(obj, property))
                : value.some((property) => Object.prototype.hasOwnProperty.call(obj, property));
        } else {
            result = Object.prototype.hasOwnProperty.call(obj, value);
        }
    }

    return result;
};

export const isUserDefaultAddress = (user?: User, address?: Address) => {
    const defaultAddress = user?.defaultAddress;
    return isSameAddress(defaultAddress, address);
};

export const isSameAddress = (address1?: Address, address2?: Address) => {
    if (!address1 || !address2) return false;
    else {
        if (
            address1.lastName !== address2.lastName ||
            address1.firstName !== address2.firstName ||
            address1.street !== address2.street ||
            address1.zipCode !== address2.zipCode ||
            address1.city !== address2.city
        ) {
            return false;
        }
    }
    return true;
};

export const isSelectedPrice = (price: OfferPrice, selectedPrice?: OfferPrice) => {
    if (
        !selectedPrice ||
        price.beginValidityDate !== selectedPrice.beginValidityDate ||
        price.endValidityDate !== selectedPrice.endValidityDate ||
        price.periodType !== selectedPrice.periodType ||
        price.price.totalDueAmount !== selectedPrice.price.totalDueAmount
    ) {
        return false;
    }

    return true;
};

export const canOrderBadgeType = (site?: Site, type?: BadgeType) => {
    if (site?.badgeType) {
        switch (type) {
            case BadgeType.dematerialized:
                return [BadgeType.dematerializedAndPhysical, BadgeType.dematerialized].includes(site.badgeType);
            case BadgeType.physical:
                return [BadgeType.dematerializedAndPhysical, BadgeType.physical].includes(site.badgeType);
            case BadgeType.dematerializedAndPhysical:
                return [BadgeType.dematerializedAndPhysical].includes(site.badgeType);

            default:
                return true;
        }
    } else {
        return true;
    }
};

export const downloadBase64Image = (contentBase64: string, fileName: string, type = 'application/pdf') => {
    const linkSource = `data:${type};base64,${contentBase64}`;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = fileName;
    downloadLink.click();
};
