import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormProps } from 'antd/lib/form/Form';
import { Form, Button, Alert, Typography } from 'antd';
import { getAuthState, forgottenPassword } from '../../store/actions/auth';
import { LoginPayload } from '../../store/api/auth';
import Seo from '../../components/Seo';
import LoginLayout from '../../components/LoginLayout';
import { getRoute, RoutePathName } from '../../routes';
import { useActions } from '../../hooks';
import Input from '../../components/form/Input';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import formMessages from '../../locale/formMessages';

const ForgottenPassword: FC = () => {
    const { formatMessage } = useIntl();
    const [sendForgottenPassword, resetForgottenPassword] = useActions([
        forgottenPassword.trigger,
        forgottenPassword.reset,
    ]);
    const authState = useSelector(getAuthState);
    const [email, setEmail] = useState<string | undefined>();
    const onFormValidSubmit: FormProps['onFinish'] = (values: LoginPayload) => {
        setEmail(values.username);
        sendForgottenPassword(values);
    };
    const requiredRule = { required: true, message: formatMessage(messages.rule) };

    let error = authState.forgottenPasswordError ? formatMessage(messages.error_default) : null;

    if (authState.forgottenPasswordError?.status && authState.forgottenPasswordError.status === 404) {
        error = formatMessage(messages.email_not_found);
    }

    useEffect(
        () => () => {
            setEmail(undefined);
            resetForgottenPassword();
        },
        [setEmail, resetForgottenPassword]
    );

    return (
        <LoginLayout>
            <Seo title="Mot de passe oublié" />
            <Form className="login-form" onFinish={onFormValidSubmit} layout="vertical" requiredMark={false}>
                <h1 className="page-title">
                    <FormattedMessage {...messages.title} />
                </h1>

                {authState.forgottenPasswordSuccess ? (
                    <Typography.Paragraph>
                        <p>
                            <FormattedMessage {...messages.success_message_1} values={{ email }} />
                            <br />
                            <br />
                            <FormattedMessage {...messages.success_message_2} />
                        </p>
                    </Typography.Paragraph>
                ) : (
                    <>
                        <Typography.Paragraph>
                            <p className="page-intro">
                                <FormattedMessage {...messages.intro_message_1} />
                                <br />
                                <FormattedMessage {...messages.intro_message_2} />
                            </p>
                        </Typography.Paragraph>
                        <Form.Item
                            name="username"
                            rules={[
                                requiredRule,
                                {
                                    type: 'email',
                                    message: formatMessage(formMessages.email_error),
                                },
                            ]}
                            validateTrigger="onBlur"
                        >
                            <Input placeholder={formatMessage(formMessages.email_label)} />
                        </Form.Item>
                        <Form.Item>
                            {error ? (
                                <div className="login-error-message">
                                    <Alert type="error" message={error} showIcon />
                                </div>
                            ) : null}

                            <Button
                                type="primary"
                                shape="round"
                                size="large"
                                htmlType="submit"
                                loading={authState.loading}
                                block
                            >
                                <FormattedMessage {...messages.reset_password_cta} />
                            </Button>
                        </Form.Item>
                    </>
                )}
                <Link to={getRoute(RoutePathName.login)}>
                    <FormattedMessage {...messages.back_login} />
                </Link>
            </Form>
        </LoginLayout>
    );
};

export default ForgottenPassword;
