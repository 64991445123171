import React, { FC, useEffect, useState, useContext } from 'react';
import { Col, Form, FormProps, Radio, Row, Spin, Switch } from 'antd';
import { RouteProps } from 'react-router-dom';
import { DrawerProps } from 'antd/lib/drawer';
import Button, { ButtonProps } from 'antd/lib/button';
import Modal from '../../../components/Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import formMessages from '../../../locale/formMessages';
import Input from '../../../components/form/Input';
import { Address, Civility, UserType } from '../../../store/api/apiTypes';
import { LocaleContext } from '../../../context/LocaleContext';
import CountrySelect from '../../../components/CountrySelect';

interface ModalAddressProps extends RouteProps {
    address?: Address;
    isVisible: boolean;
    deletable?: boolean;
    onClose: () => void;
    onSuccess?: (address: Address) => void;
    onDelete?: () => void;
}

const ModalAddress: FC<ModalAddressProps> = ({ address, isVisible, deletable, onClose, onSuccess, onDelete }) => {
    const { locale } = useContext(LocaleContext);
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState<any>();

    useEffect(() => {
        if (isVisible) {
            form.resetFields();

            if (address) {
                setFormValues(address);
                form.setFieldsValue(address);
            }
        }
    }, [form, isVisible, address]);

    const onFormFinish = () => {
        form.validateFields().then((values) => {
            if (onSuccess) {
                onSuccess(values);
            }
        });
    };

    const onFormValueChange: FormProps['onValuesChange'] = (changes, values) => {
        setFormValues(values);
    };

    const onModalClose: DrawerProps['onClose'] & ButtonProps['onClick'] = () => {
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            className="address-modal"
            width={500}
            okText="Enregistrer"
            cancelText="Annuler"
            onOk={onFormFinish}
            onCancel={onModalClose}
            visible={isVisible}
            title={address ? formatMessage(formMessages.edit_address) : formatMessage(formMessages.add_address)}
        >
            <Spin spinning={false}>
                <Form
                    form={form}
                    onValuesChange={onFormValueChange}
                    onFinish={onFormFinish}
                    layout="horizontal"
                    hideRequiredMark
                >
                    <>
                        <Form.Item
                            label={formatMessage(formMessages.user_type_label)}
                            className="radio-inline-label"
                            name="userType"
                            rules={[{ required: true, message: formatMessage(formMessages.email_error) }]}
                        >
                            <Radio.Group buttonStyle="solid">
                                <Radio.Button value={UserType.individual}>
                                    <FormattedMessage {...formMessages.user_type_individual} />
                                </Radio.Button>
                                <Radio.Button value={UserType.company}>
                                    <FormattedMessage {...formMessages.user_type_company} />
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        {formValues && formValues.userType === UserType.company && (
                            <Form.Item
                                name="companyName"
                                rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                            >
                                <Input placeholder={formatMessage(formMessages.company_name_label)} />
                            </Form.Item>
                        )}

                        <Form.Item
                            label={formatMessage(formMessages.civility_label)}
                            className="radio-inline-label"
                            name="civility"
                            rules={[{ required: false, message: formatMessage(formMessages.email_error) }]}
                        >
                            <Radio.Group buttonStyle="solid">
                                <Radio.Button value={Civility.missus}>
                                    <FormattedMessage {...formMessages.civility_mme} />
                                </Radio.Button>
                                <Radio.Button value={Civility.mister}>
                                    <FormattedMessage {...formMessages.civility_mr} />
                                </Radio.Button>
                                {locale === 'en' && (
                                    <Radio.Button value={Civility.ms}>
                                        <FormattedMessage {...formMessages.civility_ms} />
                                    </Radio.Button>
                                )}
                                <Radio.Button value={Civility.miss}>
                                    <FormattedMessage {...formMessages.civility_mlle} />
                                </Radio.Button>
                                <Radio.Button value={Civility.other}>
                                    <FormattedMessage {...formMessages.civility_autre} />
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        <Row gutter={15}>
                            <Col span={12}>
                                <Form.Item
                                    name="firstName"
                                    rules={[{ required: false, message: formatMessage(formMessages.error_required) }]}
                                >
                                    <Input placeholder={formatMessage(formMessages.firstname_label)} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="lastName"
                                    rules={[{ required: false, message: formatMessage(formMessages.error_required) }]}
                                >
                                    <Input placeholder={formatMessage(formMessages.lastname_label)} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item
                            name={['street']}
                            rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                        >
                            <Input placeholder={formatMessage(formMessages.address_label)} />
                        </Form.Item>

                        <Form.Item name={['complement']} rules={[{ required: false }]}>
                            <Input placeholder={formatMessage(formMessages.address_2_label)} />
                        </Form.Item>

                        <Row gutter={15}>
                            <Col span={12}>
                                <Form.Item
                                    name={['zipCode']}
                                    rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                                >
                                    <Input placeholder={formatMessage(formMessages.zipcode_label)} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={['city']}
                                    rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                                >
                                    <Input placeholder={formatMessage(formMessages.city_label)} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item
                            name={['country']}
                            initialValue={'France'}
                            rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                        >
                            <CountrySelect placeholder={formatMessage(formMessages.country_label)} />
                        </Form.Item>
                        {deletable ? (
                            <div className="switch-row mt-2 text-left">
                                <Form.Item
                                    name="default"
                                    rules={[{ required: false }]}
                                    valuePropName="checked"
                                    className="mt-0 mb-0"
                                >
                                    <Switch className="mb-0" />
                                </Form.Item>
                                <div className="label">
                                    <FormattedMessage {...formMessages.define_as_default_address} />
                                </div>
                            </div>
                        ) : (
                            <div className="label">
                                <FormattedMessage {...formMessages.default_address} />
                            </div>
                        )}

                        {!address ? (
                            <Form.Item className="mt-2">
                                <Button type="primary" shape="round" size="large" block htmlType="submit">
                                    <FormattedMessage {...formMessages.cta_save_address} />
                                </Button>
                            </Form.Item>
                        ) : (
                            <>
                                <Form.Item className="mt-2">
                                    <Button type="primary" shape="round" size="large" block htmlType="submit">
                                        <FormattedMessage {...formMessages.cta_update} />
                                    </Button>
                                </Form.Item>
                                {deletable && (
                                    <Form.Item className="mt-1">
                                        <Button
                                            type="primary"
                                            shape="round"
                                            size="large"
                                            block
                                            htmlType="button"
                                            className="reverse"
                                            onClick={onDelete}
                                        >
                                            <FormattedMessage {...formMessages.cta_delete_address} />
                                        </Button>
                                    </Form.Item>
                                )}
                            </>
                        )}
                    </>
                </Form>
            </Spin>
        </Modal>
    );
};

ModalAddress.defaultProps = {
    deletable: true,
};

export default ModalAddress;
