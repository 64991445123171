import { Divider, SelectProps, Select as AntdSelect } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';

import { LocaleContext } from '../context/LocaleContext';
import { classNames } from '../helpers';
import { fullCountryLabel } from '../helpers/country';
import Select from './form/Select';

const CountrySelect: FC<SelectProps<string>> = (props) => {
    const { locale } = useContext(LocaleContext);
    const [localization, setLocalization] = useState<{ [key: string]: string } | undefined>();

    useEffect(() => {
        (async () => {
            const localData = await fullCountryLabel(locale);
            setLocalization(localData);
        })();
    }, [locale]);

    return (
        <Select
            {...props}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
                return option?.text?.toString().toLowerCase().startsWith(input.toLowerCase()) ?? false;
            }}
            loading={!localization}
            dropdownClassName="lang-select-dropdown"
        >
            {localization && (
                <>
                    <AntdSelect.Option value="FR" key="fr" text={localization.fr}>
                        <span className={classNames('flag', 'fr')} /> {localization.fr}
                    </AntdSelect.Option>
                    <AntdSelect.Option value="ES" key="es" text={localization.es}>
                        <span className={classNames('flag', 'es')} /> {localization.es}
                    </AntdSelect.Option>
                    <AntdSelect.Option value="GB" key="gb" text={localization.gb}>
                        <span className={classNames('flag', 'gb')} /> {localization.gb}
                    </AntdSelect.Option>
                    <AntdSelect.Option value="" style={{ minHeight: 0, padding: 0 }} disabled>
                        <Divider style={{ margin: '0.25rem 0' }} />
                    </AntdSelect.Option>
                    {Object.keys(localization)
                        .filter((key) => !['fr', 'es', 'gb'].includes(key))
                        .map((lang) => (
                            <AntdSelect.Option value={lang.toUpperCase()} key={lang} text={localization[lang]}>
                                <span className={classNames('flag', lang)} /> {localization[lang]}
                            </AntdSelect.Option>
                        ))}
                </>
            )}
        </Select>
    );
};

export default CountrySelect;
