import * as React from 'react';
import { Address } from '../store/api/apiTypes';

interface AddressRowProps {
    address?: Address;
}

const AddressRow: React.FC<AddressRowProps> = ({ address }) => {
    return address !== undefined ? (
        <div className="address">
            {address.companyName && (
                <>
                    <strong>{address.companyName}</strong>
                    <br />
                </>
            )}
            {address.lastName && (
                <>
                    <strong>{address.firstName + ' ' + address.lastName}</strong>
                    <br />
                </>
            )}
            {address.street}
            {address.complement ? ' ' + address.complement : ''}
            {', ' + address.zipCode}
            {' ' + address.city}
            {', ' + address.country}
        </div>
    ) : null;
};

export default AddressRow;
