import React, { FC, useContext } from 'react';
import { SiteContext } from '../../context/SiteContext';

const hexToRgba = (hex: string, opacity = 1) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? 'rgba(' +
              parseInt(result[1], 16).toString() +
              ',' +
              parseInt(result[2], 16).toString() +
              ',' +
              parseInt(result[3], 16).toString() +
              ',' +
              opacity.toString() +
              ')'
        : '';
};

const Theme: FC = () => {
    const { site } = useContext(SiteContext);

    const themeColor = site?.themeColor ? site?.themeColor : '#ffca00';

    return site ? (
        <style
            dangerouslySetInnerHTML={{
                __html:
                    `
                    ::selection {
                        background: ` +
                    themeColor +
                    `;
                    }

                    .custom-picker .btn-open > .anticon,
                    .text-primary,
                    .title-2,
                    .ant-spin,
                    a,
                    a:hover,
                    .quantity-input button,
                    .ant-btn-link,
                    .ant-btn-link:hover,
                    .ant-radio-button-wrapper:hover,
                    body .ant-dropdown-menu-item > a:hover, body .ant-dropdown-menu-submenu-title > a:hover,
                    body .ant-modal-close,
                    .card.subscription-card .circle-icon
                    {
                        color:  ` +
                    themeColor +
                    `;
                    }

                    .ant-btn-primary{
                        background: ` +
                    themeColor +
                    `;
                        border-color: ` +
                    themeColor +
                    `;
                    }

                    body .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
                    .ant-btn-primary:hover{
                        background: ` +
                    hexToRgba(themeColor, 0.85) +
                    `;
                        border-color: ` +
                    hexToRgba(themeColor, 0.85) +
                    `;
                    }

                    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
                    .ant-btn-primary:focus{
                        background: ` +
                    themeColor +
                    `;
                        border-color: ` +
                    themeColor +
                    `;
                    }

                    .custom-picker .custom-picker-overlay .results-list li:hover{
                        background: ` +
                    hexToRgba(themeColor, 0.05) +
                    `;
                        color: ` +
                    themeColor +
                    `;
                    }

                    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
                    body .ant-btn-ghost.btn-circle-icon,
                    .ant-btn-ghost:hover, .ant-btn-ghost:focus{
                        border-color: ` +
                    themeColor +
                    `;
                        color: ` +
                    themeColor +
                    `;
                    }

                    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before,
                    body .ant-btn-primary.reverse,
                    .addresses-list .check,
                    .offer-card:hover, .offer-card.selected{
                        border-color: ` +
                    themeColor +
                    `;
                    }

                    #main-footer .menu li:not(:last-child):after,
                    body .ant-btn-primary.reverse:hover,
                    .offer-card .icon-checked,
                    body .ant-btn-ghost.btn-circle-icon:hover,
                    .ant-switch-checked,
                    .addresses-list .address-row.checked .check:after,
                    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{  
                        background: ` +
                    themeColor +
                    `;
                    }

                    .swiper-pagination-bullet-active{
                        background: ` +
                    themeColor +
                    ` !important;                        
                    }

                    .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
                        border-color: ` +
                    themeColor +
                    `;
                        box-shadow: 0 0 0 2px ` +
                    hexToRgba(themeColor, 0.2) +
                    `;
                    }
                `,
            }}
        />
    ) : (
        <></>
    );
};

export default Theme;
