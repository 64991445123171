import { Layout } from 'antd';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import '../assets/styles/Footer.less';
import genericMessages from '../locale/genericMessages';
import { getRoute, RoutePathName } from '../routes';

const Footer: FC = () => {
    return (
        <Layout.Footer id="main-footer">
            <ul className="menu">
                <li>
                    <Link to={getRoute(RoutePathName.legals, { slug: 'privacyPolicy' })}>
                        <FormattedMessage {...genericMessages.footer_privacy} />
                    </Link>
                </li>
                <li>
                    <Link to={getRoute(RoutePathName.legals, { slug: 'cgv' })}>
                        <FormattedMessage {...genericMessages.footer_cgv} />
                    </Link>
                </li>
                <li>
                    <Link to={getRoute(RoutePathName.legals, { slug: 'cgu' })}>
                        <FormattedMessage {...genericMessages.footer_cgu} />
                    </Link>
                </li>
                <li>
                    <Link to={getRoute(RoutePathName.legals, { slug: 'legalNotice' })}>
                        <FormattedMessage {...genericMessages.footer_legals} />
                    </Link>
                </li>
            </ul>

            <div className="copyright">
                Orbility © <FormattedMessage {...genericMessages.footer_copyright} />
            </div>
        </Layout.Footer>
    );
};

export default Footer;
