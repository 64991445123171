import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

// Types
import { Offer, ListResponse, OfferPrice, Transaction, RenewOfferPrice } from '../api/apiTypes';

// Controlers
import {
    OfferIdPayload,
    OfferListPayload,
    OfferCreatePayload,
    OfferUpdatePayload,
    list as listApiCall,
    prices as pricesApiCall,
    create as createApiCall,
    update as updateApiCall,
    remove as removeApiCall,
    details as detailsApiCall,
    renewPrice as renewPriceApiCall,
    renewTitle as renewTitleApiCall,
    authNmiCompleted as authNmiCompletedApiCall,
    authNmiReNewCompleted as authNmiReNewCompletedApiCall,
    authNmiFailure as authNmiFailureApiCall,
    authNmiError as authNmiErrorApiCall,
    OfferPricesPayload,
    OfferRenewPricePayload,
    OfferRenewTitlePayload,
    OfferAuthNmiCompletedPayload,
    OfferAuthNmiRenewCompletedPayload,
    OfferAuthNmiFailurePayload,
    OfferAuthNmiErrorPayload,
} from '../api/offers';
import { DataAction } from '../helpers/EzeeAction';

// States
export interface OffersState {
    create: RequestState<Transaction>;
    authNmiCompleted: RequestState<Transaction>;
    authNmiReNewCompleted: RequestState<Transaction>;
    authNmiFailure: RequestState<Transaction>;
    authNmiError: RequestState<Transaction>;
    update: RequestState<Offer>;
    remove: RequestState<Offer>;
    details: RequestState<Offer[]>;
    list: RequestState<ListResponse<Offer>>;
    prices: RequestState<ListResponse<OfferPrice>>;
    renewPrice: RequestState<RenewOfferPrice>;
    renewTitle: RequestState<Transaction>;
}

const initialState: OffersState = {
    create: {
        data: {},
        loading: false,
    },
    authNmiCompleted: {
        data: {},
        loading: false,
    },
    authNmiReNewCompleted: {
        data: {},
        loading: false,
    },
    authNmiFailure: {
        data: {},
        loading: false,
    },
    authNmiError: {
        data: {},
        loading: false,
    },
    update: {
        data: {
            offerId: '',
            name: '',
        },
        loading: false,
    },
    remove: {
        loading: false,
    },
    details: {
        data: [],
        loading: false,
    },
    list: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
    prices: {
        data: {
            items: [],
            totalCount: 0,
            page: 0,
            pageSize: 20,
            pageCount: 0,
        },
        loading: false,
    },
    renewPrice: {
        data: {},
        loading: false,
    },
    renewTitle: {
        data: {},
        loading: false,
    },
};

export const create = new EzeeAsyncAction<OffersState['create'], OfferCreatePayload, Offer>(
    'offers/create',
    initialState.create,
    requestReducer<OffersState['create'], Offer>(initialState.create)
);

export const update = new EzeeAsyncAction<OffersState['update'], OfferUpdatePayload, Offer>(
    'offers/update',
    initialState.update,
    requestReducer<OffersState['update'], Offer>(initialState.update)
);

export const remove = new EzeeAsyncAction<OffersState['remove'], OfferIdPayload, Offer>(
    'offers/remove',
    initialState.remove,
    requestReducer<OffersState['remove'], Offer>(initialState.remove)
);

export const details = new EzeeAsyncAction<OffersState['details'], OfferIdPayload, Offer>(
    'offers/details',
    initialState.details,
    requestReducer<OffersState['details'], Offer>(initialState.details)
);

export const list = new EzeeAsyncAction<OffersState['list'], OfferListPayload, ListResponse<Offer>>(
    'offers/list',
    initialState.list,
    requestReducer<OffersState['list'], ListResponse<Offer>>(initialState.list)
);

export const prices = new EzeeAsyncAction<OffersState['prices'], OfferPricesPayload, ListResponse<OfferPrice>>(
    'offers/prices',
    initialState.prices,
    requestReducer<OffersState['prices'], ListResponse<OfferPrice>>(initialState.prices)
);

export const renewPrice = new EzeeAsyncAction<OffersState['renewPrice'], OfferRenewPricePayload, RenewOfferPrice>(
    'offers/renewPrice',
    initialState.renewPrice,
    requestReducer<OffersState['renewPrice'], RenewOfferPrice>(initialState.renewPrice)
);

export const renewTitle = new EzeeAsyncAction<OffersState['renewTitle'], OfferRenewTitlePayload, RenewOfferPrice>(
    'offers/renewTitle',
    initialState.renewTitle,
    requestReducer<OffersState['renewTitle'], RenewOfferPrice>(initialState.renewTitle)
);

export const authNmiCompleted = new EzeeAsyncAction<
    OffersState['authNmiCompleted'],
    OfferAuthNmiCompletedPayload,
    Offer
>(
    'offers/authNmiCompleted',
    initialState.authNmiCompleted,
    requestReducer<OffersState['authNmiCompleted'], Offer>(initialState.authNmiCompleted)
);

export const authNmiReNewCompleted = new EzeeAsyncAction<
    OffersState['authNmiReNewCompleted'],
    OfferAuthNmiRenewCompletedPayload,
    Offer
>(
    'offers/authNmiReNewCompleted',
    initialState.authNmiReNewCompleted,
    requestReducer<OffersState['authNmiReNewCompleted'], Offer>(initialState.authNmiReNewCompleted)
);

export const authNmiFailure = new EzeeAsyncAction<OffersState['authNmiFailure'], OfferAuthNmiFailurePayload, Offer>(
    'offers/authNmiFailure',
    initialState.authNmiFailure,
    requestReducer<OffersState['authNmiFailure'], Offer>(initialState.authNmiFailure)
);

export const authNmiError = new EzeeAsyncAction<OffersState['authNmiError'], OfferAuthNmiErrorPayload, Offer>(
    'offers/authNmiError',
    initialState.authNmiError,
    requestReducer<OffersState['authNmiError'], Offer>(initialState.authNmiError)
);

// Reducer
export const offersReducer = combineReducers<OffersState>({
    list: list.reducer,
    prices: prices.reducer,
    update: update.reducer,
    create: create.reducer,
    remove: remove.reducer,
    details: details.reducer,
    renewPrice: renewPrice.reducer,
    renewTitle: renewTitle.reducer,
    authNmiReNewCompleted: authNmiReNewCompleted.reducer,
    authNmiCompleted: authNmiCompleted.reducer,
    authNmiFailure: authNmiFailure.reducer,
    authNmiError: authNmiError.reducer,
});

// Saga
export function* offersSaga() {
    yield takeLatest(list.type.trigger, listSaga);
    yield takeLatest(prices.type.trigger, pricesSaga);
    yield takeLatest(update.type.trigger, simpleAsyncSaga(updateApiCall, update));
    yield takeLatest(create.type.trigger, simpleAsyncSaga(createApiCall, create));
    yield takeLatest(remove.type.trigger, simpleAsyncSaga(removeApiCall, remove));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(renewPrice.type.trigger, simpleAsyncSaga(renewPriceApiCall, renewPrice));
    yield takeLatest(renewTitle.type.trigger, simpleAsyncSaga(renewTitleApiCall, renewTitle));
    yield takeLatest(authNmiCompleted.type.trigger, simpleAsyncSaga(authNmiCompletedApiCall, authNmiCompleted));
    yield takeLatest(
        authNmiReNewCompleted.type.trigger,
        simpleAsyncSaga(authNmiReNewCompletedApiCall, authNmiReNewCompleted)
    );
    yield takeLatest(authNmiFailure.type.trigger, simpleAsyncSaga(authNmiFailureApiCall, authNmiFailure));
    yield takeLatest(authNmiError.type.trigger, simpleAsyncSaga(authNmiErrorApiCall, authNmiError));
}

function* listSaga(action: DataAction<OfferListPayload>) {
    try {
        const response = yield call(listApiCall, action.payload);
        return yield put(
            list.success({
                items: response,
            })
        );
    } catch (error) {
        return yield put(list.failure(error));
    }
}

function* pricesSaga(action: DataAction<OfferPricesPayload>) {
    try {
        const response = yield call(pricesApiCall, action.payload);
        return yield put(
            prices.success({
                items: response.offerPrices,
            })
        );
    } catch (error) {
        return yield put(prices.failure(error));
    }
}

// Store helpers
export const getOffersState = (state: MainReducerState) => state.offers;
export const getOffersListState = (state: MainReducerState) => state.offers.list;
export const getOffersCreateState = (state: MainReducerState) => state.offers.create;
export const getOffersUpdateState = (state: MainReducerState) => state.offers.update;
export const getOffersRemoveState = (state: MainReducerState) => state.offers.remove;
export const getOffersDetailsState = (state: MainReducerState) => state.offers.details;
export const getOffersPricesState = (state: MainReducerState) => state.offers.prices;
export const getOffersRenewPriceState = (state: MainReducerState) => state.offers.renewPrice;
export const getOffersRenewTitleState = (state: MainReducerState) => state.offers.renewTitle;
export const getOffersAuthNmiCompletedState = (state: MainReducerState) => state.offers.authNmiCompleted;
export const getOffersAuthNmiFailureState = (state: MainReducerState) => state.offers.authNmiFailure;
export const getOffersAuthNmiErrorState = (state: MainReducerState) => state.offers.authNmiError;
export const getOffersAuthNmiReNewCompletedState = (state: MainReducerState) => state.offers.authNmiReNewCompleted;
