import api from './_client';
import { SearchPaginationQuery } from '.';

// Types
import { Offer, OfferPrice, Parking, Site, Title, VehicleType } from './apiTypes';

// Controller Interface
export interface OfferCreatePayload {
    siteId: Site['id'];
    body: Offer;
}

export interface OfferUpdatePayload {
    id: Offer['offerId'];
    body: Offer;
}

export interface OfferIdPayload {
    siteId: Site['id'];
    id: Offer['offerId'];
}

export type OfferListPayload = SearchPaginationQuery & {
    parkingIds?: Array<Parking['id']>;
    vehicleClasses?: VehicleType[];
    subscriptionDate?: Date;
};

export type OfferPricesPayload = SearchPaginationQuery & {
    offerPriceCalculation?: {
        offerId?: Offer['offerId'];
        beginValidityDate?: Date;
    };
};

export interface OfferRenewPricePayload {
    siteId: string;
    titleId: string;
}

export interface OfferRenewTitlePayload {
    siteId: string;
    cancelURL: string;
    returnURL: string;
    titleId: Title['titleId'];
    offerPrice: OfferPrice;
}

export interface OfferAuthNmiCompletedPayload {
    siteId: Site['id'];
    body: Offer;
}

export interface OfferAuthNmiRenewCompletedPayload {
    siteId: Site['id'];
    body: Offer;
}

export interface OfferAuthNmiFailurePayload {
    siteId: Site['id'];
    body: Offer;
}

export interface OfferAuthNmiErrorPayload {
    siteId: Site['id'];
    body: Offer;
}

// Routes
export const list = async (payload: OfferListPayload = {}) => {
    return await api.post(`/ppo/getoffers/${payload?.siteId ?? ''}`, payload);
};

export const create = async (payload: OfferCreatePayload) => {
    return await api.post(`/ppo/transactions/${payload?.siteId ?? ''}`, payload.body);
};

export const update = async (payload: OfferUpdatePayload) => {
    return await api.put(`/ppo/offers/${payload.id}`, payload.body);
};

export const prices = async (payload: OfferPricesPayload = {}) => {
    return await api.post(`/ppo/getofferprice/${payload?.siteId ?? ''}`, payload);
};

export const details = async (payload: OfferIdPayload) => {
    return await api.post(`/ppo/getofferbyid/${payload?.siteId ?? ''}`, payload);
};

export const remove = async (payload: OfferIdPayload) => {
    return await api.delete(`/ppo/offers/${payload.id}`);
};

export const renewPrice = async (payload: OfferRenewPricePayload) => {
    return await api.get(`/ppo/getTitleRenewalPrice/${payload.siteId}/${payload.titleId}`);
};

export const renewTitle = async (payload: OfferRenewTitlePayload) => {
    return await api.patch(`/ppo/renewTitle/${payload.siteId}`, payload);
};

export const authNmiCompleted = async (payload: OfferAuthNmiCompletedPayload) => {
    return await api.post(`/ppo/transactions/nmicompleted/${payload?.siteId ?? ''}`, payload.body);
};

export const authNmiReNewCompleted = async (payload: OfferAuthNmiRenewCompletedPayload) => {
    return await api.post(`/ppo/transactions/nmicompleted/renew/${payload?.siteId ?? ''}`, payload.body);
};

export const authNmiFailure = async (payload: OfferAuthNmiFailurePayload) => {
    return await api.post(`/ppo/transactions/nmifailure/${payload?.siteId ?? ''}`, payload.body);
};

export const authNmiError = async (payload: OfferAuthNmiErrorPayload) => {
    return await api.post(`/ppo/transactions/nmierror/${payload?.siteId ?? ''}`, payload.body);
};
