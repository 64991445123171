import React, { FC } from 'react';
import { Input as AntInput, InputProps } from 'antd';

import '../../assets/styles/form/Input.less';

const Input: FC<InputProps> = ({ ...props }) => {
    const placeholder: string | undefined = props.placeholder;
    delete props.placeholder;

    const onInputChange: InputProps['onChange'] = (e) => {
        if (props.onChange) {
            props.onChange(e);
        }
    };

    return (
        <div className={'input-float-label ' + (!props.value ? 'empty' : 'not-empty')}>
            <label className="float-label">{placeholder}</label>
            <AntInput {...props} onChange={onInputChange} />
        </div>
    );
};

export default Input;
