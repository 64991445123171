import apiClient from '../../store/api/_client';
import React, { FC, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import ErrorBoundary from '../../components/ErrorBoundary';
import MainLayout from '../../components/MainLayout';
import ProtectedRoute from '../../components/ProtectedRoute';
import { SiteContext } from '../../context/SiteContext';
import { useActions } from '../../hooks';
import { getRawRoute, RoutePathName } from '../../routes';
import { getSiteState, site as getSiteAction } from '../../store/actions/sites';
import { Site } from '../../store/api/apiTypes';
import Account from '../account/Account';
import RenewSubscription from '../account/subscriptions/renew/RenewSubscription';
import Contact from '../contact';
import ErrorPage from '../error';
import ForgottenPassword from '../forgottenPassword';
import Legals from '../legals/Legals';
import Login from '../login';
import Offer from '../offer';
import Offers from '../offers';
import Parkings from '../parkings';
import Payment from '../payment/Payment';
import PaymentConfirm from '../payment/PaymentConfirm';
import Privacy from '../privacy';
import Register from '../register';
import ResetPassword from '../resetPassword';
import Verification from '../verification/Verification';
import Theme from './Theme';

interface MatchParams {
    siteId: Site['id'];
}

const SiteTheme: FC<RouteComponentProps<MatchParams>> = ({ match }) => {
    const { setSiteId, setSite } = useContext(SiteContext);
    const siteId = match.params.siteId;
    const siteUri = '/' + siteId;

    const [getSite] = useActions([getSiteAction.trigger]);

    const site = useSelector(getSiteState);

    useEffect(() => {
        setSiteId(siteId);
        apiClient.defaults.headers['x-orbi-site-id'] = siteId;
        if (siteId) {
            getSite({
                id: siteId,
            });
        }
    }, [setSiteId, siteId, getSite]);

    useEffect(() => {
        if (site) {
            setSite(site);
        }
    }, [site, setSite]);

    return (
        <div id="site">
            <Theme />
            <Switch>
                <Route exact path={siteUri + getRawRoute(RoutePathName.login)} component={Login} />
                <Route exact path={getRawRoute(RoutePathName.register)} component={Register} />
                <Route exact path={siteUri + getRawRoute(RoutePathName.verification)} component={Verification} />
                <Route
                    exact
                    path={siteUri + getRawRoute(RoutePathName.forgottenPassword)}
                    component={ForgottenPassword}
                />
                <Route exact path={siteUri + getRawRoute(RoutePathName.resetPassword)} component={ResetPassword} />

                <Route path="*">
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route exact path={siteUri + getRawRoute(RoutePathName.contact)} component={Contact} />

                                <Route exact path={siteUri + getRawRoute(RoutePathName.offers)} component={Offers} />
                                <Route exact path={siteUri + getRawRoute(RoutePathName.offer)} component={Offer} />
                                <Route exact path={siteUri + getRawRoute(RoutePathName.payment)} component={Payment} />
                                <Route
                                    exact
                                    path={siteUri + getRawRoute(RoutePathName.paymentConfirm)}
                                    component={PaymentConfirm}
                                />

                                <Route path={siteUri + getRawRoute(RoutePathName.account)} component={Account} />
                                <Route
                                    path={siteUri + getRawRoute(RoutePathName.renewSubscription)}
                                    component={RenewSubscription}
                                />

                                <Route exact path={siteUri + getRawRoute(RoutePathName.privacy)} component={Privacy} />
                                <Route exact path={siteUri + getRawRoute(RoutePathName.legals)} component={Legals} />
                                <Route exact path={''} component={Parkings} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <ProtectedRoute>
                    <MainLayout>
                        <ErrorBoundary>
                            <Switch>
                                <Route path="*">
                                    <ErrorPage />
                                </Route>
                            </Switch>
                        </ErrorBoundary>
                    </MainLayout>
                </ProtectedRoute>
            </Switch>
        </div>
    );
};

export default SiteTheme;
