import React, { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Dropdown, Layout, Menu } from 'antd';
import { getAuthState, logout as logoutAction } from '../store/actions/auth';
import { getRoute, RoutePathName } from '../routes';
import { useActions } from '../hooks';
import { Breakpoint, useCurrentBreakpointName } from 'react-socks';
import { IconUser } from './icons';
import IconMenu from './icons/IconMenu';
import IconContact from './icons/IconContact';
import { LocaleContext } from '../context/LocaleContext';
import { Language } from '../types';
import { FormattedMessage } from 'react-intl';
import genericMessages from '../locale/genericMessages';
import accountMessages from '../locale/accountMessages';
import { useSelector } from 'react-redux';
import IconLogout from './icons/IconLogout';
import { SiteContext } from '../context/SiteContext';

import '../assets/styles/Header.less';

import logo from '../assets/images/logo.png';
import iconFlagFr from '../assets/images/icon-flag-fr.svg';
import iconFlagEn from '../assets/images/icon-flag-en.svg';
import iconFlagEs from '../assets/images/icon-flag-es.svg';
import iconArrowDown from '../assets/images/icon-arrow-down.svg';

const Header: FC = () => {
    const logout = useActions(logoutAction.trigger);
    const { locale, setLocale } = useContext(LocaleContext);
    const { site } = useContext(SiteContext);
    const authState = useSelector(getAuthState);
    const breakpoint = useCurrentBreakpointName();

    const isLgUP = !['xs', 'sm', 'md'].includes(breakpoint);
    const isMdDown = ['xs', 'sm', 'md'].includes(breakpoint);

    const changeLanguage = (lang: Language) => {
        setLocale(lang);
    };

    const logoImage = site?.logoImage ?? logo;
    const iconFlags = {
        fr: iconFlagFr,
        en: iconFlagEn,
        es: iconFlagEs,
    };

    const menu = (
        <Menu>
            {authState.isConnected ? (
                <>
                    <Menu.Item className="dropdown-title-menu-item">
                        <div className="dropdown-title">
                            {authState.user?.firstName} {authState.user?.lastName}
                        </div>
                    </Menu.Item>
                    <Divider />
                    {isLgUP && (
                        <>
                            <Menu.Item>
                                <Link to={getRoute(RoutePathName.accountInformations)}>
                                    <FormattedMessage {...accountMessages.edit_your_account} />
                                </Link>
                            </Menu.Item>

                            <Menu.Item>
                                <Link to={getRoute(RoutePathName.accountSubscriptions)}>
                                    <FormattedMessage {...accountMessages.your_subscriptions} />
                                </Link>
                            </Menu.Item>
                        </>
                    )}
                    {isMdDown && (
                        <>
                            <Menu.Item>
                                <Link to={getRoute(RoutePathName.accountInformations)}>
                                    <FormattedMessage {...accountMessages.your_informations} />
                                </Link>
                            </Menu.Item>

                            <Menu.Item>
                                <Link to={getRoute(RoutePathName.accountPassword)}>
                                    <FormattedMessage {...accountMessages.your_password} />
                                </Link>
                            </Menu.Item>

                            <Menu.Item>
                                <Link to={getRoute(RoutePathName.accountAddresses)}>
                                    <FormattedMessage {...accountMessages.your_addresses} />
                                </Link>
                            </Menu.Item>

                            <Menu.Item>
                                <Link to={getRoute(RoutePathName.accountSubscriptions)}>
                                    <FormattedMessage {...accountMessages.your_subscriptions} />
                                </Link>
                            </Menu.Item>

                            <Divider />
                            <Menu.Item>
                                <Link to={getRoute(RoutePathName.contact)}>
                                    <FormattedMessage {...genericMessages.header_contact} />
                                </Link>
                            </Menu.Item>
                        </>
                    )}
                    <Divider />
                    <Menu.Item>
                        <Link to={getRoute(RoutePathName.home)} onClick={logout}>
                            <FormattedMessage {...genericMessages.header_logout} />
                            <IconLogout />
                        </Link>
                    </Menu.Item>
                </>
            ) : (
                <>
                    <Menu.Item>
                        <Link to={getRoute(RoutePathName.login)}>
                            <FormattedMessage {...genericMessages.header_login} />
                        </Link>
                    </Menu.Item>
                    {site?.enabled && (
                        <Menu.Item>
                            <Link to={getRoute(RoutePathName.register)}>
                                <FormattedMessage {...genericMessages.header_register} />
                            </Link>
                        </Menu.Item>
                    )}
                </>
            )}
        </Menu>
    );

    const menuLanguages = (
        <Menu>
            <Menu.Item>
                <a href="#french" onClick={changeLanguage.bind(null, Language.fr)}>
                    Français
                </a>
            </Menu.Item>
            <Menu.Item>
                <a href="#english" onClick={changeLanguage.bind(null, Language.en)}>
                    English
                </a>
            </Menu.Item>
            <Menu.Item>
                <a href="#spanish" onClick={changeLanguage.bind(null, Language.es)}>
                    Español
                </a>
            </Menu.Item>
        </Menu>
    );

    const menuCta = (
        <Dropdown overlay={menu} trigger={['click']} overlayClassName="user-dropdown" placement="bottomRight">
            <a href="#menu" className="menu-dropdown" onClick={(e) => e.preventDefault()}>
                <IconUser className="text-primary" />
                <IconMenu />
            </a>
        </Dropdown>
    );

    return (
        <div id="main-header-spacer">
            <Breakpoint md down>
                <Layout.Header id="main-header">
                    <Link to={getRoute(RoutePathName.home)}>
                        <img src={logoImage} alt="logo" className="logo" />
                    </Link>
                    {menuCta}
                </Layout.Header>
            </Breakpoint>
            <Breakpoint lg up>
                <Layout.Header id="main-header">
                    <div className="left-container">
                        <Link to={getRoute(RoutePathName.home)}>
                            <img src={logoImage} alt="logo" className="logo" />
                        </Link>
                        <div className="baseline">
                            <FormattedMessage {...genericMessages.header_baseline} />
                        </div>
                    </div>
                    <div className="right-container">
                        <Link to={getRoute(RoutePathName.contact)} className="cta-contact">
                            <IconContact className="text-primary" />
                            <span>
                                <FormattedMessage {...genericMessages.header_contact} />
                            </span>
                        </Link>

                        <Dropdown overlay={menuLanguages} trigger={['click']}>
                            <a href="#lang" className="language-dropdown" onClick={(e) => e.preventDefault()}>
                                <img className="flag" src={iconFlags[locale]} alt={locale.toUpperCase()} />
                                <span>{locale.toUpperCase()}</span>
                                <img className="arrow" src={iconArrowDown} alt="Arrow" />
                            </a>
                        </Dropdown>
                        {menuCta}
                    </div>
                </Layout.Header>
            </Breakpoint>
        </div>
    );
};

export default Header;
