import { fork } from 'redux-saga/effects';

import { authSaga } from '../actions/auth';
import { logsSaga } from '../actions/logs';
import { usersSaga } from '../actions/users';
import { parkingsSaga } from '../actions/parkings';
import { offersSaga } from '../actions/offers';
import { sitesSaga } from '../actions/sites';

export default function* mainSaga() {
    yield fork(authSaga);
    yield fork(logsSaga);
    yield fork(usersSaga);
    yield fork(parkingsSaga);
    yield fork(offersSaga);
    yield fork(sitesSaga);
}
