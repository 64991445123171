import React, { FC, useEffect } from 'react';
import Seo from '../../components/Seo';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import IconConfirm from '../../components/icons/IconConfirm';
import ButtonLink from '../../components/ButtonLink';
import { getRoute, RoutePathName } from '../../routes';
import { useLocation, useParams } from 'react-router-dom';
import { transaction as getTransactionAction } from '../../store/actions/users';
import { useSelector } from 'react-redux';
import { useActions } from '../../hooks';
import { getUser } from '../../store/actions/auth';

import '../../assets/styles/Payment.less';

const PaymentConfirm: FC = () => {
    const { transactionId } = useParams<{ transactionId: string }>();
    const location = useLocation<{ transactionId?: string }>();
    const [getTransaction] = useActions([getTransactionAction.trigger]);
    const user = useSelector(getUser);

    useEffect(() => {
        if (transactionId) {
            getTransaction({ id: transactionId });
        } else if (location?.state?.transactionId) {
            getTransaction({ id: location?.state?.transactionId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionId, getTransaction]);

    return (
        <>
            <Seo title="Confirmation de paiement" />
            <div id="payment-confirm">
                <div className="wrapper mt-3 mb-3">
                    <div className="card text-center">
                        <IconConfirm className="text-primary mt-1 mb-2" />
                        <h1 className="title title-1 larger">
                            <FormattedMessage {...messages.confirm_title} />
                        </h1>
                        <p className="mt-2 mb-3">
                            <FormattedMessage {...messages.confirm_text_2} values={{ email: user?.email }} />
                        </p>
                        <p className="mt-2 mb-2">
                            <ButtonLink
                                to={getRoute(RoutePathName.accountSubscriptions)}
                                type="primary"
                                shape="round"
                                size="large"
                            >
                                <FormattedMessage {...messages.cta_subscriptions} />
                            </ButtonLink>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentConfirm;
