import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormProps } from 'antd/lib/form/Form';
import { Form, Button, Alert, Typography, Spin } from 'antd';

import { getAuthState, resetPassword, resendInvitation as resendInvitationAction } from '../../store/actions/auth';
import { LoginPayload } from '../../store/api/auth';

import Seo from '../../components/Seo';
import PasswordInput from '../../components/form/InputPassword';
import LoginLayout from '../../components/LoginLayout';
import ButtonLink from '../../components/ButtonLink';
import { checkIfTokenExpired } from '../../helpers';
import { getRoute, RoutePathName } from '../../routes';
import useQueryParams from '../../hooks/queryParams';
import validatePasswordRules from '../../helpers/passwords';
import { useActions } from '../../hooks';
import { useIntl } from 'react-intl';
import formMessages from '../../locale/formMessages';

const ResetPassword: FC = () => {
    const { formatMessage } = useIntl();
    const [sendResetPassword, resetResetPassword, resendInvitation] = useActions([
        resetPassword.trigger,
        resetPassword.reset,
        resendInvitationAction.trigger,
    ]);
    const authState = useSelector(getAuthState);
    const [isTokenExpired, setIsTokenExpired] = useState<boolean | null>(null);
    const [queryParams] = useQueryParams('reset-password');
    const [, forceUpdate] = useState(false);
    const token = queryParams.get('token');
    const onFormValidSubmit: FormProps['onFinish'] = (values: LoginPayload) => {
        sendResetPassword({
            ...values,
            token,
        });
    };
    const onClickSendNewInvitation = () => {
        resendInvitation({ token });
    };

    let error =
        authState.resetPasswordError || authState.resendInvitationError
            ? formatMessage(formMessages.reset_password_error)
            : null;

    if (authState.resetPasswordError) {
        if (authState.resetPasswordError?.status === 404) {
            error = formatMessage(formMessages.reset_password_error_404);
        } else if (authState.resetPasswordError?.status === 400) {
            error = formatMessage(formMessages.reset_password_error_400);
        } else {
            error = formatMessage(formMessages.reset_password_error);
        }
    }

    const formContent = authState.resetPasswordSuccess ? (
        <>
            <Typography.Paragraph>{formatMessage(formMessages.reset_password_success)}</Typography.Paragraph>
            <ButtonLink to={getRoute(RoutePathName.home)} type="primary">
                {formatMessage(formMessages.reset_password_button_connect)}
            </ButtonLink>
        </>
    ) : (
        <>
            <Typography.Paragraph>{formatMessage(formMessages.reset_password_new_password)}</Typography.Paragraph>
            <Form.Item
                label={formatMessage(formMessages.reset_password_new_password_label)}
                rules={[
                    { required: true, message: formatMessage(formMessages.reset_password_new_password_rule) },
                    {
                        validator: async (_, value) => {
                            if (!value || !validatePasswordRules(value)) {
                                return await Promise.resolve();
                            }
                            return await Promise.reject(
                                new Error(formatMessage(formMessages.reset_password_new_password_error))
                            );
                        },
                    },
                ]}
                name="password"
            >
                <PasswordInput />
            </Form.Item>
            {error ? (
                <Form.Item>
                    <Alert type="error" message={error} showIcon />
                </Form.Item>
            ) : null}
            <Form.Item shouldUpdate>
                {() => (
                    <Button type="primary" htmlType="submit" loading={authState.loading} size="large" block>
                        {formatMessage(formMessages.reset_password_button)}
                    </Button>
                )}
            </Form.Item>
        </>
    );

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    useEffect(() => {
        if (token) {
            setIsTokenExpired(token === null ? true : checkIfTokenExpired(token));
        }
    }, [setIsTokenExpired, token]);

    useEffect(
        () => () => {
            resetResetPassword();
        },
        [resetResetPassword]
    );

    return (
        <LoginLayout>
            <Seo title={formatMessage(formMessages.reset_password_title)} />
            <Form className="login-form" onFinish={onFormValidSubmit} layout="vertical" requiredMark={false}>
                {isTokenExpired === null ? ( // checking token expiration
                    <Spin />
                ) : isTokenExpired ? (
                    <>
                        {authState.resendInvitationSuccess ? (
                            <Typography.Paragraph>
                                {formatMessage(formMessages.reset_password_resendInvitationSuccess)}
                            </Typography.Paragraph>
                        ) : (
                            <>
                                <div className="login-error-message" style={{ padding: '2rem 0' }}>
                                    <Alert
                                        type="error"
                                        message={error ?? formatMessage(formMessages.reset_password_expiration)}
                                    />
                                </div>
                                <Button onClick={onClickSendNewInvitation} type="primary" size="large">
                                    {formatMessage(formMessages.reset_password_resend)}
                                </Button>
                            </>
                        )}
                    </>
                ) : (
                    formContent
                )}
            </Form>
        </LoginLayout>
    );
};

export default ResetPassword;
