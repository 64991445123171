import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 25 28">
        <path
            fill="currentColor"
            d="M12.5,16.3333333 C14.2854045,16.3333333 15.9833822,16.4908425 17.5197377,16.77464 L18.1254841,16.8948204 C22.2034457,17.7610666 25,19.5397262 25,21.5932364 C25,24.4489653 19.5916661,27.8814664 12.8498813,27.997002 L12.5,28 C5.59643602,28 0,24.498202 0,21.5932364 C0,19.5397262 2.79655439,17.7610666 6.87451595,16.8948204 L7.48026241,16.77464 C8.91419416,16.5097624 10.4889168,16.3548958 12.1441068,16.3354242 L12.5,16.3333333 Z M12.5,19.3333333 C9.51881924,19.3333333 6.76160059,19.8240631 4.82472296,20.6390863 C4.10577897,20.9416124 3.55896223,21.2660585 3.21681303,21.5627545 L3.104,21.667 L3.1825386,21.7734659 L3.25857305,21.8656934 C3.6422765,22.3126486 4.27692467,22.8028692 5.10195172,23.2546063 C7.07598756,24.3354741 9.73541592,25 12.5,25 C15.2645842,25 17.9240125,24.3354741 19.8980483,23.2546063 C20.7230754,22.8028692 21.3577235,22.3126486 21.741427,21.8656934 L21.8174614,21.773466 L21.895,21.667 L21.783187,21.5627545 C21.4838064,21.3031455 21.0277273,21.0222903 20.4367491,20.753464 L20.1752771,20.6390863 C18.2383995,19.8240631 15.4811808,19.3333333 12.5,19.3333333 Z M12.9032249,0 C16.4663501,0 19.3548387,2.78577856 19.3548387,6.22221338 C19.3548387,7.87245073 18.6751215,9.45509966 17.4652088,10.6219947 C16.3264675,11.7202488 14.8058678,12.365551 13.2043786,12.4376687 L12.9032249,12.4444444 C11.1921489,12.4444444 9.55115439,11.788889 8.34124214,10.621994 C7.1313299,9.45509908 6.4516129,7.87245043 6.4516129,6.22221338 C6.4516129,2.86214445 9.21314961,0.124133722 12.666707,0.00410364965 L12.9032249,0 Z M12.9032249,3 C10.9731377,3 9.4516129,4.4674238 9.4516129,6.22221338 C9.4516129,7.05369682 9.79714734,7.85823429 10.4238281,8.46263406 C11.0717706,9.08753951 11.9651798,9.44444444 12.9032249,9.44444444 C13.8412711,9.44444444 14.7346807,9.08753963 15.3826231,8.46263439 C16.0093041,7.85823459 16.3548387,7.05369698 16.3548387,6.22221338 C16.3548387,4.46742372 14.8333134,3 12.9032249,3 Z"
            id="user"
        ></path>
    </svg>
);

const IconUser: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-user${props.className ? ` ${props.className}` : ''}`} />
);

export default IconUser;
