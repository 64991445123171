import { defineMessages } from 'react-intl';

export default defineMessages({
    error_default: {
        id: 'login.error_default',
        defaultMessage: 'Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur',
    },
    user_not_found: {
        id: 'login.user_not_found',
        defaultMessage: "Nom d'utilisateur introuvable",
    },
    invalid_password: {
        id: 'login.invalid_password',
        defaultMessage: 'Mot de passe incorrect',
    },
    account_not_validated: {
        id: 'login.account_not_validated',
        defaultMessage: 'Compte non validé',
    },
    not_authorized: {
        id: 'login.not_authorized',
        defaultMessage: 'Non autorisé',
    },
    login_title: {
        id: 'login.login_title',
        defaultMessage: 'Connexion',
    },
    no_account_yet: {
        id: 'login.no_account_yet',
        defaultMessage: 'Vous n’avez pas de compte ?',
    },
    register: {
        id: 'login.register',
        defaultMessage: 'Inscrivez-vous',
    },
    forgot_password: {
        id: 'login.forgot_password',
        defaultMessage: 'Mot de passe oublié ?',
    },
    login_cta: {
        id: 'login.login_cta',
        defaultMessage: 'SE CONNECTER',
    },
});
