import { defineMessages } from 'react-intl';

export default defineMessages({
    back: {
        id: 'offer.back',
        defaultMessage: 'Retour aux offres',
    },
    title: {
        id: 'offer.title',
        defaultMessage: 'Vous avez choisi l’offre suivante',
    },
    settings: {
        id: 'offer.settings',
        defaultMessage: 'Configuration',
    },
    prices_title: {
        id: 'offer.prices_title',
        defaultMessage: 'Propositions de prix',
    },
    price_per_subscription: {
        id: 'offer.price_per_subscription',
        defaultMessage: '(Prix par abonnement)',
    },
    prices_intro: {
        id: 'offer.prices_intro',
        defaultMessage: 'Sélectionnez la proposition ci-dessous qui vous convient',
    },
    users_title: {
        id: 'offer.users_title',
        defaultMessage: 'Utilisateur de l’abonnement et véhicule(s)',
    },
    complete_infos_bellow: {
        id: 'offer.complete_infos_bellow',
        defaultMessage: 'Complétez les informations ci-dessous',
    },
    your_addresses: {
        id: 'offer.your_addresses',
        defaultMessage: 'Vos adresses',
    },
    payment_summary: {
        id: 'offer.payment_summary',
        defaultMessage: 'Récapitulatif paiement',
    },
    are_you_the_user: {
        id: 'offer.are_you_the_user',
        defaultMessage: 'Vous êtes l’utilisateur ?',
    },
    use_your_infos: {
        id: 'offer.use_your_infos',
        defaultMessage: 'Utilisez vos informations',
    },
    your_vehicles: {
        id: 'offer.your_vehicles',
        defaultMessage: 'Vos véhicules (jusque {count} possible)',
    },
    your_vehicle: {
        id: 'offer.your_vehicle',
        defaultMessage: 'Votre véhicule',
    },
    validate_and_pay: {
        id: 'offer.validate_and_pay',
        defaultMessage: 'Valider et payer {price}',
    },
    subscription_index: {
        id: 'offer.subscription_index',
        defaultMessage: 'Abonnement n°{index}',
    },
    use_another_address: {
        id: 'offer.use_another_address',
        defaultMessage: 'Utiliser une autre adresse',
    },
    use_existing_address: {
        id: 'offer.use_existing_address',
        defaultMessage: 'Sélectionner une adresse existante',
    },
});
