import React, { FC, useContext, useEffect, useState } from 'react';
import Seo from '../../components/Seo';
import PriceCard from './PriceCard';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import ButtonBack from '../../components/ButtonBack';
import VehicleIcon from '../../components/VehicleIcon';
import { CalendarOutlined } from '@ant-design/icons';
import IconMarker from '../../components/icons/IconMarker';
import { Alert, Button, Divider, Form, FormProps, Radio, Spin, Switch } from 'antd';
import formMessages from '../../locale/formMessages';
import genericMessages from '../../locale/genericMessages';
import paymemtMessages from '../../locale/paymentMessages';
import Quantity from '../../components/form/Quantity';
import IconArrowTime from '../../components/icons/IconArrowTime';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import { Breakpoint } from 'react-socks';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { BadgeType, OfferPrice, PSPName, Title, UserInfo } from '../../store/api/apiTypes';
import { useSelector } from 'react-redux';
import { useActions, usePrevious } from '../../hooks';
import { SiteContext } from '../../context/SiteContext';
import useSessionStorage from '../../hooks/sessionStorage';
import Price from '../../components/Price';
import UserInfoForm from './UserInfoForm';
import AddressesForm from './AddressesForm';
import { getDomain, getRoute, RoutePathName } from '../../routes';
import { getAuthState, getUser } from '../../store/actions/auth';
import {
    prices as pricesAction,
    details as detailsAction,
    create as createAction,
    getOffersPricesState,
    getOffersDetailsState,
    getOffersCreateState,
    authNmiFailure as authNmiFailureAction,
    authNmiCompleted as authNmiCompletedAction,
    authNmiError as authNmiErrorAction,
    getOffersAuthNmiCompletedState,
} from '../../store/actions/offers';

import '../../assets/styles/Offer.less';
import 'swiper/swiper.less';
import 'swiper/components/pagination/pagination.less';
import { canOrderBadgeType } from '../../helpers';
import Modal3DSecure from '../payment/Modal3DSecure';

SwiperCore.use([Pagination]);

declare global {
    interface Window {
        CollectJS?: any;
        Gateway?: any;
        RealexHpp?: any;
        createdGateway?: any;
        threeDSecureInterface?: any;
    }
}
export interface OfferConfig {
    totalSubscriptions: number;
    badgeType?: string;
    endNotification?: boolean;
}

const Offer: FC = () => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const [form] = Form.useForm();
    const { siteId, site, transaction, setTransaction } = useContext(SiteContext);
    const authState = useSelector(getAuthState);
    const authNmiState = useSelector(getOffersAuthNmiCompletedState);
    const user = useSelector(getUser);
    const { parkingId, offerId } = useParams<{ siteId: string; parkingId: string; offerId: string }>();
    const [loadPrices, loadDetails, initTransaction, authNmiCompleted, authNmiFailure, authNmiError] = useActions([
        pricesAction.trigger,
        detailsAction.trigger,
        createAction.trigger,
        authNmiCompletedAction.trigger,
        authNmiFailureAction.trigger,
        authNmiErrorAction.trigger,
    ]);
    const offerDetailsState = useSelector(getOffersDetailsState);
    const offerPricesState = useSelector(getOffersPricesState);
    const initTransactionState = useSelector(getOffersCreateState);
    const [searchValues] = useSessionStorage('searchValues', undefined);
    const [offerFormValues, setOfferFormValues] = useSessionStorage('offerFormValues', undefined);
    const [selectedPrice, setSelectedPrice] = useState<OfferPrice>();
    const [isWaitingForPayment, setIsWaitingForPayment] = useState(false);
    const [transactionDatas, setTransactionDatas] = useState<any>();
    const [modal3DSecureVisible, setModal3DSecureVisible] = useState<boolean>(false);
    const [error, setError] = useState<any>();
    const [offerValues, setOfferValues] = useState<OfferConfig>({
        totalSubscriptions: 1,
        badgeType: undefined,
        endNotification: undefined,
    });
    const previous = usePrevious({
        initTransactionState,
        authNmiState,
    });
    const close3DSecureModal = () => {
        setModal3DSecureVisible(false);
        if (window.threeDSecureInterface?.isMounted()) {
            window.threeDSecureInterface?.unmount();
        }
        setIsWaitingForPayment(false);
    };
    const prices = offerPricesState.data?.items;
    const offer = offerDetailsState?.data ? offerDetailsState.data[0] : null;
    const parking = offer ? offer.zones?.filter((zone) => zone.parkingId === parseInt(parkingId))[0] : null;

    const totalPrice = selectedPrice ? offerValues.totalSubscriptions * selectedPrice.price.totalDueAmount : undefined;
    const totalPriceDutyFree = selectedPrice
        ? offerValues.totalSubscriptions * selectedPrice.price.dutyFreeAmount
        : undefined;

    const isNMIWithoutTokenizationKey = site?.psp.name === PSPName.nmi && !site.psp.nmi_public_tokenisation_key;

    useEffect(() => {
        if (offerFormValues?.offerId && offerFormValues?.offerId === offerId && offer && prices) {
            form.setFieldsValue(offerFormValues);
        } else {
            setOfferFormValues('');
            loadDetails({ siteId, offerId });
            loadPrices({
                siteId,
                offerPriceCalculation: {
                    offerId,
                    beginValidityDate: searchValues.subscriptionDate,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onPriceSelect = (price: OfferPrice) => {
        setSelectedPrice(price);
    };

    const onOfferValuesChanged: FormProps['onValuesChange'] = (changedValues, values) => {
        setOfferValues(values);
    };

    const onSearchFormFinish: FormProps['onFinish'] = () => {
        setError(undefined);
        form.validateFields().then(async (values) => {
            // Format the API request from form datas

            setOfferFormValues({
                ...values,
                offerId,
            });

            const titles: Title[] = [];
            values.users.forEach((user: UserInfo) => {
                titles.push({
                    offerSale: {
                        offerPeriodType: selectedPrice?.periodType,
                        offerId: offer?.offerId,
                        beginValidityDate: searchValues.subscriptionDate,
                    },
                    offerPrice: selectedPrice,
                    handler: {
                        isCompany: false,
                        name: user.lastName,
                        firstName: user.firstName,
                        title: user.civility,
                    },
                    licensePlates: user.licensePlates?.map((licensePlate: any) => licensePlate.code),
                    withBarcode: true,
                });
            });

            const transactionData = {
                account: {
                    id: user?.id,
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                },
                returnURL: encodeURI(getDomain() + getRoute(RoutePathName.paymentConfirm)),
                cancelURL: encodeURI(getDomain() + getRoute(RoutePathName.paymentCancel)),
                parkingName: parking?.parkingName,
                deadline: values?.endNotification && values.endNotification === 'yes',
                badgeType: values.badgeType,
                offer: offer,
                titles,
                billingAddress: values.billingAddress,
                deliveryAddress: values.deliveryAddress,
            };
            setTransactionDatas(transactionData);
            initTransaction({
                siteId,
                body: transactionData,
            });
        });
    };

    useEffect(() => {
        if (site?.psp.name === PSPName.nmi) {
            if (site?.psp.nmi_public_tokenisation_key) {
                const head = document.getElementsByTagName('head')[0];
                const script = document.createElement('script');
                script.src = 'https://secure.safewebservices.com/token/Collect.js';
                script.dataset.tokenizationKey = site.psp.nmi_public_tokenisation_key;
                head.appendChild(script);
            } else {
                setError(paymemtMessages.missingNMIToken);
            }
        }
    }, [site?.psp.name, site?.psp.nmi_public_tokenisation_key, formatMessage]);

    useEffect(() => {
        if (
            totalPrice &&
            transaction &&
            transactionDatas &&
            site?.psp.name === PSPName.nmi &&
            site?.psp.nmi_public_tokenisation_key
        ) {
            window.CollectJS.configure({
                variant: 'lightbox',
                instructionText: formatMessage(paymemtMessages.instructionPaymentNMI),
                buttonText: formatMessage(paymemtMessages.buttonPaymentNMI),
                callback: (e: any) => {
                    setIsWaitingForPayment(true);
                    const options = {
                        paymentToken: e.token,
                        currency: site?.currencyIso,
                        amount: totalPrice / Math.pow(10, 2),
                        email: user?.email,
                        city: transactionDatas.billingAddress.city,
                        address1: transactionDatas.billingAddress.street,
                        country: 'GB', // transactionDatas.billingAddress.country is only set to GB in NMI back-office
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                        postalCode: transactionDatas.billingAddress.zipCode,
                    };
                    if (window.threeDSecureInterface?.isMounted()) {
                        window.threeDSecureInterface?.unmount();
                    }
                    if (!window?.createdGateway?.publicKey) {
                        window.createdGateway = window.Gateway.create(site.psp.nmi_public_checkout_key);
                    }
                    const threeDS = window.createdGateway.get3DSecure();
                    window.threeDSecureInterface = threeDS.createUI(options);
                    window.threeDSecureInterface.start('#threeDSMountPoint');
                    window.createdGateway.on('error', (e: any) => {
                        console.error('gateway', e);
                        authNmiError({
                            siteId,
                            e,
                        });
                        setError(genericMessages.error_payment);
                        close3DSecureModal();
                    });
                    threeDS.on('error', (e: any) => {
                        console.error('threeDS', e);
                        authNmiError({
                            siteId,
                            e,
                        });
                        setError(genericMessages.error_payment);
                        close3DSecureModal();
                    });
                    window.threeDSecureInterface.on('complete', function (res: any) {
                        const body = {
                            card: e.card,
                            ...res,
                            xid: res.xid,
                            cavv: res.cavv,
                            eci: res.eci,
                            cardHolderAuth: res.cardHolderAuth,
                            directoryServerId: res.directoryServerId,
                            threeDsVersion: res.threeDsVersion,
                            paymentToken: options.paymentToken,
                            currency: options.currency,
                            amount: totalPrice,
                            transactionId: transaction?.id,
                        };
                        authNmiCompleted({
                            siteId,
                            body,
                        });
                        close3DSecureModal();
                    });

                    window.threeDSecureInterface.on('failure', function (res: any) {
                        console.log('failure', res);
                        if (res.code === 'TRANSACTION_STATUS_N') {
                            setError(paymemtMessages.failure_paymentNMI_N);
                            // transaction denied|| authentication error
                        } else if (res.code === 'TRANSACTION_STATUS_U') {
                            setError(paymemtMessages.failure_paymentNMI_U);
                        } else if (res.code === 'TRANSACTION_STATUS_R') {
                            setError(paymemtMessages.failure_paymentNMI_R);
                        } else {
                            setError(paymemtMessages.failure_paymentNMI);
                        }
                        const body = {
                            ...res,
                            transactionId: transaction?.id,
                        };
                        authNmiFailure({
                            siteId,
                            body,
                        });
                        close3DSecureModal();
                    });
                    window.threeDSecureInterface.on('error', (res: any) => {
                        console.error('error', res);
                        const body = {
                            ...res,
                            transactionId: transaction?.id,
                        };
                        setError(genericMessages.error_payment);
                        authNmiError({
                            siteId,
                            body,
                        });
                        close3DSecureModal();
                    });
                    window.threeDSecureInterface.on('challenge', (res: any) => {
                        console.log('challenge', res);
                        setModal3DSecureVisible(true);
                    });
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalPrice, site, transactionDatas, transaction]);

    useEffect(() => {
        if (previous?.initTransactionState.loading && !initTransactionState.loading) {
            if (initTransactionState.success && initTransactionState.data) {
                setTransaction(initTransactionState.data);
                if (
                    site?.psp.name === PSPName.payline ||
                    site?.psp.name === PSPName.advam ||
                    site?.psp.name === PSPName.globalPayment
                ) {
                    history.push(getRoute(RoutePathName.payment));
                } else if (site?.psp.name === PSPName.nmi) {
                    if (site?.psp.nmi_public_tokenisation_key) {
                        window.CollectJS.startPaymentRequest();
                    } else {
                        setError(paymemtMessages.missingNMIToken);
                    }
                } else {
                    setError(genericMessages.error_not_available);
                }
            }
        }
    }, [previous, initTransactionState, setTransaction, history, site, formatMessage]);

    useEffect(() => {
        if (previous?.authNmiState.loading && !authNmiState.loading) {
            if (authNmiState.success && authNmiState.data) {
                setTransaction(authNmiState.data);
                history.push(getRoute(RoutePathName.paymentConfirm), { transactionId: authNmiState.data.id });
            } else {
                setError(genericMessages.error_not_available);
            }
        }
    }, [previous, authNmiState, history, setTransaction]);

    useEffect(() => {
        return () => {
            if (window.threeDSecureInterface?.isMounted()) {
                window.threeDSecureInterface?.unmount();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!authState.isConnected) {
        return <Redirect to={getRoute(RoutePathName.parkings)} />;
    }

    return (
        <>
            <Seo title="Offre" />
            <div id="offer">
                <Form
                    form={form}
                    requiredMark={false}
                    colon={false}
                    layout="vertical"
                    onValuesChange={onOfferValuesChanged}
                    onFinish={onSearchFormFinish}
                    scrollToFirstError={isWaitingForPayment || authNmiState.loading}
                >
                    <div className="wrapper mt-3 mb-3">
                        <ButtonBack label={formatMessage(messages.back)} />

                        <h1 className="title title-1 larger">
                            <FormattedMessage {...messages.title} />
                        </h1>

                        <Spin spinning={offerDetailsState.loading}>
                            <div className="offer-summary">
                                <VehicleIcon type={offer?.vehicleClass} />
                                <div className="name">{offer?.offerName}</div>
                                <div className="separator" />
                                <div className="parking">
                                    <IconMarker className="text-primary" />
                                    &nbsp;{' '}
                                    {parking && (
                                        <>
                                            {parking.parkingName} - {parking.zoneName}
                                        </>
                                    )}
                                </div>
                                {searchValues?.subscriptionDate && (
                                    <div className="dates">
                                        <CalendarOutlined className="text-primary" />
                                        &nbsp; <FormattedDate value={searchValues.subscriptionDate} />
                                    </div>
                                )}
                                <div className="separator" />
                                <div className="price text-primary">
                                    <Price value={offer?.sellingPrice?.totalDueAmount} currency={offer?.currencyIso} />
                                </div>
                            </div>
                        </Spin>

                        <h1 className="title title-1">
                            <FormattedMessage {...messages.settings} />
                        </h1>

                        <Form.Item
                            label={formatMessage(formMessages.total_subscription_label)}
                            name="totalSubscriptions"
                        >
                            <Quantity disabled={isWaitingForPayment || authNmiState.loading} />
                        </Form.Item>

                        <Form.Item
                            label={formatMessage(formMessages.badge_type_label)}
                            name="badgeType"
                            rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                        >
                            <Radio.Group
                                buttonStyle="solid"
                                size="large"
                                className="separate-mobile"
                                disabled={isWaitingForPayment || authNmiState.loading}
                            >
                                {canOrderBadgeType(site, BadgeType.dematerialized) && (
                                    <Radio.Button value="Dematerialized">
                                        <FormattedMessage {...formMessages.badge_type_dematerialized_label} />
                                    </Radio.Button>
                                )}
                                {canOrderBadgeType(site, BadgeType.physical) && (
                                    <Radio.Button value="Physical">
                                        <FormattedMessage {...formMessages.badge_type_physical_label} />
                                    </Radio.Button>
                                )}
                                {canOrderBadgeType(site, BadgeType.dematerializedAndPhysical) && (
                                    <Radio.Button value="DematerializedAndPhysical">
                                        <FormattedMessage {...formMessages.badge_type_both_label} />
                                    </Radio.Button>
                                )}
                            </Radio.Group>
                        </Form.Item>

                        {site?.deadline && (
                            <Form.Item
                                label={formatMessage(formMessages.end_subscription_notification_label)}
                                name="endNotification"
                                rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                            >
                                <Radio.Group
                                    buttonStyle="solid"
                                    size="large"
                                    disabled={isWaitingForPayment || authNmiState.loading}
                                >
                                    <Radio.Button value="yes">
                                        <FormattedMessage {...genericMessages.yes} />
                                    </Radio.Button>
                                    <Radio.Button value="no">
                                        <FormattedMessage {...genericMessages.no} />
                                    </Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        )}

                        <Divider />

                        <h1 className="title title-1 prices-title">
                            <FormattedMessage {...messages.prices_title} />{' '}
                            <small>
                                <FormattedMessage {...messages.price_per_subscription} />
                            </small>
                        </h1>

                        <p>
                            <FormattedMessage {...messages.prices_intro} />
                        </p>

                        {offerPricesState.loading || !offer ? (
                            <Spin spinning={isWaitingForPayment || authNmiState.loading} />
                        ) : (
                            <div id="prices-swiper" className="mt-2">
                                <Swiper
                                    slidesPerView={1}
                                    watchOverflow={isWaitingForPayment || authNmiState.loading}
                                    pagination={{
                                        el: '.prices-slider-pagination',
                                        clickable: true,
                                    }}
                                    breakpoints={{
                                        900: {
                                            spaceBetween: 20,
                                            slidesPerView: 2,
                                        },
                                        1200: {
                                            spaceBetween: 20,
                                            slidesPerView: 4,
                                        },
                                    }}
                                >
                                    {prices?.map((price, i) => (
                                        <SwiperSlide key={'offer-price-' + i.toString()}>
                                            <PriceCard
                                                offer={offer}
                                                price={price}
                                                onSelect={onPriceSelect}
                                                selectedPrice={selectedPrice}
                                            />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                <div className="swiper-pagination prices-slider-pagination" />
                            </div>
                        )}

                        {selectedPrice && (
                            <>
                                <h1 className="title title-1 prices-title mt-3">
                                    <FormattedMessage {...messages.users_title} />
                                </h1>

                                <p>
                                    <FormattedMessage {...messages.complete_infos_bellow} />
                                </p>

                                <div className="card">
                                    {[...Array(offerValues.totalSubscriptions)].map((value, index) => (
                                        <div className="user-form-row" key={'user-' + index.toString()}>
                                            {offerValues.totalSubscriptions > 1 && (
                                                <h1 className="title title-1 smaller mb-1">
                                                    <FormattedMessage
                                                        {...messages.subscription_index}
                                                        values={{ index: index + 1 }}
                                                    />
                                                </h1>
                                            )}

                                            <UserInfoForm
                                                form={form}
                                                index={index}
                                                disabled={isWaitingForPayment || authNmiState.loading}
                                            />
                                        </div>
                                    ))}
                                </div>

                                <AddressesForm form={form} disabled={isWaitingForPayment || authNmiState.loading} />

                                <h1 className="title title-1 mt-3 mb-2">
                                    <FormattedMessage {...messages.payment_summary} />
                                </h1>

                                <div className="card payment-summary">
                                    <div className="price-row">
                                        <div className="price-label offer-summary-alt">
                                            <VehicleIcon type={offer?.vehicleClass} />
                                            <div className="summary">
                                                <div className="name">
                                                    {offerValues.totalSubscriptions > 1 && (
                                                        <>
                                                            <span className="total-subs">
                                                                <strong>{offerValues.totalSubscriptions}x</strong>
                                                            </span>
                                                            &nbsp;
                                                        </>
                                                    )}
                                                    {offer?.offerName}
                                                    {offerValues.totalSubscriptions > 1 && (
                                                        <span className="single-price">
                                                            <Price
                                                                value={selectedPrice.price.totalDueAmount}
                                                                currency={offer?.currencyIso}
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="details">
                                                    <div className="parking">
                                                        <IconMarker className="text-primary" />
                                                        &nbsp;
                                                        {parking && (
                                                            <>
                                                                {parking.parkingName} - {parking.zoneName}
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className="dates">
                                                        <CalendarOutlined className="text-primary" />
                                                        &nbsp; <FormattedDate
                                                            value={selectedPrice.beginValidityDate}
                                                        />{' '}
                                                        <IconArrowTime />{' '}
                                                        <FormattedDate value={selectedPrice.endValidityDate} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Breakpoint lg up>
                                            <div className="price">
                                                <Price value={totalPrice} currency={offer?.currencyIso} />
                                            </div>
                                        </Breakpoint>
                                    </div>
                                    <Divider />
                                    <div className="price-row">
                                        <div className="price-label">
                                            <FormattedMessage {...formMessages.total_without_vat} />
                                        </div>
                                        <div className="price">
                                            <Price value={totalPriceDutyFree} currency={offer?.currencyIso} />
                                        </div>
                                    </div>
                                    {selectedPrice.price.dutiesAmount.map((duty, index) => (
                                        <div className="price-row" key={'duty-' + index.toString()}>
                                            <div className="price-label">
                                                <FormattedMessage
                                                    {...formMessages.total_vat}
                                                    values={{ name: duty.name, rate: duty.rate }}
                                                />
                                            </div>
                                            <div className="price">
                                                <Price
                                                    value={duty.amount * offerValues.totalSubscriptions}
                                                    currency={offer?.currencyIso}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    <Divider />
                                    <div className="price-row larger">
                                        <div className="price-label">
                                            <FormattedMessage {...formMessages.total_to_pay} />
                                        </div>
                                        <div className="price text-primary">
                                            <Price value={totalPrice} currency={offer?.currencyIso} />
                                        </div>
                                    </div>
                                </div>

                                <div className="switch-row mt-2 text-left">
                                    <Form.Item
                                        name="acceptCgv"
                                        rules={[
                                            { required: true },
                                            {
                                                type: 'boolean',
                                                message: formatMessage(formMessages.error_required),
                                                validator: async (_, value) => {
                                                    if (value) {
                                                        return await Promise.resolve();
                                                    }
                                                    return await Promise.reject(
                                                        new Error('This field must be checked')
                                                    );
                                                },
                                            },
                                        ]}
                                        valuePropName="checked"
                                        className="mt-0 mb-0"
                                    >
                                        <Switch
                                            disabled={isWaitingForPayment || authNmiState.loading}
                                            className="mb-0"
                                        />
                                    </Form.Item>
                                    <div
                                        className="label"
                                        dangerouslySetInnerHTML={{
                                            __html: formatMessage(formMessages.accept_cgv, {
                                                cgv:
                                                    '<a target="_blank" href="' +
                                                    getRoute(RoutePathName.legals, { slug: 'cgv' }) +
                                                    '">' +
                                                    formatMessage(formMessages.cgv) +
                                                    '</a>',
                                            }),
                                        }}
                                    />
                                </div>
                                {error ? (
                                    <div className="login-error-message mt-2">
                                        <Alert type="error" message={<FormattedMessage {...error} />} showIcon />
                                    </div>
                                ) : null}
                                <div className="text-center mt-3 mb-3">
                                    <Button
                                        type="primary"
                                        size="large"
                                        shape="round"
                                        className="btn-xl"
                                        htmlType="submit"
                                        loading={isWaitingForPayment || authNmiState.loading}
                                        disabled={isNMIWithoutTokenizationKey}
                                    >
                                        <FormattedMessage
                                            {...messages.validate_and_pay}
                                            values={{
                                                price: <Price value={totalPrice} currency={offer?.currencyIso} />,
                                            }}
                                        />
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </Form>
                <Modal3DSecure isVisible={modal3DSecureVisible} onClose={close3DSecureModal} />
            </div>
        </>
    );
};

export default Offer;
