import { Button } from 'antd';
import React, { FC } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import IconCheck from '../../components/icons/IconCheck';
import Price from '../../components/Price';
import { isSelectedPrice } from '../../helpers';
import genericMessages from '../../locale/genericMessages';
import { Offer, OfferPrice, PeriodTypeMessages } from '../../store/api/apiTypes';

export interface OfferCardProps {
    offer: Offer;
    price: OfferPrice;
    selectedPrice?: OfferPrice;
    onSelect: (price: OfferPrice) => void;
}

const PriceCard: FC<OfferCardProps> = ({ offer, price, selectedPrice, onSelect }) => {
    const isSelected = isSelectedPrice(price, selectedPrice);
    const select = () => {
        onSelect(price);
    };

    return (
        <div className={'offer-card ' + (isSelected ? 'selected' : '')} onClick={select} aria-hidden="true">
            <div className="body text-center">
                <div className="flex-grow">
                    <div className="subscription-name mt-2 mb-2">
                        <FormattedMessage {...PeriodTypeMessages.get(price.periodType)} />
                    </div>
                    {price.periods.map((period, index) => (
                        <div className="parking-name" key={'period-' + index.toString()}>
                            <FormattedMessage
                                {...genericMessages.dates_from_to}
                                values={{
                                    from: (
                                        <strong>
                                            <FormattedDate value={period.beginValidityDate} />
                                        </strong>
                                    ),
                                    to: (
                                        <strong>
                                            <FormattedDate value={period.endValidityDate} />
                                        </strong>
                                    ),
                                }}
                            />
                            {price.periods.length > 1 && (
                                <>
                                    <br />
                                    <span className="text-primary">
                                        <Price value={period.price.totalDueAmount} currency={offer.currencyIso} />
                                    </span>
                                </>
                            )}
                        </div>
                    ))}
                </div>
                <div className="price-container mt-2 mb-2">
                    <small>
                        <FormattedMessage {...genericMessages.to_pay_now} />
                    </small>
                    <div className="price text-primary">
                        {offer.months && <Price value={price.price.totalDueAmount} currency={offer.currencyIso} />}
                    </div>
                </div>
                {isSelected ? (
                    <div className="icon-checked">
                        <IconCheck />
                    </div>
                ) : (
                    <Button type="primary" shape="round" size="large" block className="reverse">
                        <FormattedMessage {...genericMessages.select} />
                    </Button>
                )}
            </div>
        </div>
    );
};

export default PriceCard;
