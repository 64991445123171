import { combineReducers } from 'redux';

import { AuthState, authReducer } from '../actions/auth';
import { CookiesState, cookiesReducer } from '../actions/cookies';
import { LogsState, logsReducer } from '../actions/logs';
import { TestState, testReducer } from '../actions/test';
import { UsersState, usersReducer } from '../actions/users';
import { ParkingsState, parkingsReducer } from '../actions/parkings';
import { OffersState, offersReducer } from '../actions/offers';
import { SitesState, sitesReducer } from '../actions/sites';

export interface MainReducerState {
    readonly auth: AuthState;
    readonly cookies: CookiesState;
    readonly logs: LogsState;
    readonly test: TestState;
    readonly users: UsersState;
    readonly parkings: ParkingsState;
    readonly offers: OffersState;
    readonly sites: SitesState;
}

const mainReducer = combineReducers<MainReducerState>({
    auth: authReducer,
    cookies: cookiesReducer,
    logs: logsReducer,
    test: testReducer,
    users: usersReducer,
    parkings: parkingsReducer,
    offers: offersReducer,
    sites: sitesReducer,
});

export default mainReducer;

export interface RequestState<T = any> {
    readonly data?: T;
    readonly error?: any;
    readonly loading: boolean;
    readonly response?: any;
    readonly success?: boolean;
    readonly total?: number;
}
