import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Form, FormProps, Skeleton, Spin } from 'antd';

import Seo from '../../components/Seo';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import ParkingPicker from '../parkings/ParkingPicker';
import SubscriptionDatePicker from '../parkings/SubscriptionDatePicker';
import VehicleTypePicker from '../parkings/VehicleTypePicker';
import OfferCard from './OfferCard';
import { Breakpoint } from 'react-socks';
import { EditOutlined } from '@ant-design/icons';
import useScrollPosition from '../../hooks/scrollPosition';
import { useHistory } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';
import useSessionStorage from '../../hooks/sessionStorage';
import { list as listAction, getOffersListState } from '../../store/actions/offers';
import { useSelector } from 'react-redux';
import { useActions } from '../../hooks';
import genericMessages from '../../locale/genericMessages';
import { SiteContext } from '../../context/SiteContext';

import '../../assets/styles/Offers.less';
import { OfferListPayload } from '../../store/api/offers';
import { Offer, VehicleType } from '../../store/api/apiTypes';
import formMessages from '../../locale/formMessages';
import { getAuthState } from '../../store/actions/auth';

const Offers: FC = () => {
    const { formatMessage } = useIntl();
    const { siteId } = useContext(SiteContext);
    const [loadOffers] = useActions([listAction.trigger]);
    const listOffersState = useSelector(getOffersListState);
    const authState = useSelector(getAuthState);

    const history = useHistory();
    const offersListRef = useRef<HTMLDivElement>(null);
    const scrollPosition = useScrollPosition();
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [searchValues, setSearchValues] = useSessionStorage('searchValues', undefined);

    const totalItems = listOffersState?.data?.items ? listOffersState?.data?.items.length : 0;

    useEffect(() => {
        const filters: OfferListPayload = {};

        if (searchValues?.parking) filters.parkingIds = [searchValues.parking.id];
        if (searchValues?.vehicleType && searchValues?.vehicleType !== VehicleType.all) {
            filters.vehicleClasses = [searchValues.vehicleType];
        }
        if (searchValues?.subscriptionDate) filters.subscriptionDate = searchValues.subscriptionDate;

        loadOffers({ ...filters, siteId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValues]);

    const onOfferSelect = (offer: Offer) => {
        const redirectUrl = getRoute(RoutePathName.offer, {
            parkingId: searchValues.parking.id,
            offerId: offer.offerId,
        });

        if (authState.isConnected) {
            history.push(redirectUrl);
        } else {
            history.push(getRoute(RoutePathName.login), { redirect: redirectUrl });
        }
    };

    const editSearch = () => {
        if (offersListRef.current) {
            setShowFilters(true);
            offersListRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const searchBar = () => (
        <Form form={form} onValuesChange={onSearchFormFinish} initialValues={searchValues} id="searchbar">
            <Form.Item name="parking" rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}>
                <ParkingPicker />
            </Form.Item>
            <Form.Item
                name="subscriptionDate"
                rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
            >
                <SubscriptionDatePicker />
            </Form.Item>
            <Form.Item
                name="vehicleType"
                rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
            >
                <VehicleTypePicker />
            </Form.Item>
        </Form>
    );

    const onSearchFormFinish: FormProps['onValuesChange'] = (changes, values) => {
        setSearchValues(values);
    };

    return (
        <>
            <Seo title="Offres" />
            <div id="offers-list" ref={offersListRef}>
                <div className="wrapper mt-3 mb-3">
                    <div className="result-count text-primary">
                        <FormattedMessage
                            {...messages.available_offers}
                            values={{
                                count: totalItems,
                            }}
                        />
                    </div>
                    <h1 className="title title-1">
                        <FormattedMessage {...messages.title} />
                    </h1>

                    <Breakpoint md down>
                        {!showFilters ? (
                            <div
                                className="search-summary"
                                onClick={setShowFilters.bind(null, true)}
                                aria-hidden="true"
                            >
                                <div className="summary">
                                    <div className="name">Parking Poterne/Cathedrale</div>
                                    <div className="list">
                                        <span>01/04/2021</span>
                                        <span>Voiture</span>
                                    </div>
                                </div>
                                <div className="icon">
                                    <EditOutlined />
                                </div>
                            </div>
                        ) : (
                            <>{searchBar()}</>
                        )}

                        {scrollPosition > 400 && (
                            <div className="fixed-footer-actions">
                                <Button type="primary" className="dark" shape="round" onClick={editSearch}>
                                    <FormattedMessage {...messages.cta_edit_search} />
                                </Button>
                            </div>
                        )}
                    </Breakpoint>

                    <Breakpoint lg up>
                        <>{searchBar()}</>
                    </Breakpoint>

                    <Spin spinning={listOffersState.loading}>
                        {listOffersState.data?.items && listOffersState.data?.items.length > 0 ? (
                            <div id="offers-grid">
                                {listOffersState.data.items.map((offer) => (
                                    <OfferCard offer={offer} onSelect={onOfferSelect} key={'offer-' + offer.offerId} />
                                ))}
                            </div>
                        ) : (
                            <>
                                {listOffersState.error ? (
                                    <Alert
                                        type="error"
                                        message={<FormattedMessage {...genericMessages.error_not_available} />}
                                        showIcon
                                    />
                                ) : (
                                    <>
                                        {listOffersState.loading ? (
                                            <div id="offers-grid">
                                                <Skeleton loading={true} />
                                                <Skeleton loading={true} />
                                                <Skeleton loading={true} />
                                            </div>
                                        ) : (
                                            <Alert
                                                type="warning"
                                                message={<FormattedMessage {...genericMessages.error_no_result} />}
                                                showIcon
                                            />
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </Spin>
                </div>
            </div>
        </>
    );
};

export default Offers;
