import React, { FC, useContext } from 'react';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { getRawRoute, getRoute, RoutePathName } from '../../routes';
import ErrorPage from '../error';
import { Divider, Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import accountMessages from '../../locale/accountMessages';
import Informations from './informations/Informations';
import IconLogout from '../../components/icons/IconLogout';
import { Breakpoint } from 'react-socks';
import Password from './password/Password';
import Addresses from './addresses/Addresses';
import Subscriptions from './subscriptions/Subscriptions';
import { SiteContext } from '../../context/SiteContext';
import { getAuthState, logout as logoutAction } from '../../store/actions/auth';
import { useActions } from '../../hooks';
import { useSelector } from 'react-redux';

import '../../assets/styles/Account.less';

const Account: FC = () => {
    const { siteId } = useContext(SiteContext);
    const logout = useActions(logoutAction.trigger);
    const siteUri = '/' + siteId;
    const location = useLocation();
    const authState = useSelector(getAuthState);

    if (!authState.isConnected) {
        return <Redirect to={getRoute(RoutePathName.parkings)} />;
    }

    return (
        <div id="account-layout">
            <div className="wrapper mt-3 mb-3">
                <Breakpoint xl up>
                    <div className="menu-container mt-2">
                        <h2 className="text-primary">
                            <FormattedMessage {...accountMessages.your_account} />
                        </h2>
                        <Menu selectedKeys={[location.pathname]}>
                            <Menu.Item key={getRoute(RoutePathName.accountInformations)}>
                                <Link to={getRoute(RoutePathName.accountInformations)}>
                                    <FormattedMessage {...accountMessages.your_informations} tagName="span" />
                                </Link>
                            </Menu.Item>
                            <Menu.Item key={getRoute(RoutePathName.accountPassword)}>
                                <Link to={getRoute(RoutePathName.accountPassword)}>
                                    <FormattedMessage {...accountMessages.your_password} tagName="span" />
                                </Link>
                            </Menu.Item>
                            <Menu.Item key={getRoute(RoutePathName.accountAddresses)}>
                                <Link to={getRoute(RoutePathName.accountAddresses)}>
                                    <FormattedMessage {...accountMessages.your_addresses} tagName="span" />
                                </Link>
                            </Menu.Item>
                        </Menu>

                        <Divider />

                        <h2 className="text-primary">
                            <FormattedMessage {...accountMessages.your_subscriptions} />
                        </h2>
                        <Menu selectedKeys={[location.pathname]}>
                            <Menu.Item key={getRoute(RoutePathName.accountSubscriptions)}>
                                <Link to={getRoute(RoutePathName.accountSubscriptions)}>
                                    <FormattedMessage {...accountMessages.subscriptions} tagName="span" />
                                </Link>
                            </Menu.Item>
                        </Menu>

                        <Divider />

                        <Menu>
                            <Menu.Item key={getRoute(RoutePathName.accountInformations)}>
                                <a href="#logout" onClick={logout}>
                                    <FormattedMessage {...accountMessages.logout} tagName="span" /> <IconLogout />
                                </a>
                            </Menu.Item>
                        </Menu>
                    </div>
                </Breakpoint>

                <div className="account-page card">
                    <div>
                        <Switch>
                            <Route exact path={siteUri + getRawRoute(RoutePathName.accountInformations)}>
                                <Informations />
                            </Route>

                            <Route exact path={siteUri + getRawRoute(RoutePathName.accountPassword)}>
                                <Password />
                            </Route>

                            <Route exact path={siteUri + getRawRoute(RoutePathName.accountAddresses)}>
                                <Addresses />
                            </Route>

                            <Route exact path={siteUri + getRawRoute(RoutePathName.accountSubscriptions)}>
                                <Subscriptions />
                            </Route>

                            <Route path="*">
                                <ErrorPage />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Account;
