import { Button } from 'antd';
import React, { FC, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Breakpoint } from 'react-socks';
import IconArrowDown from '../../components/icons/IconArrowDown';
import genericMessages from '../../locale/genericMessages';
import messages from './messages';
import { VehicleType, vehicleTypeMessages } from '../../store/api/apiTypes';

import '../../assets/styles/CustomPicker.less';

interface VehicleTypePickerProps {
    onChange?: (value?: VehicleType) => void;
    value?: VehicleType;
}

const VehicleTypePicker: FC<VehicleTypePickerProps> = (props) => {
    const container: any = useRef();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [value, setValue] = useState<VehicleType>();

    useEffect(() => {
        if (props.onChange) {
            setValue(props.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    // ---------------------------------
    // Close search on click outside

    const onSelect = (type: VehicleType) => {
        setIsOpen(false);

        if (props.onChange) {
            props.onChange(type);
        }
    };

    const handleClick = (e: any) => {
        if (!container.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------
    // Render
    return (
        <div className={'vehicle-type-picker custom-picker ' + (value ? 'has-value' : '')} ref={container}>
            <div className="btn-open" onClick={setIsOpen.bind(null, true)} aria-hidden="true">
                <div className="content">
                    <Breakpoint lg down>
                        <div className="value">
                            {value ? (
                                <FormattedMessage {...vehicleTypeMessages.get(value)} />
                            ) : (
                                <span className="placeholder">
                                    <FormattedMessage {...messages.vehicletype_picker_label} />
                                </span>
                            )}
                        </div>
                    </Breakpoint>
                    <Breakpoint xl up>
                        <div className="label">
                            <FormattedMessage {...messages.vehicletype_picker_label} />
                        </div>
                        <div className="value">
                            {value ? (
                                <FormattedMessage {...vehicleTypeMessages.get(value)} />
                            ) : (
                                <span className="placeholder">
                                    <FormattedMessage {...messages.vehicletype_picker_placeholder} />
                                </span>
                            )}
                        </div>
                    </Breakpoint>
                </div>
                <IconArrowDown />
            </div>

            {isOpen && (
                <div className="custom-picker-overlay">
                    <div className="head">
                        <span className="head-title">
                            <FormattedMessage {...messages.vehicletype_picker_label} />
                        </span>
                        <Button type="text" className="btn-cancel text-primary" onClick={setIsOpen.bind(null, false)}>
                            <FormattedMessage {...genericMessages.cancel} />
                        </Button>
                    </div>
                    <div className="results-list">
                        <ul>
                            {Object.values(VehicleType).map((type) => (
                                <li key={'type-' + type} onClick={onSelect.bind(null, type)} role="presentation">
                                    <FormattedMessage {...vehicleTypeMessages.get(type)} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VehicleTypePicker;
