import { Button, Typography } from 'antd';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import IconMarker from '../../components/icons/IconMarker';
import IconPhone from '../../components/icons/IconPhone';
import { Parking } from '../../store/api/apiTypes';
import messages from './messages';
import { useIsMobile } from '../../hooks';

export interface ParkingCardProps {
    parking: Parking;
    onSelect: (parking: Parking) => void;
}

const ParkingCard: FC<ParkingCardProps> = ({ parking, onSelect }) => {
    const isMobile = useIsMobile();
    const select = () => {
        onSelect(parking);
    };

    const mapUrl =
        parking.address && parking.city && parking.zipCode
            ? 'https://maps.google.com/?q=' + parking.address + ', ' + parking.zipCode + parking.city
            : undefined;

    return (
        <div className="parking-card">
            <div className="body">
                <div className="name">{parking.name}</div>
                <div className="address">
                    {parking.address}
                    {parking.zipCode && parking.city && (
                        <>
                            <br />
                            {parking.zipCode + ' ' + parking.city}
                        </>
                    )}
                </div>
                <div className="spots">
                    <FormattedMessage {...messages.parking_card_total_spots} values={{ count: parking.nbSpaces }} />
                </div>
                <Button type="primary" shape="round" size="large" block className="reverse" onClick={select}>
                    <FormattedMessage {...messages.parking_card_offers_cta} />
                </Button>
            </div>
            <div className="actions">
                {parking.geoCoordinate?.latitude && parking.geoCoordinate?.longitude && (
                    <a
                        href={mapUrl ?? '#map'}
                        target="_blank"
                        rel="noreferrer"
                        className={'action ' + (!mapUrl ? 'disabled' : '')}
                    >
                        <IconMarker className="text-primary" />
                        <span>
                            <FormattedMessage {...messages.parking_card_map_cta} />
                        </span>
                    </a>
                )}

                {parking.phoneNumber &&
                    (isMobile ? (
                        <a
                            href={'tel:' + (parking.phoneNumber ? parking.phoneNumber : '')}
                            className={'action ' + (!parking.phoneNumber ? 'disabled' : '')}
                        >
                            <IconPhone className="text-primary" />
                            <span>
                                <FormattedMessage {...messages.parking_card_phone_cta} />
                            </span>
                        </a>
                    ) : (
                        <Typography.Paragraph
                            className={'action ' + (!parking.phoneNumber ? 'disabled' : '')}
                            style={{ margin: 0 }}
                            copyable={{
                                text: parking.phoneNumber ? parking.phoneNumber : '',
                                icon: [
                                    <>
                                        <IconPhone className="text-primary" />
                                        {parking.phoneNumber ? parking.phoneNumber : ''}
                                    </>,
                                    <FormattedMessage key={0} {...messages.parking_card_phone_copied} />,
                                ],
                            }}
                        />
                    ))}
            </div>
        </div>
    );
};

export default ParkingCard;
