import React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { setDefaultBreakpoints, BreakpointProvider } from 'react-socks';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import IntlProvider from './components/IntlProvider';
import reportWebVitals from './reportWebVitals';
import configureStore from './store/configureStore';
import { LocaleContextProvider } from './context/LocaleContext';
import { SiteContextProvider } from './context/SiteContext';
import App from './App';

import './assets/styles/AntOverride.less';

Spin.setDefaultIndicator(<LoadingOutlined spin />);

setDefaultBreakpoints([{ xs: 0 }, { sm: 480 }, { md: 576 }, { lg: 768 }, { xl: 992 }, { xxl: 1200 }, { xxxl: 1600 }]);

const store = configureStore();

const render = (Component: React.ComponentType) => {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <SiteContextProvider>
                    <LocaleContextProvider>
                        <IntlProvider>
                            <BreakpointProvider>
                                <Component />
                            </BreakpointProvider>
                        </IntlProvider>
                    </LocaleContextProvider>
                </SiteContextProvider>
            </BrowserRouter>
        </Provider>,
        document.getElementById('root') as HTMLElement
    );
};

const init = async () => {
    const checkPolyfills = async () => {
        if (!window.IntersectionObserver) {
            await import('intersection-observer');
        }
        if (!window.URLSearchParams) {
            await import('url-search-params-polyfill');
        }
    };

    await checkPolyfills();

    render(App);
};

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
