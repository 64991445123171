import React, { FC } from 'react';
import { ModalProps } from 'antd/lib/modal';
import IconClose from './icons/IconClose';
import { Modal as AntModal } from 'antd';

const Modal: FC<ModalProps> = ({ children, ...props }) => {
    return (
        <AntModal {...props} footer={null} closeIcon={<IconClose />}>
            {children}
        </AntModal>
    );
};

export default Modal;
