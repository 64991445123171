import React, { FC, useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FormProps } from 'antd/lib/form/Form';
import { Form, Button, Alert, Row, Col, Radio } from 'antd';
import Seo from '../../components/Seo';
import { getRoute, RoutePathName } from '../../routes';
import { useActions, usePrevious } from '../../hooks';
import LoginLayout from '../../components/LoginLayout';
import Input from '../../components/form/Input';
import InputPassword from '../../components/form/InputPassword';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import formMessages from '../../locale/formMessages';
import genericMessages from '../../locale/genericMessages';
import validatePasswordRules from '../../helpers/passwords';
import ButtonLink from '../../components/ButtonLink';
import { create as createAction, getUsersCreateState } from '../../store/actions/users';
import { getAuthState, login as loginAction } from '../../store/actions/auth';
import { Civility, SocialAuthResponse, UserRole, UserType } from '../../store/api/apiTypes';
import { UserCreatePayload } from '../../store/api/users';
import ButtonFacebookLogin from '../../components/ButtonFacebookLogin';
import ButtonGoogleLogin from '../../components/ButtonGoogleLogin';
import PhoneInput from '../../components/PhoneInput';
import CountrySelect from '../../components/CountrySelect';
import { CountryData, PhoneInputProps } from 'react-phone-input-2';
import { LocaleContext } from '../../context/LocaleContext';

const Register: FC = () => {
    const { locale } = useContext(LocaleContext);
    const { siteId } = useParams<{ siteId: string }>();
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [createUser] = useActions([createAction.trigger]);
    const login = useActions(loginAction.trigger);
    const authState = useSelector(getAuthState);
    const userCreateState = useSelector(getUsersCreateState);
    const [formValues, setFormValues] = useState<any>();
    const [socialAuthValue, setSocialAuthValue] = useState<SocialAuthResponse>();
    const [success, setSuccess] = useState<boolean>(false);
    const [connexion, setConnexion] = useState<boolean>(false);
    const previous = usePrevious({
        userCreateState,
        authState,
    });

    const onFormValidSubmit: FormProps['onFinish'] = (user: UserCreatePayload) => {
        user.siteId = siteId;
        user.addresses = [user.address];
        user.defaultAddress = user.address;
        user.role = UserRole.customer;
        user.phone = `+${user.phone}`;

        if (socialAuthValue?.goToken) user.goToken = socialAuthValue?.goToken;
        if (socialAuthValue?.fbId) {
            user.fbId = socialAuthValue?.fbId;
            user.fbToken = socialAuthValue?.fbToken;
        }
        createUser(user);
    };

    const onSocialLogin = (infos: SocialAuthResponse) => {
        setSocialAuthValue(infos);
        form.setFieldsValue(infos);
    };

    const onFormValueChange: FormProps['onValuesChange'] = (changes, values) => {
        setFormValues(values);
    };

    const onChangePhoneInput: PhoneInputProps['onChange'] = (value, countryData) => {
        form.setFields([
            {
                name: ['address', 'country'],
                value: (countryData as CountryData).countryCode.toUpperCase(),
            },
        ]);
    };

    useEffect(() => {
        if (previous?.userCreateState.loading && !userCreateState.loading) {
            if (userCreateState.success) {
                if (socialAuthValue?.fbId ?? socialAuthValue?.goToken) {
                    login(socialAuthValue);
                } else {
                    setSuccess(true);
                }
            }
        }
    }, [login, previous, socialAuthValue, userCreateState]);

    useEffect(() => {
        if (previous?.authState.loading && !authState.loading) {
            if (authState.isConnected) {
                setSuccess(true);
                setConnexion(true);
            }
        }
    }, [authState.loading, authState, login, previous, socialAuthValue, userCreateState]);

    let error = userCreateState.error ? formatMessage(messages.error_default) : null;

    if (userCreateState.error?.data?.error_code) {
        switch (userCreateState.error?.data?.error_code) {
            case 'email_already_used':
                error = formatMessage(messages.error_email_already_used);
                break;
        }
    }

    return (
        <LoginLayout>
            <Seo title="Inscription" />
            <Form
                form={form}
                className="login-form"
                onValuesChange={onFormValueChange}
                onFinish={onFormValidSubmit}
                layout="vertical"
                requiredMark={false}
                scrollToFirstError={true}
            >
                <h1 className="page-title">
                    {!connexion ? (
                        <FormattedMessage {...messages.title} />
                    ) : (
                        <FormattedMessage {...messages.title_social} />
                    )}
                </h1>

                {!success ? (
                    <>
                        <p className="page-intro">
                            <FormattedMessage {...messages.already_account} />{' '}
                            <Link to={getRoute(RoutePathName.login)}>
                                <FormattedMessage {...messages.login} />
                            </Link>
                        </p>

                        {!socialAuthValue && (
                            <>
                                <ButtonFacebookLogin onSuccess={onSocialLogin} />
                                <ButtonGoogleLogin onSuccess={onSocialLogin} />

                                <div className="separator">
                                    <span>
                                        <FormattedMessage {...genericMessages.or} />
                                    </span>
                                </div>
                            </>
                        )}

                        <h2 className="sub-title mb-1">
                            <FormattedMessage {...messages.your_infos} />
                        </h2>

                        <Form.Item
                            label={formatMessage(formMessages.user_type_label)}
                            className="radio-inline-label"
                            name="userType"
                            rules={[{ required: true, message: formatMessage(formMessages.email_error) }]}
                        >
                            <Radio.Group buttonStyle="solid">
                                <Radio.Button value={UserType.individual}>
                                    <FormattedMessage {...formMessages.user_type_individual} />
                                </Radio.Button>
                                <Radio.Button value={UserType.company}>
                                    <FormattedMessage {...formMessages.user_type_company} />
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label={formatMessage(formMessages.civility_label)}
                            className="radio-inline-label"
                            name="civility"
                            rules={[{ required: true, message: formatMessage(formMessages.email_error) }]}
                        >
                            <Radio.Group buttonStyle="solid">
                                <Radio.Button value={Civility.missus}>
                                    <FormattedMessage {...formMessages.civility_mme} />
                                </Radio.Button>
                                <Radio.Button value={Civility.mister}>
                                    <FormattedMessage {...formMessages.civility_mr} />
                                </Radio.Button>
                                {locale === 'en' && (
                                    <Radio.Button value={Civility.ms}>
                                        <FormattedMessage {...formMessages.civility_ms} />
                                    </Radio.Button>
                                )}
                                <Radio.Button value={Civility.miss}>
                                    <FormattedMessage {...formMessages.civility_mlle} />
                                </Radio.Button>
                                <Radio.Button value={Civility.other}>
                                    <FormattedMessage {...formMessages.civility_autre} />
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        <Row gutter={15}>
                            <Col span={12}>
                                <Form.Item
                                    name="firstName"
                                    rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                                >
                                    <Input
                                        disabled={!!socialAuthValue}
                                        placeholder={formatMessage(formMessages.firstname_label)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="lastName"
                                    rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                                >
                                    <Input
                                        disabled={!!socialAuthValue}
                                        placeholder={formatMessage(formMessages.lastname_label)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: formatMessage(formMessages.email_error) }]}
                        >
                            <Input disabled={!!socialAuthValue} placeholder={formatMessage(formMessages.email_label)} />
                        </Form.Item>

                        {!socialAuthValue && (
                            <Form.Item
                                name="password"
                                rules={[
                                    { required: true, message: formatMessage(formMessages.error_required) },
                                    {
                                        validator: async (_, value) => {
                                            if (!value || !validatePasswordRules(value)) {
                                                return await Promise.resolve();
                                            }
                                            return await Promise.reject(
                                                new Error(formatMessage(formMessages.password_invalid))
                                            );
                                        },
                                    },
                                ]}
                            >
                                <InputPassword
                                    placeholder={formatMessage(formMessages.password_label)}
                                    withInstruction={true}
                                />
                            </Form.Item>
                        )}

                        <Form.Item name="phone">
                            <PhoneInput
                                placeholder={formatMessage(formMessages.phone_label)}
                                onChange={onChangePhoneInput}
                                geolocationAutoFill
                            />
                        </Form.Item>

                        {formValues && formValues.userType === UserType.company && (
                            <>
                                <h2 className="sub-title mb-1 mt-2">
                                    <FormattedMessage {...messages.your_infos} />
                                </h2>

                                <Form.Item
                                    name={['companyName']}
                                    rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                                >
                                    <Input placeholder={formatMessage(formMessages.company_name_label)} />
                                </Form.Item>

                                <Form.Item
                                    name={['siret']}
                                    rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                                >
                                    <Input placeholder={formatMessage(formMessages.company_siret_label)} />
                                </Form.Item>

                                <Form.Item
                                    name={['vatCode']}
                                    rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                                >
                                    <Input placeholder={formatMessage(formMessages.company_vat_label)} />
                                </Form.Item>

                                <Form.Item name={['textArea']}>
                                    <Input placeholder={formatMessage(formMessages.company_info_label)} />
                                </Form.Item>
                            </>
                        )}

                        <h2 className="sub-title mb-1 mt-2">
                            <FormattedMessage {...messages.your_address} />
                        </h2>

                        <Form.Item
                            name={['address', 'street']}
                            rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                        >
                            <Input placeholder={formatMessage(formMessages.address_label)} />
                        </Form.Item>

                        <Form.Item name={['address', 'complement']} rules={[{ required: false }]}>
                            <Input placeholder={formatMessage(formMessages.address_2_label)} />
                        </Form.Item>

                        <Row gutter={15}>
                            <Col span={12}>
                                <Form.Item
                                    name={['address', 'zipCode']}
                                    rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                                >
                                    <Input placeholder={formatMessage(formMessages.zipcode_label)} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={['address', 'city']}
                                    rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                                >
                                    <Input placeholder={formatMessage(formMessages.city_label)} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item
                            name={['address', 'country']}
                            rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                        >
                            <CountrySelect placeholder={formatMessage(formMessages.country_label)} />
                        </Form.Item>

                        <div
                            className="legals-optins text-sm mb-2"
                            dangerouslySetInnerHTML={{
                                __html: formatMessage(messages.rgpd_legals, {
                                    cgu:
                                        `<a href="${getRoute(RoutePathName.legals, { slug: 'cgu' })}">` +
                                        formatMessage(messages.rgpd_legals_cgu) +
                                        '</a>',
                                    cgv:
                                        `<a href="${getRoute(RoutePathName.legals, { slug: 'cgv' })}">` +
                                        formatMessage(messages.rgpd_legals_privacy) +
                                        '</a>',
                                }),
                            }}
                        />

                        <Form.Item>
                            {error ? (
                                <div className="login-error-message">
                                    <Alert type="error" message={error} showIcon />
                                </div>
                            ) : null}

                            <Button
                                type="primary"
                                shape="round"
                                size="large"
                                block
                                htmlType="submit"
                                loading={userCreateState.loading}
                            >
                                <FormattedMessage {...messages.register_cta} />
                            </Button>
                        </Form.Item>

                        <div className="text-light text-sm">
                            <FormattedMessage {...formMessages.all_fields_required_except} />
                        </div>
                    </>
                ) : (
                    <>
                        <p className="mb-2 mt-2">
                            {socialAuthValue?.fbId ?? socialAuthValue?.goToken ? (
                                <FormattedMessage {...messages.success_text_social} />
                            ) : (
                                <FormattedMessage {...messages.success_text} values={{ email: formValues.email }} />
                            )}
                        </p>

                        <ButtonLink
                            to={getRoute(RoutePathName.home)}
                            type="ghost"
                            shape="round"
                            size="large"
                            className="border-primary"
                        >
                            <FormattedMessage {...genericMessages.back_homepage} />
                        </ButtonLink>
                    </>
                )}
            </Form>
        </LoginLayout>
    );
};

export default Register;
