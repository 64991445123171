import React, { FC, useContext, useEffect } from 'react';
import Seo from '../../components/Seo';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import ButtonBack from '../../components/ButtonBack';
import { SiteContext } from '../../context/SiteContext';
import { useHistory } from 'react-router-dom';
import { PaylineWidget } from 'react-payline';
import '../../assets/styles/Payment.less';
import { PSPName } from '../../store/api/apiTypes';
import Iframe from 'react-iframe';
import constants from '../../config/constants';
declare global {
    interface Window {
        RealexHpp?: any;
    }
}

const Payment: FC = () => {
    const history = useHistory();
    const { formatMessage } = useIntl();
    const { transaction } = useContext(SiteContext);

    const Payline = (window as any).Payline;
    if (!transaction) {
        history.goBack();
    }

    const advamUri =
        constants.ADVAM_IFRAME_URL +
        '?SessionId=' +
        encodeURI(transaction?.paymentToken ? transaction.paymentToken : '');

    useEffect(() => {
        if (transaction?.globalPaymentTransactionResult !== undefined && transaction?.returnUrl !== undefined) {
            console.log('transaction');
            window.RealexHpp.setHppUrl('https://pay.sandbox.realexpayments.com/pay');
            window.RealexHpp.embedded.init(
                'autoload',
                'globalPaymentIframe',
                transaction.returnUrl,
                transaction.globalPaymentTransactionResult
                // {
                //     onResize:function(data: any){
                // 		$('#globalPaymentIframe').css(data)
                //     }
                // }
            );
        }
    }, [transaction]);
    return (
        <>
            <Seo title={formatMessage(messages.payment)} />
            <div id="payment">
                <div className="wrapper mt-3 mb-3">
                    <ButtonBack label={formatMessage(messages.back)} />

                    <h1 className="title title-1 larger mb-1 mt-2">
                        <FormattedMessage {...messages.title} />
                    </h1>

                    <p>
                        <FormattedMessage {...messages.intro} />
                    </p>

                    <div className="card text-center mt-1">
                        {Payline && transaction?.psp === PSPName.payline && transaction?.paymentToken && (
                            <PaylineWidget
                                token={transaction.paymentToken}
                                template="column"
                                embeddedRedirectionAllowed={false}
                            />
                        )}
                        {transaction?.psp === PSPName.advam && transaction?.paymentToken && (
                            <Iframe url={advamUri} width="100%" height="450px" position="relative" />
                        )}
                        {transaction?.psp === PSPName.globalPayment && transaction?.globalPaymentTransactionResult && (
                            <iframe id="globalPaymentIframe" title="globalPaymentIframe" />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Payment;
