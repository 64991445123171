import { Button } from 'antd';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import formMessages from '../locale/formMessages';
import { constants } from '../helpers';
import { LoginSocialGoogle } from 'reactjs-social-login';

import iconGoogle from '../assets/images/icon-google.svg';
import { SocialAuthResponse } from '../store/api/apiTypes';

interface ButtonGoogleLoginProps {
    onSuccess?: (infos: SocialAuthResponse) => void;
}

const ButtonGoogleLogin: FC<ButtonGoogleLoginProps> = ({ onSuccess }) => {
    const response = (e: any) => {
        console.log('Google infos', e);

        if (onSuccess) {
            if (e?.id_token) {
                onSuccess({
                    goToken: e.id_token,
                    email: e.email,
                    firstName: e.firstName,
                    lastName: e.lastName,
                });
            }
        }
    };

    return (
        <LoginSocialGoogle
            client_id={constants.GOOGLE_ID}
            onResolve={({ data }: any) => {
                response(data);
            }}
            onReject={(err: any) => response(err)}
        >
            <Button type="ghost" shape="round" size="large" block htmlType="button">
                <img className="icon" src={iconGoogle} alt="Google Signin" />
                <FormattedMessage {...formMessages.google_signin} />
            </Button>
        </LoginSocialGoogle>
    );
};

export default ButtonGoogleLogin;
