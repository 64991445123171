import { Button } from 'antd';
import React, { FC, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import ButtonLink from '../../../components/ButtonLink';
import Modal from '../../../components/Modal';
import VehicleIcon from '../../../components/VehicleIcon';
import accountMessages from '../../../locale/accountMessages';
import { getRoute, RoutePathName } from '../../../routes';
import { Title } from '../../../store/api/apiTypes';
import { receipt as getReceiptAction } from '../../../store/actions/users';
import { useActions } from '../../../hooks';
import { downloadBase64Image } from '../../../helpers';
import { getSiteState } from '../../../store/actions/sites';
import { useSelector } from 'react-redux';

export interface SubscriptionsProps {
    title: Title;
}

const SubscriptionCard: FC<SubscriptionsProps> = ({ title }) => {
    const [isBadgeModalVisible, setIsBadgeModalVisible] = useState(false);
    const site = useSelector(getSiteState);

    const [getReceipt] = useActions([getReceiptAction.trigger]);

    const openModal = () => {
        setIsBadgeModalVisible(true);
    };

    const closeModal = () => {
        setIsBadgeModalVisible(false);
    };

    const downloadReceipt = () => {
        getReceipt({ id: title.transaction?.id });
    };

    const downloadBadge = () => {
        if (title.barcode) {
            downloadBase64Image(title.barcode, 'badge', 'image/png');
        }
    };

    return (
        <>
            <div className="card subscription-card">
                <div className="type">
                    <VehicleIcon type={title.offer?.vehicleClass} />
                </div>
                <div className="content">
                    <div className="informations">
                        <div className="details">
                            <div className="name">{title.offer?.offerName}</div>
                            {title?.offer?.zones?.map((zone, index) => (
                                <div className="parking" key={'offer-zone-' + index.toString()}>
                                    {zone.parkingName} - {zone.zoneName}
                                </div>
                            ))}
                            <div className="separator" />
                            <div className="user">
                                {title.handler?.firstName} {title.handler?.name}
                            </div>
                            <div className="info-row">
                                <span className="label text-primary">
                                    <FormattedMessage {...accountMessages.card_number_label} />
                                </span>{' '}
                                {title.titleNumber}
                            </div>
                            {!!title.licensePlates?.length && (
                                <div className="info-row">
                                    <span className="label text-primary">
                                        <FormattedMessage {...accountMessages.licenses_plates_label} />
                                    </span>{' '}
                                    {title.licensePlates?.join(', ')}
                                </div>
                            )}
                        </div>
                        <div className="validity">
                            <div className="validity-period">
                                <div className="label text-primary">
                                    <FormattedMessage {...accountMessages.validity} />
                                </div>
                                <FormattedMessage
                                    {...accountMessages.validity_dates}
                                    values={{
                                        from: <FormattedDate value={title?.beginValidityDate} />,
                                        to: <FormattedDate value={title?.endValidityDate} />,
                                    }}
                                />
                            </div>

                            {title.canRenew && title.id && (
                                <ButtonLink
                                    to={getRoute(RoutePathName.renewSubscription, { titleId: title.id })}
                                    type="primary"
                                    shape="round"
                                    className="mt-1"
                                    size="large"
                                    block
                                    disabled={!site?.enabled}
                                >
                                    <FormattedMessage {...accountMessages.renew} />
                                </ButtonLink>
                            )}
                        </div>
                    </div>
                    <div className="actions">
                        <Button type="ghost" className="dark" size="small" shape="round" onClick={downloadReceipt}>
                            <FormattedMessage {...accountMessages.download_invoice} />
                        </Button>
                        {title.barcode && (
                            <Button type="ghost" className="dark" size="small" shape="round" onClick={openModal}>
                                <FormattedMessage {...accountMessages.your_dematerialized_badge} />
                            </Button>
                        )}
                    </div>
                </div>
            </div>

            <Modal visible={isBadgeModalVisible} onCancel={closeModal}>
                <div className="modal-title text-center">
                    <FormattedMessage {...accountMessages.badge_modal_title} />
                </div>

                <div className="modal-body text-center">
                    {title.barcode && (
                        <Button onClick={downloadBadge} type="primary" size="large" shape="round" className="mt-2">
                            <FormattedMessage {...accountMessages.download_badge} />
                        </Button>
                    )}
                    {/*
                    <p className="mt-2 mb-2">
                        <strong>
                            <FormattedMessage {...accountMessages.download_badge_alternatives} />
                        </strong>
                    </p>
                    [Apple Wallet] [GPay]
                    */}
                </div>
            </Modal>
        </>
    );
};

export default SubscriptionCard;
