import {
    CalendarOutlined,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    LeftOutlined,
    RightOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Breakpoint } from 'react-socks';
import messages from './messages';
import { FormattedDate, FormattedMessage } from 'react-intl';
import genericMessages from '../../locale/genericMessages';
import Calendar from '../../components/Calendar';
import dayjs, { Dayjs } from 'dayjs';

import '../../assets/styles/CustomPicker.less';

interface SubscriptionDatePickerProps {
    onChange?: (value?: Dayjs) => void;
    value?: Dayjs;
}

const SubscriptionDatePicker: FC<SubscriptionDatePickerProps> = (props) => {
    const container: any = useRef();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [value, setValue] = useState<Dayjs>();

    useEffect(() => {
        if (props.onChange) {
            if (typeof props.value === 'string' || props.value instanceof String) {
                setValue(dayjs(props.value));
            } else {
                setValue(props.value);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    // ---------------------------------
    // On Date change

    const onDateSelect = (date: dayjs.Dayjs) => {
        setIsOpen(false);

        if (props.onChange) {
            props.onChange(date);
        }
    };

    // ---------------------------------
    // Close search on click outside

    const handleClick = (e: any) => {
        if (!container.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------
    // Render

    return (
        <div className={'subscription-date-picker custom-picker ' + (value ? 'has-value' : '')} ref={container}>
            <div className="btn-open" onClick={setIsOpen.bind(null, true)} aria-hidden="true">
                <div className="content">
                    <Breakpoint lg down>
                        <div className="value">
                            {value ? (
                                <FormattedDate value={value.format()} />
                            ) : (
                                <span className="placeholder">
                                    <FormattedMessage {...messages.subscription_picker_label} />
                                </span>
                            )}
                        </div>
                    </Breakpoint>
                    <Breakpoint xl up>
                        <div className="label">
                            <FormattedMessage {...messages.subscription_picker_label} />
                        </div>
                        <div className="value">
                            {value ? (
                                <FormattedDate value={value.format()} />
                            ) : (
                                <span className="placeholder">
                                    <FormattedMessage {...messages.subscription_picker_placeholder} />
                                </span>
                            )}
                        </div>
                    </Breakpoint>
                </div>
                <CalendarOutlined />
            </div>

            {isOpen && (
                <div className="custom-picker-overlay">
                    <div className="head">
                        <span className="head-title">
                            <FormattedMessage {...messages.subscription_picker_label} />
                        </span>
                        <Button type="text" className="btn-cancel text-primary" onClick={setIsOpen.bind(null, false)}>
                            <FormattedMessage {...genericMessages.cancel} />
                        </Button>
                    </div>
                    <div className="results-list">
                        <Calendar
                            fullscreen={false}
                            dateCellRender={(value) => {
                                return (
                                    <div
                                        className="date-select"
                                        onClick={onDateSelect.bind(null, value)}
                                        role="presentation"
                                    />
                                );
                            }}
                            headerRender={({ value, onChange }) => {
                                const prevMonth = () => {
                                    const newDate = value.clone();
                                    onChange(newDate.subtract(1, 'month'));
                                };

                                const nextMonth = () => {
                                    const newDate = value.clone();
                                    onChange(newDate.add(1, 'month'));
                                };

                                const prevYear = () => {
                                    const newDate = value.clone();
                                    onChange(newDate.subtract(1, 'year'));
                                };

                                const nextYear = () => {
                                    const newDate = value.clone();
                                    onChange(newDate.add(1, 'year'));
                                };

                                return (
                                    <div className="calendar-header">
                                        <div>
                                            <Button type="text" onClick={prevYear}>
                                                <DoubleLeftOutlined />
                                            </Button>
                                            <Button type="text" onClick={prevMonth}>
                                                <LeftOutlined />
                                            </Button>
                                        </div>
                                        {value.format('MMM YYYY')}
                                        <div>
                                            <Button type="text" onClick={nextMonth}>
                                                <RightOutlined />
                                            </Button>
                                            <Button type="text" onClick={nextYear}>
                                                <DoubleRightOutlined />
                                            </Button>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default SubscriptionDatePicker;
