import React, { FC, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { addGoogleTagManager, analytics } from '../helpers/cookies';
import { LocaleContext } from '../context/LocaleContext';
declare global {
    interface Window {
        axeptioSDK?: any;
        _axcb: any;
    }
}
const Axeptio: FC = () => {
    const location = useLocation();
    const { locale } = useContext(LocaleContext);
    const cookieVersion = `Version_1_` + locale.toLocaleUpperCase();

    useEffect(() => {
        addGoogleTagManager();
    }, []);

    useEffect(() => {
        if (window.dataLayer && location) {
            analytics.pageView(location.pathname);
        }
    }, [location, location.pathname]);

    // useEffect(() => {
    //     window.axeptioSDK?.setCookiesVersion(cookieVersion);
    // }, [cookieVersion]);

    return (
        <Helmet>
            <script>
                {`
                    window.axeptioSettings = {
                        clientId: "60e85e8d32c45b44ff5caa85",
                        cookiesVersion: "${cookieVersion}"
                    };
                    
                    (function(d, s) {
                        var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
                        e.async = true; e.src = "//static.axept.io/sdk-slim.js";
                        t.parentNode.insertBefore(e, t);
                    })(document, "script");

                    void 0 === window._axcb && (window._axcb = []);
                    window._axcb.push(function(axeptio) {
                        axeptio.on('consent:saved', function(choices) { 
                            window.location.reload();
                        });
                    });
                `}
            </script>
        </Helmet>
    );
};

export default Axeptio;
