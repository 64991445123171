import * as React from 'react';
import { useIntl } from 'react-intl';

interface PriceProps {
    value?: number;
    currency?: string;
    currencyCentsDigits?: number;
    suffix?: string;
}

const Price: React.FC<PriceProps> = ({ value, currency, currencyCentsDigits, suffix }) => {
    const { locale } = useIntl();

    return value !== undefined ? (
        <span className="price-tag">
            {Intl.NumberFormat(locale, {
                style: 'currency',
                currency: currency?.trim(),
            }).format(value / Math.pow(10, currencyCentsDigits ?? 2))}
            {suffix && ' ' + suffix}
        </span>
    ) : null;
};

Price.defaultProps = {
    currency: 'EUR',
    currencyCentsDigits: 2,
};

export default Price;
