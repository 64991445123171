import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import genericMessages from '../locale/genericMessages';

interface ButtonBackProps {
    label?: string;
}

const ButtonBack: FC<ButtonBackProps> = ({ label }) => {
    const history = useHistory();

    const back = () => {
        history.goBack();
    };

    return (
        <button onClick={back} className="btn-back">
            <ArrowLeftOutlined className="text-primary" />
            <span className="font-title">{label ?? <FormattedMessage {...genericMessages.back} />}</span>
        </button>
    );
};

export default ButtonBack;
