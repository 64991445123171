import React, { FC, useEffect, useState } from 'react';
import { Alert, Button, Form, FormProps, message, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import accountMessages from '../../../locale/accountMessages';
import Seo from '../../../components/Seo';
import formMessages from '../../../locale/formMessages';
import InputPassword from '../../../components/form/InputPassword';
import validatePasswordRules from '../../../helpers/passwords';
import { useActions, usePrevious } from '../../../hooks';
import { useSelector } from 'react-redux';
import { update as updateAction, getUsersUpdateState } from '../../../store/actions/users';
import { getAuthState } from '../../../store/actions/auth';
import { UserCreatePayload } from '../../../store/api/users';

const Password: FC = () => {
    const { formatMessage } = useIntl();
    const [form] = Form.useForm();
    const [updateUser] = useActions([updateAction.trigger]);
    const userUpdateState = useSelector(getUsersUpdateState);
    const [userId, setUserId] = useState<string>();
    const authState = useSelector(getAuthState);

    const previous = usePrevious({
        userUpdateState,
    });

    useEffect(() => {
        setUserId(authState.user?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFormValidSubmit: FormProps['onFinish'] = (user: UserCreatePayload) => {
        updateUser({
            id: userId,
            body: user,
        });
    };

    useEffect(() => {
        if (previous?.userUpdateState.loading && !userUpdateState.loading) {
            if (userUpdateState.success) {
                message.success(formatMessage(formMessages.update_success));
            }
        }
    }, [previous, userUpdateState, formatMessage]);

    let error = userUpdateState.error ? formatMessage(formMessages.error_default) : null;

    if (userUpdateState.error?.data?.message) {
        switch (userUpdateState.error?.data?.message) {
            case 'Old password does not match':
                error = formatMessage(formMessages.error_old_password_not_match);
                break;
        }
    }

    return (
        <>
            <Seo title={formatMessage(accountMessages.your_informations)} />
            <Form
                form={form}
                onFinish={onFormValidSubmit}
                className="password-form"
                layout="vertical"
                requiredMark={false}
            >
                <div className="header mb-2">
                    <Typography.Title level={1} className="title title-1">
                        <FormattedMessage {...accountMessages.your_password} />
                    </Typography.Title>
                    <p>
                        <FormattedMessage {...accountMessages.password_intro} />
                    </p>
                </div>

                <Form.Item
                    name="oldPassword"
                    rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                >
                    <InputPassword
                        placeholder={formatMessage(formMessages.current_password_label)}
                        withInstruction={false}
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[
                        { required: true, message: formatMessage(formMessages.error_required) },
                        {
                            validator: async (_, value) => {
                                if (!value || !validatePasswordRules(value)) {
                                    return await Promise.resolve();
                                }
                                return await Promise.reject(new Error(formatMessage(formMessages.password_invalid)));
                            },
                        },
                    ]}
                >
                    <InputPassword
                        placeholder={formatMessage(formMessages.new_password_label)}
                        withInstruction={true}
                    />
                </Form.Item>

                {error ? (
                    <div className="login-error-message">
                        <Alert type="error" message={error} showIcon />
                    </div>
                ) : null}

                <div className="flex-column-center lg-flex-row cta-container mt-2">
                    <Form.Item className="mb-0">
                        <Button type="primary" shape="round" size="large" block htmlType="submit" className="btn-xl">
                            <FormattedMessage {...formMessages.cta_update_password} />
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </>
    );
};

export default Password;
