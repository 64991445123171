import { message, Spin } from 'antd';
import axios from 'axios';
import React, { FC, useContext, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import ReactPhoneInput, { PhoneInputProps as ReactPhoneInputProps } from 'react-phone-input-2';

import '../assets/styles/ReactPhoneInput2.less';
import { LocaleContext } from '../context/LocaleContext';
import { Language } from '../types';

type ReverseGeocodingApiResponse = Array<{
    name: string;
    local_names: {
        [key: string]: string;
    };
    lat: number;
    lon: number;
    country: string;
}>;

const storedCountry = localStorage.getItem('country') ?? undefined;

const messages = defineMessages({
    geolocationError: {
        id: 'phone_input.geolocation_error.message',
        defaultMessage: 'La géolocalisation permet de préremplir le préfix du champ téléphone',
    },
});

const checkGBLocale = (locale: string) => {
    return locale === 'en' ? 'gb' : locale;
};

interface LangImport {
    [lang: string]: any;
}

const availableLangs = ['fr', 'es'];

const intlData = availableLangs.reduce<LangImport>(
    (acc, lang) => ({
        ...acc,
        [lang]: async () => await import(`react-phone-input-2/lang/${lang}.json`),
    }),
    {}
);

interface PhoneInputProps extends ReactPhoneInputProps {
    geolocationAutoFill?: boolean;
}

const PhoneInput: FC<PhoneInputProps> = ({ geolocationAutoFill, placeholder, ...props }) => {
    const { formatMessage } = useIntl();
    const { locale } = useContext(LocaleContext);
    const [countryCode, setCountryCode] = useState<string | undefined>(storedCountry);
    const [localization, setLocalization] = useState();
    const [isRequestingCountry, setIsRequestingCountry] = useState(false);

    useEffect(() => {
        if (availableLangs.includes(locale)) {
            (async () => {
                const localeData = await intlData[locale]?.();
                setLocalization(localeData);
            })();
        }
    }, [locale]);

    useEffect(() => {
        if (geolocationAutoFill && !props.value && !countryCode) {
            setIsRequestingCountry(true);
            navigator.geolocation.getCurrentPosition(
                (geolocation) => {
                    axios
                        .get<ReverseGeocodingApiResponse>(
                            `https://api.openweathermap.org/geo/1.0/reverse?lat=${geolocation.coords.latitude}&lon=${geolocation.coords.longitude}&limit=1&appid=252b1bb5a1f0b94f67e0a82b37afa4e5`
                        )
                        .then((response) => {
                            const country = response.data?.[0]?.country.toLowerCase();
                            if (country) {
                                setCountryCode(country); // geocoding API returns GB for united kingdom so we don't use checkGBLocale
                                localStorage.setItem('country', country);
                            } else {
                                setCountryCode(checkGBLocale(locale));
                            }
                        })
                        .catch(() => {
                            setCountryCode(checkGBLocale(locale));
                        })
                        .finally(() => {
                            setIsRequestingCountry(false);
                        });
                },
                () => {
                    setIsRequestingCountry(false);
                    setCountryCode(checkGBLocale(locale));
                    message.info(formatMessage(messages.geolocationError));
                }
            );
        }
    }, [locale, formatMessage, geolocationAutoFill, props.value, countryCode]);

    return localization || locale === Language.en ? (
        <div className={'input-float-label phone-input ' + (!props.value && !countryCode ? 'empty' : 'not-empty')}>
            <label className="float-label">{placeholder}</label>
            <Spin spinning={isRequestingCountry}>
                <ReactPhoneInput
                    {...props}
                    inputClass="ant-input"
                    localization={localization}
                    preferredCountries={['fr', 'gb', 'es']}
                    country={countryCode}
                    specialLabel=""
                    placeholder=""
                />
            </Spin>
        </div>
    ) : null;
};

export default PhoneInput;
