import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 25 30">
        <path
            fill="currentColor"
            d="M26.9748334,3.08085041 C27.6750555,3.83993659 27.6750555,5.08798252 26.9748334,5.8470687 L10.561383,24.4348538 C9.90057883,25.1909862 8.78936076,25.1909862 8.07733794,24.4191073 L0.539364614,15.8991797 C-0.177510958,15.0762821 -0.177510958,13.8120981 0.525798329,13.0496652 C1.20081395,12.2777863 2.31203202,12.2777863 3.02405484,13.0496652 L9.35574847,20.1897521 L24.4904937,3.06543745 C25.1515925,2.30897145 26.2628106,2.30897145 26.9748334,3.08085041 Z"
        ></path>
    </svg>
);

const IconCheck: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-check${props.className ? ` ${props.className}` : ''}`} />
);

export default IconCheck;
