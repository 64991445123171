import { Alert, Button, Form } from 'antd';
import { FormProps } from 'antd/lib/form/Form';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Redirect, useLocation } from 'react-router-dom';
import ButtonFacebookLogin from '../../components/ButtonFacebookLogin';
import ButtonGoogleLogin from '../../components/ButtonGoogleLogin';
import Input from '../../components/form/Input';
import InputPassword from '../../components/form/InputPassword';
import LoginLayout from '../../components/LoginLayout';
import Seo from '../../components/Seo';
import { useActions } from '../../hooks';
import formMessages from '../../locale/formMessages';
import genericMessages from '../../locale/genericMessages';
import { getRoute, RoutePathName } from '../../routes';
import { getAuthState, login as loginAction } from '../../store/actions/auth';
import { SocialAuthResponse } from '../../store/api/apiTypes';
import messages from './messages';

const Login: FC = () => {
    const { formatMessage } = useIntl();
    const login = useActions(loginAction.trigger);
    const authState = useSelector(getAuthState);
    const location = useLocation<{ redirect?: string }>();

    const redirectUrl = location?.state?.redirect ? location.state.redirect : getRoute(RoutePathName.parkings);

    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        login(values);
    };

    const onSocialLogin = (infos: SocialAuthResponse) => {
        login(infos);
    };

    if (authState.isConnected) {
        return <Redirect to={redirectUrl} />;
    }

    let error = authState.error ? formatMessage(messages.error_default) : null;

    if (authState.error?.status) {
        switch (authState.error.status) {
            case 401:
                error = formatMessage(messages.invalid_password);
                break;

            case 403:
                error = formatMessage(messages.account_not_validated);
                break;

            case 404:
                error = formatMessage(messages.user_not_found);
                break;

            default:
                error = formatMessage(messages.error_default);
                break;
        }
    }

    return (
        <LoginLayout>
            <Seo title="Connexion" />
            <Form className="login-form" onFinish={onFormValidSubmit} layout="vertical" requiredMark={false}>
                <h1 className="page-title">
                    <FormattedMessage {...messages.login_title} />
                </h1>

                <p className="page-intro">
                    <FormattedMessage {...messages.no_account_yet} />{' '}
                    <Link to={getRoute(RoutePathName.register)}>
                        <FormattedMessage {...messages.register} />
                    </Link>
                </p>

                <Form.Item
                    name="username"
                    rules={[{ required: true, message: formatMessage(formMessages.email_error) }]}
                >
                    <Input placeholder={formatMessage(formMessages.username_label)} />
                </Form.Item>

                <Form.Item
                    className="password-row"
                    name="password"
                    rules={[{ required: true, message: formatMessage(formMessages.password_error) }]}
                >
                    <InputPassword placeholder={formatMessage(formMessages.password_label)} />
                </Form.Item>

                <Link to={getRoute(RoutePathName.forgottenPassword)} className="btn-forgot-password">
                    <FormattedMessage {...messages.forgot_password} />
                </Link>

                <Form.Item>
                    {error ? (
                        <div className="login-error-message">
                            <Alert type="error" message={error} showIcon />
                        </div>
                    ) : null}

                    <Button
                        type="primary"
                        shape="round"
                        size="large"
                        block
                        htmlType="submit"
                        loading={authState.loading}
                    >
                        <FormattedMessage {...messages.login_cta} />
                    </Button>
                </Form.Item>

                <div className="separator">
                    <span>
                        <FormattedMessage {...genericMessages.or} />
                    </span>
                </div>

                <ButtonFacebookLogin onSuccess={onSocialLogin} />
                <ButtonGoogleLogin onSuccess={onSocialLogin} />
            </Form>
        </LoginLayout>
    );
};

export default Login;
