import React, { FC, useContext, useEffect } from 'react';
import { Alert, Divider, Skeleton, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import accountMessages from '../../../locale/accountMessages';
import Seo from '../../../components/Seo';
import genericMessages from '../../../locale/genericMessages';
import { SiteContext } from '../../../context/SiteContext';
import SubscriptionCard from './SubscriptionCard';
import {
    titles as getTitlesAction,
    getTitlesState,
    getReceiptState,
    transactionPendingList as getTransactionAction,
    getTransactionPendingListState,
} from '../../../store/actions/users';
import { useActions, usePrevious } from '../../../hooks';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { TransactionValidationStatus } from '../../../store/api/apiTypes';
import TransactionCard from './TransactionCard';

const Subscriptions: FC = () => {
    const { formatMessage } = useIntl();
    const { siteId } = useContext(SiteContext);
    const [getTitles] = useActions([getTitlesAction.trigger]);
    const titlesState = useSelector(getTitlesState);
    const [getPendingTransaction] = useActions([getTransactionAction.trigger]);
    const pendingTransactionState = useSelector(getTransactionPendingListState);
    const receipt = useSelector(getReceiptState);

    const previous = usePrevious({
        receipt,
    });

    const activeTitles = titlesState?.data
        ? titlesState.data.filter((title) => dayjs(title.info?.offerPrice?.endValidityDate) >= dayjs())
        : [];

    const expiredTitles = titlesState?.data
        ? titlesState.data.filter((title) => dayjs(title.info?.offerPrice?.endValidityDate) < dayjs())
        : [];

    const pendingTransactions = pendingTransactionState?.data?.items ? pendingTransactionState.data.items : [];

    const totalTitles = activeTitles.length + expiredTitles.length + pendingTransactions.length;

    useEffect(() => {
        getTitles({
            siteId: siteId,
        });
        getPendingTransaction({
            validationStatus: TransactionValidationStatus.pending,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (previous?.receipt.loading && !receipt.loading) {
            if (receipt.success && receipt.data) {
                const fileURL = URL.createObjectURL(receipt.data);
                window.open(fileURL);
            }
        }
    }, [previous, receipt]);

    return (
        <>
            <Seo title={formatMessage(accountMessages.subscriptions)} />
            <div className="subscriptions-list">
                <div className="header mb-2">
                    <Typography.Title level={1} className="title title-1">
                        <FormattedMessage {...accountMessages.subscriptions} />
                    </Typography.Title>

                    {titlesState.loading && totalTitles === 0 ? (
                        <>
                            <Skeleton loading={true} />
                        </>
                    ) : (
                        <>
                            {totalTitles === 0 && (
                                <Alert
                                    type="warning"
                                    message={<FormattedMessage {...genericMessages.error_no_subs} />}
                                    showIcon
                                />
                            )}

                            {activeTitles.length > 0 && (
                                <>
                                    <Divider orientation="left">
                                        <FormattedMessage {...genericMessages.ongoing} />
                                    </Divider>

                                    <div className="subscription-grid">
                                        {activeTitles.map((title) => (
                                            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                                            <SubscriptionCard title={title} key={'subscription-' + title.id} />
                                        ))}
                                    </div>
                                </>
                            )}

                            {expiredTitles.length > 0 && (
                                <>
                                    <Divider orientation="left" className="mt-2">
                                        <FormattedMessage {...genericMessages.expired} />
                                    </Divider>

                                    <div className="subscription-grid">
                                        {expiredTitles.map((title) => (
                                            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                                            <SubscriptionCard title={title} key={'subscription-' + title.id} />
                                        ))}
                                    </div>
                                </>
                            )}

                            {pendingTransactions.length > 0 && (
                                <>
                                    <Divider orientation="left" className="mt-2">
                                        <FormattedMessage {...genericMessages.transaction_pending} />
                                    </Divider>

                                    <div className="subscription-grid">
                                        {pendingTransactions.map((transaction) => (
                                            <TransactionCard
                                                transaction={transaction}
                                                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                                                key={'transaction-' + transaction.id}
                                            />
                                        ))}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Subscriptions;
