import { Button } from 'antd';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import formMessages from '../locale/formMessages';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { constants } from '../helpers';
import { SocialAuthResponse } from '../store/api/apiTypes';

import iconFacebook from '../assets/images/icon-facebook.svg';

interface ButtonFacebookLoginProps {
    onSuccess?: (infos: SocialAuthResponse) => void;
}
const ButtonFacebookLogin: FC<ButtonFacebookLoginProps> = ({ onSuccess }) => {
    const response = (e: any) => {
        if (onSuccess) {
            if (e.accessToken && e.id) {
                console.log('FB infos', e);
                onSuccess({
                    fbId: e.id,
                    fbToken: e.accessToken,
                    lastName: e.last_name,
                    firstName: e.first_name,
                    email: e.email,
                });
            }
        }
    };

    return (
        <LoginSocialFacebook
            appId={constants.FACEBOOK_ID}
            onResolve={({ data }: any) => {
                response(data);
            }}
            fieldsProfile="id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
            onReject={(err: any) => response(err)}
        >
            <Button type="ghost" shape="round" size="large" block className="mb-1" htmlType="button">
                <img className="icon" src={iconFacebook} alt="Facebook Signin" />
                <FormattedMessage {...formMessages.facebook_signin} />
            </Button>
        </LoginSocialFacebook>
    );
};

export default ButtonFacebookLogin;
