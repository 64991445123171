import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 13 16">
        <path
            fill="currentColor"
            d="M6.5,-3.37507799e-14 C10.0898619,-3.37507799e-14 13,2.76768381 13,6.18181817 C13,8.33815276 11.9604405,10.5192842 10.2192761,12.6494668 C9.59837186,13.4090961 8.92144906,14.1188381 8.22568807,14.7671868 C8.0931541,14.8906893 7.9652727,15.0070893 7.84283551,15.1161557 L7.84283551,15.1161557 L7.49264824,15.4211228 L7.17987616,15.680701 L6.98393967,15.8358394 C6.7046866,16.0529095 6.30392172,16.0549067 6.02229202,15.8406318 L6.02229202,15.8406318 L5.81808177,15.6791047 L5.5067467,15.4206499 C5.45151941,15.3736842 5.39469534,15.3247977 5.33638289,15.2740267 L5.33638289,15.2740267 L4.96951505,14.9470774 L4.77431193,14.7671868 C4.07855094,14.1188381 3.40162814,13.4090961 2.7807239,12.6494668 C1.03955948,10.5192842 0,8.33815276 0,6.18181817 C0,2.76768381 2.91013813,-3.37507799e-14 6.5,-3.37507799e-14 Z M6.5,1.45454545 C3.75480893,1.45454545 1.52941176,3.57100708 1.52941176,6.18181817 C1.52941176,7.95533497 2.43630994,9.85812563 3.98820911,11.7567566 C4.56394786,12.4611293 5.19537514,13.1231701 5.84468984,13.7282375 L5.84468984,13.7282375 L6.19967237,14.0518893 L6.5,14.3136969 L6.80026446,14.0519387 L7.15531016,13.7282375 C7.80462486,13.1231701 8.43605214,12.4611293 9.01179089,11.7567566 C10.5636901,9.85812563 11.4705882,7.95533497 11.4705882,6.18181817 C11.4705882,3.57100708 9.24519107,1.45454545 6.5,1.45454545 Z M6.5,3.27272727 C8.18933834,3.27272727 9.55882353,4.57517472 9.55882353,6.18181817 C9.55882353,7.78846162 8.18933834,9.09090907 6.5,9.09090907 C4.81066166,9.09090907 3.44117647,7.78846162 3.44117647,6.18181817 C3.44117647,4.57517472 4.81066166,3.27272727 6.5,3.27272727 Z M6.5,4.72727272 C5.65533246,4.72727272 4.97058824,5.37849799 4.97058824,6.18181817 C4.97058824,6.98513835 5.65533246,7.63636362 6.5,7.63636362 C7.34466754,7.63636362 8.02941176,6.98513835 8.02941176,6.18181817 C8.02941176,5.37849799 7.34466754,4.72727272 6.5,4.72727272 Z"
        ></path>
    </svg>
);

const IconMarker: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-marker${props.className ? ` ${props.className}` : ''}`} />
);

export default IconMarker;
