import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import VehicleIcon from '../../../components/VehicleIcon';
import accountMessages from '../../../locale/accountMessages';
import { Transaction } from '../../../store/api/apiTypes';

export interface TransactionsProps {
    transaction: Transaction;
}

const SubscriptionCard: FC<TransactionsProps> = ({ transaction }) => {
    const transactionCurrentieToString = (amount: number, digitPlacement: number) => {
        const amountStr = amount.toString();
        return amountStr.slice(0, digitPlacement) + ',' + amountStr.slice(digitPlacement, amountStr.length);
    };
    return (
        <>
            <div className="card subscription-card">
                <div className="type">
                    <VehicleIcon type={transaction.offer?.vehicleClass} />
                </div>
                <div className="content">
                    <div className="informations">
                        <div className="details">
                            <div className="name">{transaction.offer?.offerName}</div>
                            {transaction?.offer?.zones?.map((zone, index) => (
                                <div className="parking" key={'offer-zone-' + index.toString()}>
                                    {zone.parkingName} - {zone.zoneName}
                                </div>
                            ))}

                            <div className="separator" />
                            {transaction.titles?.length && transaction.titles[0].handler && (
                                <div className="user">
                                    {transaction.titles[0].handler?.firstName} {transaction.titles[0].handler?.name}
                                </div>
                            )}
                            {transaction.currencyCentsDigits && transaction.totalDueAmount && (
                                <div className="info-row">
                                    <span className="label text-primary">
                                        <FormattedMessage {...accountMessages.amount_label} />
                                    </span>{' '}
                                    {transactionCurrentieToString(
                                        transaction.totalDueAmount,
                                        transaction.currencyCentsDigits
                                    )}{' '}
                                    {transaction.currencyIso}
                                </div>
                            )}
                            {transaction.titles?.length && transaction.titles[0].licensePlates?.length && (
                                <div className="info-row">
                                    <span className="label text-primary">
                                        <FormattedMessage {...accountMessages.licenses_plates_label} />
                                    </span>{' '}
                                    {transaction.titles[0].licensePlates?.join(', ')}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubscriptionCard;
