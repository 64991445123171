import api from './_client';
import { SearchPaginationQuery } from '.';

// Types
import { Parking } from './apiTypes';

export interface ParkingSitePayload {
    id: Parking['id'];
}

// Controller Interface
export interface ParkingCreatePayload {
    body: {
        name: Parking['name'];
    };
}

export interface ParkingUpdatePayload {
    id: Parking['id'];
    body: {
        name: Parking['name'];
    };
}

export interface ParkingIdPayload {
    id: Parking['id'];
}

export type ParkingListPayload = SearchPaginationQuery;

// Routes
export const list = async (payload: ParkingListPayload = {}) => {
    return await api.get(`/ppo/parkings/${payload?.siteId ?? ''}`, { params: payload });
};

export const create = async (payload: ParkingCreatePayload) => {
    return await api.post(`/ppo/parkings`, payload.body);
};

export const update = async (payload: ParkingUpdatePayload) => {
    return await api.put(`/ppo/parkings/${payload.id}`, payload.body);
};

export const details = async (payload: ParkingIdPayload) => {
    return await api.get(`/ppo/parkings/${payload.id}`);
};

export const remove = async (payload: ParkingIdPayload) => {
    return await api.delete(`/ppo/parkings/${payload.id}`);
};

export const site = async (payload: ParkingSitePayload) => {
    return await api.get(`/ppo/sites/${payload.id}`);
};
