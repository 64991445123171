import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'forgot_password.title',
        defaultMessage: 'Mot de passe oublié',
    },
    error_default: {
        id: 'forgot_password.error_default',
        defaultMessage: 'Une erreur est survenue, veuillez réessayer plus tard ou contacter un administrateur',
    },
    rule: {
        id: 'forgot_password.rule',
        defaultMessage: 'champ requis',
    },
    email_not_found: {
        id: 'forgot_password.email_not_found',
        defaultMessage: 'Adresse e-mail non trouvée.',
    },
    intro_message_1: {
        id: 'forgot_password.intro_message_1',
        defaultMessage: 'Saisissez l’adresse e-mail associé à votre compte.',
    },
    intro_message_2: {
        id: 'forgot_password.intro_message_2',
        defaultMessage: 'Vous recevrez un lien pour réinitialiser votre mot de passe.',
    },
    reset_password_cta: {
        id: 'forgot_password.reset_password_cta',
        defaultMessage: 'Réinitialiser votre mot de passe',
    },
    back_login: {
        id: 'forgot_password.back_login',
        defaultMessage: 'Revenir à l’écran de connexion',
    },
    success_message_1: {
        id: 'forgot_password.success_message_1',
        defaultMessage: 'Nous venons de vous envoyer un lien pour réinitialiser votre mot de passe à l’adresse {email}',
    },
    success_message_2: {
        id: 'forgot_password.success_message_2',
        defaultMessage: 'Merci de surveiller vos e-mails.',
    },
});
