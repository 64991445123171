import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width="1em" height="1em" x="0px" y="0px" viewBox="0 0 16 16">
        <path
            fill="currentColor"
            d="M0.697932655,2.53368329 L2.82794203,0.405949256 C3.08794317,0.145981752 3.43594471,0 3.80394632,0 C4.17194794,0 4.51994948,0.143982002 4.77995062,0.405949256 L7.07396072,2.69566304 C7.33396186,2.95563055 7.4799625,3.3055868 7.4799625,3.67354081 C7.4799625,4.04349456 7.33596187,4.38945132 7.07396072,4.65141857 L5.28195283,6.44519435 C5.69018738,7.39624732 6.27674752,8.26036125 7.00996043,8.99087614 C7.74396366,9.7287839 8.60196744,10.3107112 9.55397163,10.7226597 L11.3459795,8.92888389 C11.6059807,8.66891639 11.9539822,8.52293463 12.3219838,8.52293463 C12.6886658,8.52157804 13.0404512,8.66789606 13.2979881,8.92888389 L15.5939982,11.2185977 C15.8539994,11.4785652 16,11.8285214 16,12.1964754 C16,12.5664292 15.8559994,12.912386 15.5939982,13.1743532 L13.4659888,15.3020872 C13.0219869,15.7460317 12.4099842,16 11.7819814,16 C11.6519809,16 11.5259803,15.9900012 11.3979797,15.968004 C8.74996809,15.5320585 6.12195653,14.1222347 3.99994719,12.0024997 C1.87993786,9.87876515 0.471931661,7.25109361 0.0319297241,4.60142482 C-0.0940708304,3.84951881 0.157930279,3.07561555 0.697932655,2.53368329 Z M1.44993596,4.36345457 C1.83993768,6.72115986 3.10794326,9.07286589 5.01795167,10.9826272 C6.92796007,12.8923885 9.27797042,14.16023 11.6359808,14.5501812 C11.9319821,14.600175 12.2359834,14.5001875 12.4519844,14.2862142 L14.5419936,12.1964754 L12.3259838,9.97875266 L9.91197321,12.3964504 L9.47997131,12.2364704 C6.82890195,11.2618904 4.73972452,9.17225209 3.76594616,6.52118485 L3.60594545,6.08923885 L6.02195609,3.67554056 L3.80594633,1.45781777 L1.71593714,3.54755656 C1.49993618,3.76352956 1.39993574,4.06749156 1.44993596,4.36345457 Z"
        ></path>
    </svg>
);

const IconPhone: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-phone${props.className ? ` ${props.className}` : ''}`} />
);

export default IconPhone;
