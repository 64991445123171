import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useActions, usePrevious } from '../../hooks';
import { getRoute, RoutePathName } from '../../routes';
import { verification as verificationAction, getUsersVerificationState } from '../../store/actions/users';

const Verification: FC = () => {
    const history = useHistory();
    const [verification] = useActions([verificationAction.trigger]);
    const userVerificationState = useSelector(getUsersVerificationState);
    const previous = usePrevious({
        userVerificationState,
    });

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        verification({
            token: params.get('token'),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (previous?.userVerificationState.loading && !userVerificationState.loading) {
            if (userVerificationState.success) {
                console.log(userVerificationState);
                history.push(getRoute(RoutePathName.home));
            }
        }
    }, [history, previous, userVerificationState]);

    return <></>;
};

export default Verification;
