import React, { FC, useEffect, useState } from 'react';
import { Button, Divider, Form, Switch } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import formMessages from '../../locale/formMessages';
import { useSelector } from 'react-redux';
import { FormInstance } from 'antd/es/form/Form';
import ModalAddress from '../account/addresses/ModalAddress';
import { Address, AddressType } from '../../store/api/apiTypes';
import genericMessages from '../../locale/genericMessages';
import AddressRow from '../../components/AddressRow';
import { update as updateAction, getUsersUpdateState } from '../../store/actions/users';
import { getUser, checkLoginStatus as checkLoginStatusAction } from '../../store/actions/auth';
import { useActions, usePrevious } from '../../hooks';
import AddressesList from './AddressesList';
import { isSameAddress } from '../../helpers';

interface AddressesFormProps {
    form: FormInstance;
    disabled?: boolean;
}

const AddressesForm: FC<AddressesFormProps> = ({ form, disabled }) => {
    const { formatMessage } = useIntl();

    const [updateUser, checkLoginStatus] = useActions([updateAction.trigger, checkLoginStatusAction.trigger]);
    const userUpdateState = useSelector(getUsersUpdateState);
    const user = useSelector(getUser);

    const [currentAddressType, setCurrentAddressType] = useState<AddressType>();
    const [showAddressesList, setShowAddressesList] = useState<boolean>(false);
    const [showDeliveryAddressesList, setShowDeliveryAddressesList] = useState<boolean>(false);
    const [addressModalVisible, setAddressModalVisible] = useState<boolean>(false);
    const [editAddress, setEditAddress] = useState<Address>();
    const [editAddressIndex, setEditAddressIndex] = useState<number>();
    const defaultAddress = user?.defaultAddress;

    const [billingAddress, setBillingAddress] = useState<Address | undefined>(defaultAddress);
    const [deliveryAddress, setDeliveryAddress] = useState<Address | undefined>(defaultAddress);

    const previous = usePrevious({
        userUpdateState,
    });

    const editAddressModal = (type: AddressType, index: number) => {
        setEditAddressIndex(index);
        setEditAddress(user?.addresses[index]);
        openAddressModal(type);
    };

    const openAddressModal = (type: AddressType) => {
        setCurrentAddressType(type);
        setAddressModalVisible(true);
    };

    const closeAddressModal = () => {
        setAddressModalVisible(false);
        setEditAddressIndex(undefined);
    };

    const onModalAddressSuccess = (address: Address) => {
        if (user?.id) {
            let addresses: Address[];

            if (editAddressIndex !== undefined) {
                addresses = user.addresses;
                addresses[editAddressIndex] = address;
            } else {
                addresses = [...user.addresses, address];
            }

            updateAddresses(addresses);

            if (address.default || addresses.length === 1) {
                setDefaultAddress(address);
            }

            if (currentAddressType === AddressType.billing) setBillingAddress(address);
            if (currentAddressType === AddressType.delivery) setDeliveryAddress(address);

            setAddressModalVisible(false);
        }
    };

    const updateAddresses = (addresses: Address[]) => {
        updateUser({
            id: user?.id,
            body: {
                addresses: addresses,
            },
        });
    };

    const setDefaultAddress = (address: Address) => {
        updateUser({
            id: user?.id,
            body: {
                defaultAddress: address,
            },
        });
    };

    useEffect(() => {
        if (previous?.userUpdateState.loading && !userUpdateState.loading) {
            if (userUpdateState.success) {
                checkLoginStatus();
            }
        }
    }, [previous, userUpdateState, checkLoginStatus]);

    useEffect(() => {
        form.setFieldsValue({
            deliveryAddress,
            billingAddress,
        });
    }, [deliveryAddress, billingAddress, form]);

    const onSameAddressSwitchChange = (checked: any) => {
        if (!checked) {
            setShowDeliveryAddressesList(true);
        }
    };

    const confirmDeliveryAddress = () => {
        setShowDeliveryAddressesList(false);
        form.setFieldsValue({
            sameAddressForDelivery: isSameAddress(deliveryAddress, billingAddress),
        });
    };

    return (
        <>
            <h1 className="title title-1 mt-3 mb-2">
                <FormattedMessage {...messages.your_addresses} />
            </h1>

            <div className="card card-addresses text-left">
                <div className="mb-1 flex-row-center">
                    <h2 className="title title-1 smaller">
                        <FormattedMessage {...formMessages.invoice_address_label} />
                    </h2>

                    {showAddressesList && (
                        <a href="#show-addresses-list" onClick={setShowAddressesList.bind(null, false)}>
                            <FormattedMessage {...genericMessages.cancel} />
                        </a>
                    )}
                </div>

                {!showAddressesList ? (
                    <>
                        <div className="flex-column-center md-row">
                            <AddressRow address={billingAddress} />
                            {billingAddress && (
                                <small>
                                    <a href="#show-addresses-list" onClick={setShowAddressesList.bind(null, true)}>
                                        <FormattedMessage {...messages.use_another_address} />
                                    </a>
                                </small>
                            )}
                        </div>

                        {!billingAddress && (
                            <Button
                                type="primary"
                                shape="round"
                                size="large"
                                className="reverse"
                                onClick={openAddressModal.bind(null, AddressType.billing)}
                                disabled={disabled}
                            >
                                <FormattedMessage {...formMessages.add_address} />
                            </Button>
                        )}
                    </>
                ) : (
                    <>
                        <AddressesList
                            type={AddressType.billing}
                            user={user}
                            selectedAdress={billingAddress}
                            onSelect={setBillingAddress}
                            onEdit={editAddressModal}
                            onNew={openAddressModal}
                        />

                        <div className="text-center">
                            <Button
                                type="primary"
                                shape="round"
                                size="large"
                                className="reverse"
                                onClick={setShowAddressesList.bind(null, false)}
                                disabled={disabled}
                            >
                                <FormattedMessage {...formMessages.confirm_billing_address} />
                            </Button>
                        </div>
                    </>
                )}

                <Form.Item
                    name="billingAddress"
                    rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                />

                <Divider />

                <div className="mb-1 flex-row-center">
                    <h2 className="title title-1 smaller">
                        <FormattedMessage {...formMessages.badge_delivery_address_label} />
                    </h2>

                    {showAddressesList && (
                        <a href="#show-addresses-list" onClick={setShowAddressesList.bind(null, false)}>
                            <FormattedMessage {...genericMessages.cancel} />
                        </a>
                    )}
                </div>

                {isSameAddress(deliveryAddress, billingAddress) && !showDeliveryAddressesList && (
                    <div className="switch-row">
                        <Form.Item
                            name="sameAddressForDelivery"
                            rules={[{ required: false }]}
                            valuePropName="checked"
                            className="mt-0 mb-0"
                            initialValue={true}
                        >
                            <Switch
                                className="mb-0"
                                onChange={onSameAddressSwitchChange}
                                checked={isSameAddress(deliveryAddress, billingAddress)}
                                disabled={disabled}
                            />
                        </Form.Item>
                        <div className="label">
                            <FormattedMessage {...formMessages.same_address_for_delivery_label} />
                        </div>
                    </div>
                )}

                {!showDeliveryAddressesList ? (
                    <>
                        {!isSameAddress(deliveryAddress, billingAddress) && (
                            <div className="flex-column-center md-row">
                                <AddressRow address={deliveryAddress} />
                                {deliveryAddress && (
                                    <small>
                                        <a
                                            href="#show-addresses-list"
                                            onClick={setShowDeliveryAddressesList.bind(null, true)}
                                        >
                                            <FormattedMessage {...messages.use_another_address} />
                                        </a>
                                    </small>
                                )}
                            </div>
                        )}

                        {!deliveryAddress && (
                            <Button
                                type="primary"
                                shape="round"
                                size="large"
                                className="reverse"
                                onClick={openAddressModal.bind(null, AddressType.delivery)}
                                disabled={disabled}
                            >
                                <FormattedMessage {...formMessages.add_address} />
                            </Button>
                        )}
                    </>
                ) : (
                    <>
                        <AddressesList
                            type={AddressType.delivery}
                            user={user}
                            selectedAdress={deliveryAddress}
                            onSelect={setDeliveryAddress}
                            onEdit={editAddressModal}
                            onNew={openAddressModal}
                        />

                        <div className="text-center">
                            <Button
                                type="primary"
                                shape="round"
                                size="large"
                                className="reverse"
                                onClick={confirmDeliveryAddress}
                            >
                                <FormattedMessage {...formMessages.confirm_delivery_address} />
                            </Button>
                        </div>
                    </>
                )}

                <Form.Item
                    name="deliveryAddress"
                    rules={[{ required: true, message: formatMessage(formMessages.error_required) }]}
                />
            </div>

            <ModalAddress
                isVisible={addressModalVisible}
                address={editAddress}
                deletable={false}
                onClose={closeAddressModal}
                onSuccess={onModalAddressSuccess}
            />
        </>
    );
};

export default AddressesForm;
