import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'contact.title',
        defaultMessage: 'Contactez-nous',
    },
    intro: {
        id: 'contact.intro',
        defaultMessage: 'Complétez le formulaire ci-dessous et notre équipe reviendra vers vous rapidement.',
    },
    submit: {
        id: 'contact.submit',
        defaultMessage: 'Envoyer le message',
    },
    copy_message: {
        id: 'contact.copy_message',
        defaultMessage: 'Vous souhaitez recevoir une copie de votre message',
    },
});
