import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Result } from 'antd';
import { ResultProps } from 'antd/lib/result';
import messages from './messages';
import Seo from '../../components/Seo';
import ButtonLink from '../../components/ButtonLink';
import { getRoute, RoutePathName } from '../../routes';
import { useIntl } from 'react-intl';

const ErrorPage: FC = () => {
    const { formatMessage } = useIntl();
    const { 0: param } = useParams<{ 0?: string }>();
    let statusCode = parseInt(param?.substring(1) ?? '', 10);
    let status: ResultProps['status'] = 'error';
    let title: ResultProps['title'] = formatMessage(messages.title);
    let subTitle: ResultProps['subTitle'] = formatMessage(messages.subtitle);

    if (isNaN(statusCode)) {
        statusCode = 404;
    }

    if (`${statusCode}`.length === 3 && `${statusCode}`.startsWith('5')) {
        status = '500';
        title = `${statusCode}`;
    } else if (statusCode === 403 || statusCode === 401) {
        status = '403';
        title = statusCode;
        subTitle =
            statusCode === 403
                ? formatMessage(messages.subTitle_error_403)
                : formatMessage(messages.subTitle_error_401);
    } else if (statusCode === 400) {
        title = statusCode;
        subTitle = formatMessage(messages.subTitle_error_400);
    } else {
        status = '404';
        title = 404;
        subTitle = formatMessage(messages.subTitle_error_404);
    }

    return (
        <>
            <Seo title={`${statusCode}`} />
            <Result
                status={status}
                title={title}
                subTitle={subTitle}
                extra={
                    <ButtonLink to={getRoute(RoutePathName.home)} type="primary">
                        {formatMessage(messages.button_link)}
                    </ButtonLink>
                }
            />
        </>
    );
};

export default ErrorPage;
