import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 49 48">
        <path
            fill="currentColor"
            d="M6.60229088,7.38590801 C13.8828532,-0.293627168 25.2752606,-2.19226469 34.5888424,2.67022583 L35.0044586,2.89247101 L35.1594878,2.98643389 C36.1087721,3.61594751 36.4348251,4.88384738 35.8837671,5.90631841 C35.3021559,6.98547996 33.9631162,7.3840703 32.8966292,6.79532039 C26.9095039,3.52335962 19.6637326,3.6801881 13.8202062,7.20826562 C7.97505493,10.7373242 4.39448501,17.1194047 4.39507845,24.0099417 C4.39507845,34.8142292 13.0472028,43.5708188 23.7176372,43.5708188 C34.3880716,43.5708188 43.0401959,34.8142292 43.0401961,24.0103562 C43.0392669,22.6937361 42.9096793,21.3804414 42.6532414,20.0892189 C42.4160309,18.8900492 43.1846135,17.7216006 44.3742557,17.479403 C45.5650674,17.2381233 46.7223203,18.0178344 46.9609146,19.2182475 C47.2744364,20.795589 47.4331886,22.4004106 47.4348605,24.0102051 C47.4300168,34.8081202 40.2986271,44.2741024 30.0081066,47.1383886 C19.7160801,50.0030941 8.80157228,45.5578355 3.34031639,36.2788181 C-2.11958428,27.0021034 -0.784843693,15.1778556 6.60229088,7.38590801 Z M45.1900774,1.59990593 C45.7239141,1.01724872 46.5255847,0.766697788 47.2924591,0.943585548 C48.0584006,1.12025811 48.6720907,1.69618697 48.9031535,2.45346345 C49.0891513,3.06304563 49.0053315,3.71949669 48.6681295,4.27546676 L48.5463669,4.45686647 L48.4157248,4.61423713 L25.633609,29.429404 C25.3204019,29.7838781 24.9043464,30.0258421 24.4276566,30.1250237 L24.2197717,30.1587 L24.0135357,30.1709151 L23.8807012,30.1697996 C23.3703384,30.1381381 22.8898705,29.9280594 22.5046064,29.5628253 L22.3647226,29.4186349 L22.2314764,29.2505559 L14.5044034,18.3913582 C14.0073973,17.7517656 13.896666,16.88905 14.2154558,16.1427782 C14.5348982,15.3949788 15.2346907,14.8834709 16.0390012,14.8114544 C16.701158,14.7521662 17.3490676,14.9988016 17.8196806,15.4899415 L17.9553825,15.6445572 L18.0537323,15.7799456 L24.2261381,24.44954 L44.2942172,2.58031059 L44.2907734,2.58145341 L45.1900774,1.59990593 Z"
        ></path>
    </svg>
);

const IconConfirm: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-confirm${props.className ? ` ${props.className}` : ''}`} />
);

export default IconConfirm;
