export default Object.freeze({
    PAGE_SIZE: 50,
    API: Object.freeze({
        BASE_URL: process.env.REACT_APP_API_BASE_URL ?? '',
        KEY: process.env.REACT_APP_API_KEY ?? '',
    }),
    SEO: Object.freeze({
        BASE_URL: '',
        SITE_NAME: 'Orbility ParkPass',
        DEFAULT_TITLE: 'Orbility ParkPass',
        DEFAULT_DESCRIPTION: 'Orbility ParkPass',
    }),
    GOOGLE_ANALYTICS: process.env.REACT_APP_GOOGLE_ANALYTICS ?? '',
    ENABLE_DEBUG_LOGS: false, // process.env.NODE_ENV === 'production',
    DEBUG_LOGS_APP: '', // <app>_<service>
    VERSION: process.env.REACT_APP_VERSION ?? '',
    FACEBOOK_ID: process.env.REACT_APP_FACEBOOK_ID ?? '',
    GOOGLE_ID: process.env.REACT_APP_GOOGLE_ID ?? '',
    ADVAM_IFRAME_URL: process.env.REACT_APP_ADVAM_IFRAME_URL ?? 'https://demo-webbank.advam.com/iframe/transaction.php',
});
