import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 25 28">
        <path
            fill="currentColor"
            d="M11.0021732,0 C17.0761052,0 22,4.92453742 22,10.9992622 C22,17.073987 17.0761052,21.9985244 11.0021732,21.9985244 C9.24396469,22.028403 7.50778744,21.604089 5.96150262,20.766607 L5.96150262,20.766607 L2.95176403,21.8665332 C2.1485313,22.158854 1.24870569,21.9593051 0.644300203,21.3548207 C0.0398947194,20.7503363 -0.159628219,19.8503933 0.132654436,19.0470557 L0.132654436,19.0470557 L1.23243711,16.0369243 C0.396951883,14.4911814 -0.0260199725,12.7561391 0.00434645705,10.9992622 C0.00434645705,4.92453742 4.92824122,0 11.0021732,0 Z M11.0021732,2.19985244 C6.14302762,2.19985244 2.20391181,6.13948238 2.20391181,10.9992622 C2.15270035,12.7064465 2.66834626,14.3825145 3.67028871,15.7656091 L3.67028871,15.7656091 L2.20391181,19.7986719 L6.23644829,18.3321037 C7.61936248,19.3341769 9.29521168,19.8498901 11.0021732,19.7986719 C15.8613188,19.7986719 19.8004346,15.859042 19.8004346,10.9992622 C19.8004346,6.13948238 15.8613188,2.19985244 11.0021732,2.19985244 Z M11.0021732,9.89933597 C11.6095664,9.89933597 12.1019559,10.3917897 12.1019559,10.9992622 C12.1019559,11.6067347 11.6095664,12.0991884 11.0021732,12.0991884 C10.39478,12.0991884 9.90239055,11.6067347 9.90239055,10.9992622 C9.90239055,10.3917897 10.39478,9.89933597 11.0021732,9.89933597 Z M6.23644829,9.89933597 C6.84384149,9.89933597 7.33623097,10.3917897 7.33623097,10.9992622 C7.33623097,11.6067347 6.84384149,12.0991884 6.23644829,12.0991884 C5.62905509,12.0991884 5.13666562,11.6067347 5.13666562,10.9992622 C5.13666562,10.3917897 5.62905509,9.89933597 6.23644829,9.89933597 Z M15.7678982,9.89933597 C16.3752914,9.89933597 16.8676808,10.3917897 16.8676808,10.9992622 C16.8676808,11.6067347 16.3752914,12.0991884 15.7678982,12.0991884 C15.160505,12.0991884 14.6681155,11.6067347 14.6681155,10.9992622 C14.6681155,10.3917897 15.160505,9.89933597 15.7678982,9.89933597 Z"
            id="contact"
        ></path>
    </svg>
);

const IconContact: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-contact${props.className ? ` ${props.className}` : ''}`} />
);

export default IconContact;
