import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 16 8">
        <path
            fill="currentColor"
            d="M15.6060274,2.95460172 L11.7480207,0.190880371 C11.4562387,-0.0340736927 11.067196,-0.0662099875 10.7429938,0.126607781 C10.4187915,0.287289255 10.2242702,0.608652203 10.2242702,0.99428774 L10.2242702,3.05101061 L0.972606728,3.05101061 C0.421462916,3.05101061 0,3.46878244 0,4.01509945 C0,4.56141646 0.421462916,4.97918829 0.972606728,4.97918829 L10.2242702,4.97918829 L10.2242702,7.03591116 C10.2242702,7.42154669 10.4512117,7.74290964 10.775414,7.90359112 C10.9050949,7.96786371 11.0347758,8 11.1968769,8 C11.4238185,8 11.65076,7.93572741 11.8128612,7.77504594 L15.6708679,4.49714387 C15.8978094,4.3043261 16.0274903,4.01509945 15.9950701,3.7258728 C15.9950701,3.40450985 15.8653892,3.11528319 15.6060274,2.95460172 Z"
        ></path>
    </svg>
);

const IconArrowTime: FC<IconComponentProps> = (props) => (
    // @ts-expect-error
    <Icon component={svg} {...props} className={`icon-arrow-time${props.className ? ` ${props.className}` : ''}`} />
);

export default IconArrowTime;
