import { defineMessages } from 'react-intl';

export default defineMessages({
    payment: {
        id: 'payment.payment',
        defaultMessage: 'Paiement',
    },
    back: {
        id: 'payment.back',
        defaultMessage: 'Modifier les informations',
    },
    title: {
        id: 'payment.title',
        defaultMessage: 'Votre paiement',
    },
    intro: {
        id: 'payment.intro',
        defaultMessage: 'Effectuez votre paiement ci-dessous en toute sécurité',
    },
    confirm_title: {
        id: 'payment.confirm_title',
        defaultMessage: 'Votre paiement est confirmé.',
    },
    confirm_text_1: {
        id: 'payment.confirm_text_1',
        defaultMessage: 'Nous venons de débiter {price} sur votre carte bancaire.',
    },
    confirm_text_2: {
        id: 'payment.confirm_text_2',
        defaultMessage: 'Vous allez recevoir un e-mail de confirmation à l’adresse suivante : {email}.',
    },
    cta_subscriptions: {
        id: 'payment.cta_subscriptions',
        defaultMessage: 'Accéder à vos abonnements',
    },
    thank_you: {
        id: 'payment.thank_you',
        defaultMessage: 'Nous vous remercions de votre confiance.',
    },
});
