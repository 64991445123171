import React, { FC } from 'react';
import Modal from '../../components/Modal';

interface Modal3DSecureProps {
    isVisible: boolean;
    onClose: () => void;
}

const Modal3DSecure: FC<Modal3DSecureProps> = ({ isVisible, onClose }) => {
    return (
        <Modal width={438} onCancel={onClose} visible={isVisible} forceRender>
            <div id="threeDSMountPoint" style={{ height: 415 }}></div>
        </Modal>
    );
};

export default Modal3DSecure;
