import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import IconCheck from '../../../components/icons/IconCheck';
import accountMessages from '../../../locale/accountMessages';
import genericMessages from '../../../locale/genericMessages';
import { Address } from '../../../store/api/apiTypes';

export interface AddressCardProps {
    address: Address;
    isDefault: boolean;
    onEdit: () => void;
    onRemove: () => void;
    onCheck: () => void;
}

const AddressCard: FC<AddressCardProps> = ({ isDefault, address, onEdit, onRemove, onCheck }) => {
    const { formatMessage } = useIntl();

    const edit = () => {
        onEdit();
    };

    const remove = () => {
        onRemove();
    };

    const check = () => {
        onCheck();
    };

    return (
        <div className="card address-card">
            <div className="body">
                {isDefault && (
                    <div className="default text-primary">
                        <FormattedMessage {...accountMessages.by_default} />
                    </div>
                )}
                {address.companyName && <div className="company">{address.companyName}</div>}
                {address.firstName && (
                    <div className="name">
                        {address.firstName} {address.lastName}
                    </div>
                )}
                <div className="address">
                    {address.street}
                    {address.complement && (
                        <>
                            <br />
                            {address.complement}
                        </>
                    )}
                    <br />
                    {address.zipCode} {address.city}
                    <br />
                    {address.country}
                </div>
            </div>
            <div className="actions mt-2">
                <Tooltip placement="top" title={formatMessage(genericMessages.edit)}>
                    <Button className="action" onClick={edit} icon={<EditOutlined />} shape="circle" size="large" />
                </Tooltip>
                {!isDefault && (
                    <Tooltip placement="top" title={formatMessage(genericMessages.delete)}>
                        <Button
                            className="action"
                            onClick={remove}
                            icon={<DeleteOutlined />}
                            shape="circle"
                            size="large"
                        />
                    </Tooltip>
                )}
                {!isDefault && (
                    <Tooltip placement="top" title={formatMessage(accountMessages.define_by_default)}>
                        <Button className="action" onClick={check} icon={<IconCheck />} shape="circle" size="large" />
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

export default AddressCard;
