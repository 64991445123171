import { debug } from './helpers';

export enum RoutePathName {
    home = 'home',
    site = 'site',
    parkings = 'parkings',
    login = 'login',
    logout = 'logout',
    register = 'register',
    verification = 'verification',
    notFound = 'notFound',
    privacy = 'privacy',
    legals = 'legals',
    forgottenPassword = 'forgottenPassword',
    resetPassword = 'resetPassword',
    contact = 'contact',
    offers = 'offers',
    offer = 'offer',
    payment = 'payment',
    paymentConfirm = 'paymentConfirm',
    paymentCancel = 'paymentCancel',
    account = 'account',
    accountInformations = 'accountInformations',
    accountPassword = 'accountPassword',
    accountAddresses = 'accountAddresses',
    accountSubscriptions = 'accountSubscriptions',
    renewSubscription = 'renewSubscription',
}

export type Routes = {
    [r in RoutePathName]: string;
};

export const routes: Routes = {
    [RoutePathName.home]: '/',
    [RoutePathName.site]: '/:siteId',
    [RoutePathName.parkings]: '',
    [RoutePathName.login]: '/connexion',
    [RoutePathName.logout]: '/logout',
    [RoutePathName.register]: '/:siteId?/register',
    [RoutePathName.verification]: '/verification',
    [RoutePathName.notFound]: '/404',
    [RoutePathName.privacy]: '/confidentialite',
    [RoutePathName.legals]: '/legals/:slug',
    [RoutePathName.forgottenPassword]: '/mot-de-passe-oublie',
    [RoutePathName.resetPassword]: '/reset-password',
    [RoutePathName.contact]: '/contact',
    [RoutePathName.offers]: '/offers',
    [RoutePathName.offer]: '/offer/:parkingId/:offerId',
    [RoutePathName.payment]: '/payment',
    [RoutePathName.paymentConfirm]: '/payment-confirm/:transactionId?',
    [RoutePathName.paymentCancel]: '/payment-cancel',
    [RoutePathName.account]: '/account',
    [RoutePathName.accountInformations]: '/account/informations',
    [RoutePathName.accountPassword]: '/account/password',
    [RoutePathName.accountAddresses]: '/account/addresses',
    [RoutePathName.accountSubscriptions]: '/account/subscriptions',
    [RoutePathName.renewSubscription]: '/renew-subscription/:titleId',
};

export interface RouteParams {
    [param: string]: string | number;
}

// returns raw react-router string path eg: /user/:id
export const getRawRoute = (path: RoutePathName) => {
    if (!routes[path]) {
        debug.error(`[getRawRoute] Route not found for path "${path}", returning /404.`);
        return '/404';
    } else {
        return routes[path];
    }
};

// returns real-world path eg: /user/1337
export const getRoute = (
    path: RoutePathName,
    params?: RouteParams,
    queryParams?: string | string[][] | Record<string, string> | URLSearchParams | undefined,
    anchor?: string
) => {
    let route = getRawRoute(path);
    const rawParams = route.split('/').filter((str) => str.startsWith(':'));
    const providedParamNames = params ? Object.keys(params) : [];

    const routeParts = window.location.pathname.split('/');
    const siteId = routeParts.length > 1 ? '/' + routeParts[1] : '';

    rawParams.forEach((rawParam) => {
        const isOptionnal = rawParam.endsWith('?');
        const rawParamStripped = isOptionnal ? rawParam.substring(1, rawParam.length - 1) : rawParam.substring(1);

        if (params && providedParamNames.includes(rawParamStripped)) {
            route = route.replace(rawParam, `${params[rawParamStripped]}`);
        } else if (isOptionnal) {
            debug.info(
                `[getRoute] Optionnal param "${rawParamStripped}" not provided in route "${route}", skipping param.`
            );
            route = route.replace(`/${rawParam}`, '');
        } else {
            throw new Error(`[getRoute] Param "${rawParamStripped}" not provided in route "${route}".`);
        }
    });

    if (queryParams && Object.keys(queryParams).length) {
        const urlQueryParams = new URLSearchParams(queryParams);
        route += `?${urlQueryParams.toString()}`;
    }

    if (anchor) {
        route = `${route}#${anchor}`;
    }

    return siteId + route;
};

export const getDomain = () => {
    return (
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '')
    );
};
