import React, { FC, useEffect, useState } from 'react';
import { Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

import '../../assets/styles/form/Quantity.less';

interface QuantityProps {
    onChange?: (value: number) => void;
    max?: number;
    disabled?: boolean;
}

const Quantity: FC<QuantityProps> = ({ ...props }) => {
    const [value, setValue] = useState<number>(1);

    useEffect(() => {
        if (props.onChange) {
            props.onChange(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const remove = () => {
        if (value > 1) {
            setValue(value - 1);
        }
    };

    const add = () => {
        if (!props.max || value < props.max) {
            setValue(value + 1);
        }
    };

    return (
        <div className="quantity-input">
            <Button
                type="ghost"
                shape="circle"
                icon={<MinusOutlined />}
                onClick={remove}
                disabled={value <= 1 || props.disabled}
            />
            <span className="value">{value}</span>
            <Button type="ghost" shape="circle" icon={<PlusOutlined />} onClick={add} disabled={props.disabled} />
        </div>
    );
};

export default Quantity;
